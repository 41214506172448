/* Website */
@media screen and (min-device-width: 768px) and (max-device-width: 2500px) {
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
 
  :root {
    scroll-behavior: smooth !important;
  }

  body {
    font-family: "Inter", sans-serif !important;
    color: #444444;
    overflow-x: hidden;
    background-color: #f6f7fa !important;
    animation: fadeInAnimation ease 2.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  a {
    color: #3e4093;
    text-decoration: none;
    cursor: pointer !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: "Poppins";
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /*--------------------------------------------------------------
    # Header
    --------------------------------------------------------------*/
  .comman_btn_search {
    background-color: #3e4093;
    color: #fff;
    padding: 2px 6px;
    transition: 0.5s all;
    border-radius: 6px 0px 0px 6px;
    position: relative;
    left: 4px;
    z-index: 9;
  }
  .searchDrop {
    background-color: #3e4093;
    border: none;
  }
  .comman_btn {
    background-color: #3e4093;
    padding: 12px 28px;
    font-size: 14px !important;
    font-weight: 600;
    transition: 0.5s all;
    color: #fff;
    border-radius: 50px;
    border: 0;
    display: inline-flex;
  }
  .comman_btn:hover {
    background-color: #eb3237;
    color: #3b4093 !important;
  }
  /* welcome screeen */
  .welcome {
    font-family: "Open Sans", sans-serif;
    color: #fff;
    background: url("../img/bg.jpg") top center no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
  }
  .welcome::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(34, 34, 34, 0.8);
  }
  @media (min-width: 1024px) {
    .welcome {
      background-attachment: fixed;
    }
  }

  .welcome a {
    color: #ff0000;
    text-decoration: none;
  }

  .welcome a:hover {
    color: #ff3333;
    text-decoration: none;
  }

  .welcome h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Raleway", sans-serif;
  }

  #main {
    position: relative;
  }

  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #ff0000;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }

  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }

  .back-to-top:hover {
    background: #ff2929;
    color: #fff;
  }

  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }

  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    position: relative;
    width: 100%;
    padding: 50px 0;
    /* countdown */
  }

  #header h1 {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
  }

  #header h2 {
    color: #eee;
    margin-bottom: 40px;
    font-size: 22px;
  }

  #header .countdown {
    margin-bottom: 0px;
  }

  #header .countdown div {
    text-align: center;
    margin: 10px;
    width: 150px;
    padding: 15px 0;
    background: rgba(255, 255, 255, 0.12);
    border-top: 5px solid #eb3237;
  }

  #header .countdown div h3 {
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 15px;
  }

  #header .countdown div h4 {
    font-size: 16px;
    font-weight: 600;
  }

  @media (max-width: 575px) {
    #header .countdown div {
      width: 70px;
      padding: 10px 0;
      margin: 10px 8px;
    }

    #header .countdown div h3 {
      font-size: 28px;
      margin-bottom: 10px;
    }

    #header .countdown div h4 {
      font-size: 14px;
      font-weight: 500;
    }
  }

  #header .subscribe {
    font-size: 15px;
    text-align: center;
  }

  #header .subscribe h4 {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }

  #header .subscribe .subscribe-form {
    min-width: 300px;
    margin-top: 10px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    text-align: left;
  }

  #header .subscribe .subscribe-form input[type="email"] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }

  #header .subscribe .subscribe-form input[type="submit"] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #ff0000;
    color: #fff;
    transition: 0.3s;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }

  #header .subscribe .subscribe-form input[type="submit"]:hover {
    background: #f50000;
  }

  #header .subscribe .error-message {
    display: none;
    color: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }

  #header .subscribe .sent-message {
    display: none;
    color: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }

  #header .subscribe .loading {
    display: none;
    text-align: center;
    padding: 15px;
  }

  #header .subscribe .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading-notify 1s linear infinite;
    animation: animate-loading-notify 1s linear infinite;
  }

  @-webkit-keyframes animate-loading-notify {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-loading-notify {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  #header .social-links {
    margin-top: 10px;
  }

  #header .social-links a {
    font-size: 24px;
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1;
    padding-top: 14px;
    margin: 0 10px;
    text-align: center;
    transition: 0.3s;
  }

  #header .social-links a:hover {
    color: #fff;
  }

  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  .welcome section {
    padding: 60px 0;
  }

  .section-bg {
    background-color: #fff5f5;
  }

  .section-title {
    text-align: center;
    padding-bottom: 15px;
  }

  .section-title h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;
  }

  .section-title p {
    margin-bottom: 0;
  }
  .marginTop {
    margin-top: 8rem;
  }
  .marginTopLog {
    margin-top: 5.5rem !important;
  }
  .photos {
    margin-top: 8.6rem;
    background-color: #f1f0ff;
  }
  .photos_main {
    padding: 1rem;

    border-radius: 0.2rem;
  }
  .photos_main img {
    max-width: 26rem;
    min-width: 20rem;
    min-height: 18rem;
    max-height: 20rem;
    margin-bottom: 0.8rem !important;
    border-radius: 5px;
  }
  .photos_main img:hover {
    opacity: 50%;
    filter: brightness(70%);
    cursor: zoom-in;
  }
  @media (max-width: 800px) {
    .photos_main img {
      max-width: 26rem;
      min-width: 20rem;
      margin: 1rem;
    }
  }

  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .about .content h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .about .content h3 {
    font-weight: 500;
    line-height: 32px;
    font-size: 24px;
  }

  .about .content ul {
    list-style: none;
    padding: 0;
  }

  .about .content ul li {
    padding: 10px 0 0 28px;
    position: relative;
  }

  .about .content ul i {
    left: 0;
    top: 12px;
    position: absolute;
    font-size: 20px;
    color: #ff0000;
  }

  .about .content p:last-child {
    margin-bottom: 0;
  }

  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .contact .info-wrap {
    background: rgba(255, 255, 255, 0.06);
    padding: 30px;
  }

  .contact .info i {
    font-size: 20px;
    color: #fff;
    float: left;
    width: 44px;
    height: 44px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .contact .info .email p {
    padding-top: 5px;
  }

  .contact .info .social-links {
    padding-left: 60px;
  }

  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }

  .contact .info .social-links a:hover {
    background: #ff0000;
    color: #fff;
  }

  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
    background: #ff0000;
    color: #fff;
  }

  .contact .php-email-form {
    width: 100%;
    padding: 30px;
    padding-top: 0;
    background: rgba(255, 255, 255, 0.06);
  }

  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }

  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }

  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }

  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }

  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .viewAllBtn {
    font-size: 18px;
    font-weight: bold;
    color: #989797;
    cursor: pointer;
    position: relative;
    left: 46.5%;
    bottom: 33px;
  }
  .viewAllBtn:hover {
    color: #eb3237;
  }
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }

  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  .contact .php-email-form input::-moz-placeholder,
  .contact .php-email-form textarea::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .contact .php-email-form input::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  .contact .php-email-form input {
    height: 44px;
  }

  .contact .php-email-form textarea {
    padding: 10px 12px;
  }

  .contact .php-email-form button[type="submit"] {
    background: #ff0000;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
  }

  .contact .php-email-form button[type="submit"]:hover {
    background: #ff3333;
  }

  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: rgba(19, 19, 19, 0.4);
    padding: 30px 0;
    color: #fff;
    font-size: 14px;
    position: relative;
    margin-top: 35px;
  }

  #footer .copyright {
    text-align: center;
  }

  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }

  #footer .credits a {
    color: #ff3333;
  }
  .navbar-brand img {
    max-width: 150px;
    margin: 10px 10px;
  }
  .navbar-brand {
    margin: 15px 0px 0px 20px;
  }
  /* welcome end */
  .comman_btn2 {
    background-color: #3e4093 !important;
    padding: 12px 28px !important;
    text-decoration: none !important;
    font-size: 14px !important;
    font-weight: 600;
    transition: 0.5s all;
    color: #fff;
    border-radius: 60px !important;
    border: 0;
    display: inline-flex;
    cursor: pointer !important;
  }
  .comman_btn2:hover {
    background-color: #eb3237 !important;
    color: #fff !important;
  }

  .header_bottom {
    padding-left: 40px;
    padding-bottom: 8px !important;
    position: relative;
    display: flex;
    font-weight: 600;
    font-size: 0.6rem;
    justify-content: center !important;
    background-color: #3e4093;
    height: 35px !important;
  }
  .container-fluid {
    max-width: 96%;
  }
  .comman_head h2 {
    font-weight: 700;
    font-size: 36px;
    text-transform: uppercase;
  }
  .header_menus {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }


  .header_logo img {
    max-width: 150px;
  }

  .signup_btns {
    background-color: #3e4093;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 19px;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50px;
    transition: 0.5s all;
  }
  .signup_btns:hover {
    background-color: #eb3237;
  }
  .login_btns {
    color: #3e4093;
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    margin: 0 15px;
    text-decoration: none;
  }
  .fade-scale {
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
  }
  .fade-scale.in {
    opacity: 1;
    transform: scale(1);
  }
  .social_icon a {
    font-size: 19px;
    display: inline-block;
    margin-right: 18px;
    color: #3e4093;
    position: relative;
  }
  .count {
    position: absolute;
    top: -11px !important;
    right: -5px;
    display: flex;
    background-color: #eb3237;
    height: 10px;
    width: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
  }
  .social_icon {
    border-right: 2px solid #3e4093;
  }
  .header_search .form-control {
    height: 34px;
    font-size: 13px;
    padding: 0 20px;
    border: 0;
  }
  .form-New-select {
    height: 35px !important;
    font-size: 13px;
    padding: 0 20px;
    border-radius: 6px;
    border: 2px grey !important;
  }
  .header_search {
    border-radius: 4px;
    overflow: hidden;
    height: 38px;
    display: inline-block;
    border: 2px solid #3e4093;
    width: 100%;
    position: relative;
  }
  .header_search .Btn-design {
    background-color: #3e4093;
    height: 40px !important;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 0;
    margin-right: -1px;
    font-size: 13px;
  }
  .header_menus li a {
    color: #fff;
    font-size: 15px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    position: relative;
  }
  .header_menus li {
    list-style: none;
    margin-left: 18px;
    position: static;
  }
  .header_top {
    background-color: #eef3ff;
  }
  a:hover {
    color: #eb3237;
  }

  .forms_modal .modal-body {
    padding: 25px;
  }
  .btn-close {
    position: absolute;
    right: 10px;
    font-size: 12px;
    opacity: 1;
    top: 10px;
  }
  .heading_forms h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 3px;
  }
  .heading_forms span {
    display: block;
    font-size: 14px;
    color: #9f9d9d;
  }
  .forms_modal_content .form-group label {
    font-weight: 500;
    font-size: 12px !important;
    color: #000;
    display: block;
    margin: 0 0 4px;
  }
  .forms_modal_content .form-group .form-control {
    border-radius: 3px;
    border: 1px solid #ddd7d7;
    height: 46px;
    font-size: 11px;
    padding: 0 19px;
    box-shadow: unset !important;
  }
  .forgot_password a {
    font-size: 13px;
    font-weight: 500;
    color: #000;
  }
  .comman_text span {
    font-size: 13px;
    display: block;
  }
  .product_parts_box:hover {
    behavior: smooth;
    transform: scale(1);
    transition: 0.2s ease;
  }

  .forms_modal_content .form-group label a {
    font-weight: 600;
  }
  /* .modal-backdrop {
    background-color: #00000045;
    backdrop-filter: blur(120px);
  } */
  .modal-open {
    position: relative;
  }

  .modal-open::after {
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal-backdrop.show {
    display: none;
  }
  .forms_modal .modal-content {
    border: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 18px;
    border-radius: 16px;
  }
  .forms_modal_content .form-group {
    margin-bottom: 15px;
  }
  .custom_checkbox label {
    font-size: 13px !important;
    padding-left: 30px;
    position: relative;
  }
  .comman_modal_form .modal-body {
    padding: 35px 0;
  }
  .comman_modal_heading h2 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 15px;
  }
  .comman_modal_heading p {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .time_js span {
    font-weight: 600;
    color: #3e4093;
    font-size: 20px;
    display: block;
  }
  .otp_part .form-group .form-control {
    width: 46px;
    margin: 0 6px;
    border-radius: 10px;
    height: 46px;
    padding: 0;
    text-align: center;
    font-size: 14px;
  }
  .comman_modal_form .modal-dialog {
    max-width: 400px;
  }
  .banner_box {
    margin-top: 9rem;
  }
  .banner_slide {
    height: calc(100vh - 153px);
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-color: #000;
    opacity: 100%;
  }
  .banner_slides::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.9;
  }
  .category_btn {
    background-color: #f20;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    height: 120px;
    width: 120px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .banner_content {
    position: relative;
    z-index: 1;
  }
  .banner_content h2 {
    color: #fff;
    font-weight: 800;
    font-size: 46px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .banner_content p {
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    line-height: 28px;
  }
  .banner_sider .owl-nav button {
    background-color: #eb3237 !important;
    color: #fff !important;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    font-size: 30px !important;
  }
  .banner_sider .owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 95%;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .flavour_box_img {
    background-color: #000 !important;
  }
  section {
    overflow: hidden;
  }
  .banner_sider .owl-dots {
    position: absolute;
    z-index: 9;
    bottom: 28px;
    width: 100%;
    text-align: center;
    left: -1px;
    right: 0;
  }
  .banner_sider .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    display: inline-flex;
    background-color: #eb3237 !important;
    margin: 0 4px;
    transition: 0.5s all;
  }
  .banner_sider .owl-dots .owl-dot.active {
    width: 30px;
  }
  .form-design .form-floating > label {
    font-size: 14px;
    color: #3d3d3d;
    font-weight: 500;
    left: 18px;
  }
  .form-design .form-control {
    background: transparent;
    padding: 0 20px;
    font-size: 12px !important;
    height: 52px;
    border: 1px solid #3d3d3d7a;
  }
  .form-design .form-floating > label span {
    color: #f20;
  }
  .choose_file label {
    position: absolute;
    top: 30px;
    background-color: #3e4093;
    height: 52px;
    color: #fff;
    display: flex !important;
    align-items: center;
    width: 110px !important;
    justify-content: center;
    text-align: center;
    border-radius: 4px 0 0 4px;
    font-size: 11px !important;
    cursor: pointer;
  }
  .choose_file span {
    display: block;
    margin: 0 0 8px 0;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
  }
  .choose_file .form-control {
    height: 52px;
    padding: 14px 7px;
  }
  .select_dropdown {
    position: relative;
  }
  .select_dropdown::after {
    position: absolute;
    top: 20px;
    content: "";
    /* background-image: url(../img/arrow_down.png); */
    height: 10px;
    width: 12px;
    z-index: 9;
    right: 27px;
    background-size: 12px;
    background-repeat: no-repeat;
  }
  .form-heading h2 {
    font-weight: 700;
    font-size: 19px;
  }
  .form-heading p {
    font-size: 12px !important;
  }

  .newsletter_form {
    border-radius: 4px;
    overflow: hidden;
  }

  .news_leter_heading h3 {
    font-weight: 600;
    margin: 0;
    color: #fff;
  }
  .newsletter_section {
    background: #eb3237;
    padding: 25px 0 !important;
  }

  /*===================================*
      11.START FOOTER STYLE
    *===================================*/
  footer {
    overflow: hidden;
  }
  .footer_dark {
    background-color: #202325;
    position: relative;
  }
  .footer_top {
    padding: 60px 0 30px;
  }
  .footer_logo {
    margin-bottom: 20px;
  }
  footer p {
    font-size: 14px;
  }
  footer .widget {
    margin-bottom: 30px;
  }
  footer .widget_title {
    text-transform: capitalize;
    margin-top: 6px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .contact_info.contact_info_light {
    padding-left: 3px;
  }
  .border-top-tran {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .widget_links li {
    padding-bottom: 10px;
    list-style-type: circle;
    color: #fff;
  }
  .widget_links {
    padding-left: 20px;
  }
  .widget_links li:last-child {
    padding-bottom: 0;
  }
  .widget_links li a {
    font-size: 14px;
    color: #687188;
  }
  .contact_info > li {
    margin-bottom: 15px;
    list-style: none;
  }
  .contact_info > li:last-child {
    margin-bottom: 0px;
  }
  .contact_info li i {
    display: inline-block;
    margin-right: 10px;
    margin-top: 4px;
    font-size: 18px;
    vertical-align: top;
  }
  .contact_info li span {
    float: left;
    margin-right: 10px;
    max-width: 70px;
    width: 100%;
    color: #292b2c;
  }
  .contact_info span + * {
    overflow: hidden;
    color: #687188;
    margin: 0;
    font-size: 14px;
    line-height: 26px;
  }
  .contact_info i + * {
    overflow: hidden;
    font-size: 14px;
    color: #687188;
    line-height: 26px;
    margin: 0;
    vertical-align: middle;
    max-width: 78%;
    display: inline-block;
  }
  .contact_info_light li i {
    border-color: #fff;
    color: #fff;
  }
  .contact_info_light li span,
  .contact_info_light li a {
    color: #fff;
  }
  .contact_info_style2 i + * {
    font-size: 18px;
  }
  .contact_info_style2 li i {
    margin-right: 8px;
    margin-top: 2px;
    font-size: 22px;
    color: #ff324d;
  }
  .bottom_footer {
    padding: 30px 0;
    position: relative;
  }
  .footer_link li {
    display: inline-block;
    position: relative;
  }
  .footer_link li a {
    font-size: 14px;
    padding: 0 5px;
    color: #687188;
  }
  .footer_dark h1,
  .footer_dark h2,
  .footer_dark h3,
  .footer_dark h4,
  .footer_dark h5,
  .footer_dark h6,
  .footer_dark p,
  .footer_dark a,
  .footer_dark .widget_links li a,
  .footer_dark .footer_link li a,
  .footer_dark .post_content a {
    color: #fff;
  }
  .footer_dark a:hover,
  .footer_dark .widget_links li a:hover,
  .widget_links li a:hover,
  .footer_dark .footer_link li a:hover,
  .footer_link li a:hover {
    color: #ff324d;
  }
  .widget_contact_form {
    background-color: #fff;
    padding: 20px 30px 30px;
    margin-top: -190px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .bottom_footer .social_icons li {
    padding-bottom: 0;
  }
  .footer_payment li,
  .app_list li {
    display: inline-block;
    list-style: none;
  }
  .footer_call {
    border-radius: 40px;
    padding: 15px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .footer_call * {
    margin: 0;
    font-size: 22px;
  }
  .footer_call i {
    margin-right: 10px;
  }
  .footer_logo img {
    max-width: 150px;
  }
  .footer_logo a {
    display: inline-block;
    background-color: #fff;
    text-align: center;
    padding: 10px 12px;
    border-radius: 10px;
  }

  /*===================================*
      11.END FOOTER STYLE
    *===================================*/
  .product_parts_box {
    width: 100%;
    padding: 14px 14px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 10px;
    margin: 12px 0;
    background-color: #fff;
    cursor: pointer;
  }
  .product_parts_box_buy {
    width: 100%;
    padding: 14px 14px 15px;
    border: 2px solid #f1f1f1;
    border-radius: 10px;
    margin: 12px 0;
    background-color: #f2f2f2;
    cursor: pointer;
  }
  .slider_box {
    margin: 0 12px;
  }
  .partsproduct_img img {
    max-width: 100%;
    width: 10rem;
    height: 100px;
    object-fit: contain;
  }
  .partsproduct_img_buy img {
    max-width: 100%;
    width: 10rem;
    object-fit: contain;
  }
  .partsproduct_img_search {
    border-radius: 4px;
    overflow: hidden;
    background: #fff !important;
    padding: 4px !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    position: relative;
  }
  .partsproduct_img {
    border-radius: 7px;
    overflow: hidden;
    background: #fff;
    padding: 16px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
    position: relative;
  }

  .partsproduct_img_buy {
    border-radius: 7px;
    overflow: hidden;
    background: #fff;
    padding: 16px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .product_content a {
    color: #3e4093;
    font-weight: 600;
    font-size: 14px;
  }
  .rating_box a {
    font-size: 14px;
    color: #ffd000;
  }
  .price {
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 600;
    margin-top: -5px;
  }
  .number input {
    background-color: #fff;
    border: 0;
    border-radius: 0;
    height: 36px;
    text-align: center;
    padding: 10px 10px;
    font-size: 18px;
    font-weight: 600;
    width: 50px;
    margin: 0 0;
    outline: 0;
  }
  .price del {
    color: #b6b6b6;
    font-weight: 500;
    margin-left: 6px;
    display: inline-block;
  }
  .number span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #f1f1f1;
    border-radius: 6px;
    width: 34px;
    height: 34px;
    color: #bdbdbd;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }
  .product_parts_box .comman_btn {
    top: -100%;
    position: absolute;
    font-size: 20px;
    padding: 0;
    border-radius: 60px !important;
    z-index: 1;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product_parts_box:hover .comman_btn {
    top: 50%;
    transform: translateY(-50%);
  }
  .featured__box img {
    max-width: 100%;
    height: 140px;
    object-fit: contain;
  }
  .featured__box {
    padding: 20px 15px;
    width: 100%;
    max-width: 14%;
    color: #000 !important;
    font-weight: 500;
    font-size: 15px;
  }
  .Fcard {
    position: relative;
    max-width: 100%;
    height: 17rem;
    width: auto !important;
    background: #eb3237 !important;
    border-radius: 15px;
    overflow: hidden;
  }

  .Fcard::before {
    content: "";
    position: absolute;
    top: -50%;
    width: 100%;
    height: 100%;
    background: #3b4093 !important;
    transform: skewY(345deg);
    transition: 0.5s;
  }

  .Fcard:hover::before {
    top: -50%;
    transform: skewY(390deg);
  }

  .Fcard::after {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 600;
    font-size: 6em;
    color: rgba(0, 0, 0, 0.1);
  }

  .Fcard .imgBox {
    position: relative;
    max-width: 100%;
    max-height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    z-index: 1;
  }

  /* .Fcard .imgBox img {
      max-width: 4rem;
      
      transition: .5s;
  }
  
  .Fcard:hover .imgBox img {
      max-width: 50%;
        
  } */

  .Fcard .contentBox {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
  }

  .title_prod {
    font-size: 17px;
    position: relative;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .Fcard:hover .title_prod {
    opacity: 0;
  }

  .Fcard .contentBox .price {
    font-size: 24px;
    color: #000;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .Fcard .contentBox .buy {
    position: relative;
    top: 100px;
    opacity: 0;
    padding: 10px 30px;
    margin-top: 15px;
    color: #000;
    text-decoration: none;
    background: #f2f2f2 !important;
    border-radius: 30px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.5s;
  }

  .Fcard:hover .contentBox .buy {
    top: 60px !important;
    opacity: 1;
  }

  .brand_box {
    display: flex;
    background-color: #eff3ff;
    text-align: center;
    height: 200px;
    margin: 20px 22px;
    align-items: center;
    justify-content: center;
  }
  .brand_box img {
    max-height: 180px !important;
    object-fit: contain;
  }
  .brand_box img {
    height: 80px;
    object-fit: contain;
  }
  .cta_section {
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    padding: 45px 0;
  }
  /* .product_show_home {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    background-size: cover;

    background-position: center;
    background-attachment: fixed;
    height: 500px;
  } */
  .carousel-item_home img {
    max-width: 500px;
  }

  .product_parts_box:hover .partsproduct_img::after {
    opacity: 0.6;
  }
  .newsletter_form .form-design .form-control {
    background-color: #fff;
  }
  .forms_modal .form-design .form-floating > label {
    font-size: 12px;
    color: #3d3d3d;
    font-weight: 500;
    left: 8px;
  }
  .form-design .form-control:focus {
    border: 1px solid #3e4093;
  }
  .age_verification {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .age_verification::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .age_verification .container {
    z-index: 1;
    position: relative;
  }
  .verification_content {
    border: 2px dashed #f2f2f263;
    padding: 32px 20px;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.2);
  }
  .verification_content h2 {
    /* -webkit-text-stroke: 2px #000; */
    color: #fff;
    font-weight: 700;
    font-size: 50px;
    margin-bottom: 18px;
  }
  .verification_content span {
    -webkit-text-stroke: 1.2px #000;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
  }
  .verification_content .custom_checkboxs label::after {
    border: 2px solid #fff;
    height: 21px;
    width: 21px;
    border-radius: 2px;
  }
  .verification_content .custom_checkboxs label::before {
    top: 1px;
    left: 4px;
  }
  .verification_content .custom_checkboxs label {
    -webkit-text-stroke: 1px #fff;
    color: #000;
    font-weight: 500;
    font-size: 16px !important;
  }
  .verification_content .custom_check {
    position: relative;
    left: -4px;
    height: 20px;
    width: 20px;
    top: 4px;
  }
  .verification_content {
    border: 2px dashed #f2f2f263;
    padding: 32px 20px;
  }

  .view_category {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -20px;
    top: 50%;
    z-index: 99;
    background-color: #fff;
    transform: translateY(-50%);
    border-radius: 50px;
  }
  ._banner {
    height: 230px;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    background-position: center;
    display: flex;
    align-items: center;
  }
  .comman_banner {
    height: 140px !important;
    background-size: cover;
    position: relative;
    background-position: center;
    display: flex;
    align-items: center;
  }
  .comman_banner .container {
    z-index: 1;
  }
  .comman_banner h1 {
    font-weight: 700 !important;
    color: #fff;
    font-size: 35px;
  }
 
  .breadcrumb li a,
  .breadcrumb li {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #fff;
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-size: 12px;
  }
  .breadcrumb-item.active {
    color: #fff;
  }
  .comman_banner::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.6;
  }
  .width_adjust {
    width: 20%;
    margin-top: 38px !important;
  }
  .filter_design {
    border: 0;
    padding: 20px 20px;
    border-bottom: 2px solid #f2f2f2;
  }
  .product_single {
    background-color: #eef3ff;
  }
  .product_single_left {
    border-right: 2px solid #f2f2f2;
  }
  .filter_design .accordion-button {
    padding: 8px 0;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    color: #3d3d3d;
    box-shadow: unset;
  }

  .select_design {
    height: 48px;
    border: 1px solid #3e4093;
    font-size: 14px;
    box-shadow: unset;
  }
  .range_box input {
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    pointer-events: none;
    z-index: 3;
    height: 3px;
    top: 0;
    width: 100%;
    opacity: 0;
    margin: 0;
  }
  .range_box .slide {
    position: absolute;
    top: 0;
    height: 4px;
    background: #ccc;
    left: 9px;
    right: 9px;
  }
  .range_box .line {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;
    background-color: #3e4093;
  }
  .range_box .thumb {
    position: absolute;
    z-index: 2;
    text-align: left;
    border: 2px solid #3e4093;
    background-color: #fff;
    border-radius: 50%;
    outline: none;
    top: -7px;
    height: 20px;
    width: 20px;
    margin-left: -9px;
  }
  .range_box .display {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .checkbox_design {
    margin-bottom: 12px;
  }
  .checkbox_design label {
    font-size: 15px !important;
    padding-left: 36px;
    position: relative;
    font-weight: 500;
  }
  .checkbox_design label::before {
    position: absolute;
    background-color: #3e4093;
    top: 1px;
    left: 4px;
    opacity: 0;
    font-size: 15px;
  }
  .checkbox_design label::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border: 1px solid #3e4093;
    border-radius: 3px;
  }
  .checkbox_design input:checked ~ label::before {
    opacity: 1;
  }
  .range_box .range-slide {
    position: relative;
  }
  .radio_design label::after {
    border-radius: 50px;
  }
  .radio_design label::before {
    position: absolute;
    content: "";
    top: 3.5px;
    left: 3.5px;
    height: 15px;
    width: 15px;
    background-color: #234476;
    border-radius: 50px;
    opacity: 0;
  }
  .width_adjust_right {
    width: 70%;
  }
  .Paginate {
    display: flex;
    margin-top: 1rem !important;
    justify-content: space-between;
  }
  .fav_btn {
    background-image: url(../img/fav_icon.png);
    height: 22px;
    width: 24px;
    background-repeat: no-repeat;
    background-size: 24px;
    display: flex;
    justify-content: center;
    background-position: center;
    text-align: center;
    margin: 10px auto 0px;
    position: absolute;
    right: 0;
    top: -10px;
  }
  .product_content {
    position: relative;
  }

  .carousel-inner {
    margin-left: 0;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
    height: 620px !important;
  }
  .product_show .carousel-indicators {
    position: relative;
    width: 8rem;
    margin: 0;
    margin-right: 12px;
    display: block;
  }
  .flavours:hover {
    border: #3e4093 2px solid;
  }
  .selected-img {
    animation: fadeinout 1s ease-in !important;
    transition: 0.8s ease-in-out;
  }
  .product_show ::-webkit-scrollbar {
    width: 2px;
    color: #fff;
  }

  /* Track */
  .product_show ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .product_show ::-webkit-scrollbar-thumb {
    background: #fff;
  }

  .carousel-slider {
    max-height: 30rem !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .product_show .carousel {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.28) !important;
  }
  .product_show .carousel-item .productimg_show {
    background-color: #fff;
    height: 480px;
    padding: 70px !important;
    display: flex;
    border: 0px solid rgba(0, 0, 0, 0.16);
    align-items: center;
    justify-content: center;
  }
  .product_show .carousel-item img {
    max-width: 100%;
  }
  .product_show .carousel {
    position: relative;
    display: flex;
    width: 100%;
  }
  .product_show .carousel-indicators button {
    width: 100px !important;
    height: 90px;
    overflow: hidden;
    display: block;
    margin: 0 0 15px;
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19); */
    background-color: #fff !important;
    opacity: 1;
    padding: 0 !important;
  }

  .product_show .carousel-inner {
    margin-left: 15px;
  }
  .product_show .carousel-indicators button img {
    max-width: 90px;
  }
  .product_details_text h2 {
    font-weight: 600;
    font-size: 22px;
  }
  .product_details_text p {
    color: #8f8f8f;
    font-size: 16px;
    line-height: 25px;
    margin: 0;
  }
  .product_details_text .rating_box a {
    font-size: 16px;
  }
  .ratee_part span {
    font-size: 15px;
    color: #8f8f8f;
    display: block;
    margin-left: 10px;
  }
  .pricee_part strong {
    font-weight: 500;
    font-size: 30px;
    color: #000;
  }
  .pricee_part {
    display: flex;
    align-items: center;
  }
  .pricee_part span {
    display: inline-block;
    background-color: #f00;
    color: #fff;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 8px;
  }
  .product_details_btns a {
    position: relative;
    display: inline-flex;
    right: -30px;
  }
  .pricee_part del {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 500;
    margin: 0 14px;
  }
  .offers_head strong {
    font-size: 15px;
    font-weight: 500;
    display: block;
    margin-bottom: 6px;
  }
  .offer_icon img {
    max-width: 25px;
  }
  .offers_box_main p {
    font-size: 12px;
    color: #000;
    line-height: 18px;
    font-weight: 500;
  }
  .offers_box_main {
    border: 1.5px dashed #dbdbdb;
    margin-left: -12px;
  }

  .flavour_box {
    border: 1px solid #ced4da;
    border-radius: 10px;
  }

  .offers_box_main_afterSelect {
    border: 1.7px dashed #eb3237;
    margin-left: -12px;
  }

  .quantity_box .number input {
    height: 44px;
    font-size: 22px;
    width: 40px;
  }
  .quantity_box .number span {
    height: 44px;
    width: 44px;
    font-size: 24px;
  }
  .flavour_box a {
    background: #f2f2f2;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 13px;
    color: #3d3d3d;
    margin: 0 10px 10px 0;
    transition: 0.5s all;
  }
  .flavour_box a:hover {
    background: #eef3ff;
  }
  .flavour_box a:hover.disabled {
    background: #f2f2f2;
  }
  .flavour_box .disabled {
    opacity: 0.5;
  }

  .featured-details .accordion-button {
    border: 0;
    background: transparent;
    color: #656565;
    font-weight: 500;
    font-size: 16px;
    padding: 13px 0px 14px;
    box-shadow: unset;
    border-bottom: 2px solid #f2f2f2;
  }
  .featured-details .accordion-item {
    border: 0;
  }
  .btns_collapes {
    border-radius: 3px;
    height: 22px;
    width: 22px;
    border: 1px solid;
    margin-right: 30px;
  }
  .btns_collapes::after {
    position: absolute;
    content: "\f068";
    font-family: "Font Awesome 5 Pro";
    top: 13%;
    left: 5px;
    font-weight: 700;
    font-size: 13px;
  }
  .featured-details .accordion-button.collapsed .btns_collapes::after {
    content: "\f067";
  }
  .featured-details .accordion-body {
    padding: 30px 50px;
  }
  .featured-ul li {
    width: 33%;
    margin-bottom: 4px;
  }
  .featured-details .accordion-body p {
    margin-bottom: 10px;
    font-size: 15px;
    color: #8f8f8f;
  }
  .featured-ul {
    list-style: circle;
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
  }

  .accordion-button::after {
    display: none;
  }
  .ratingbox_main_left {
    text-align: center;
    padding: 60px 0;
    border-right: 1px solid #dee2e6;
  }
  .ratingbox_main_left h3 {
    font-size: 48px;
    margin: 0;
  }
  .ratingbox_main_left h3 span {
    color: #8f8f8f;
    font-size: 20px;
    display: inline-block;
  }
  .all_review {
    font-size: 15px;
    font-weight: 500;
  }
  .ratingbox_main_left p {
    font-size: 16px;
    color: #8f8f8f;
    line-height: 20px;
    margin: 0 0 12px;
  }
  .rating_progress {
    padding: 60px 0;
  }
  .rating_progress .progress {
    display: flex;
    height: 8px;
    overflow: hidden;
    background-color: #d9d9d9;
    border-radius: 0;
  }
  .rating_progress .text_wtihstart {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .question_answer_box h3 {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
  }
  .question_answer_box p {
    margin: 0;
    font-size: 15px;
    color: #8f8f8f;
    line-height: 25px;
  }
  .question_answer_box .que_tag {
    display: block;
    font-size: 15px;
    font-weight: 600;
  }
  .rating_progress .text_wtihstart i {
    color: #ffd000;
    font-size: 15px;
    margin-left: 6px;
  }
  .commam_tabs_design .nav-item {
    width: 50%;
    text-align: center;
  }
  .commam_tabs_design .nav-item .nav-link {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    border: 0;
    font-size: 18px;
    font-weight: 500;
    padding: 16px 0;
    background-color: #3e4093;
    border-radius: 0;
    color: #fff;
    opacity: 0.7;
  }
  .commam_tabs_design .nav-item .nav-link.active {
    opacity: 1;
  }

  .related_product .owl-nav button {
    background-color: #eb3237 !important;
    color: #fff !important;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    font-size: 30px !important;
    margin-left: -60px;
    margin-right: -60px;
  }
  .related_product .owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .cart_table tr th {
    border: 0;
    font-weight: 500;
    text-align: center;
    color: #8f8f8f;
    font-size: 16px;
    padding: 14px 20px;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .cart_table tr td:first-child {
    width: 800px;
  }

  .cart_table_2 {
    overflow-y: scroll;
    max-height: 35rem;
  }

  .cart_table_2 tr th {
    border: 0;
    font-weight: 500;
    text-align: center;
    color: #8f8f8f;
    font-size: 13px !important;
    padding: 14px 20px;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .cart_table_2 tr td:nth-child(2) {
    width: 700px;
  }
  .cart_table_2 tr td:nth-child(1) {
    width: 100px !important;
  }


  .cart_table_web tr th {
    border: 0;
    font-weight: 500;
    text-align: center;
    color: #8f8f8f;
    font-size: 13px !important;
    padding: 14px 20px;
    text-transform: uppercase;
    vertical-align: middle;
  }
  .cart_table_web tr td:nth-child(2) {
    /* position: relative; */
    padding-top: 3rem;
    
  }
  
 
  .cart_product {
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    border-radius: 8px;
    
  }
  .cart_content h3 {
    font-weight: 600;
    font-size: 18px;
  }
  .cart_content p {
    color: #8f8f8f;
    font-size: 14px;
    margin: 0;
  }
  .rate_main span {
    display: block;
    font-size: 14px;
    color: #8f8f8f;
    margin-left: 10px;
  }
  .remove_btn {
    color: #f00;
    background-color: #fff;
    font-weight: 600;
    margin-top: 0;
    font-size: 14px;
    display: block;
  }
  .cart_table .number span {
    height: 40px;
    width: 40px;
    font-size: 25px;
  }
  .cart_table .number input {
    font-size: 22px;
    height: 40px;
  }
  .pricetext {
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  .pricetext {
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  .cart_table tr th:first-child,
  .cart_table tr td:first-child {
    text-align: left;
  }
  .cart_table tr td {
    padding: 24px 20px;
    vertical-align: middle;
    text-align: center;
    border: 0;
  }
  .table_main > :not(:first-child) {
    border-top: 0;
  }
 
  .pricetext {
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  .pricetext {
    font-weight: 600;
    font-size: 18px;
    display: block;
  }
  .cart_table tr th:first-child,
  .cart_table tr td:first-child {
    text-align: left;
  }
  .cart_table tr td {
    padding: 24px 20px;
    vertical-align: middle;
    text-align: center;
    border: 0;
  }
  .table_main > :not(:first-child) {
    border-top: 0;
  }

  .custom_radio label {
    display: block;
    position: relative;
    padding-left: 40px;
  }
  .custom_radio input:checked ~ label::before {
    opacity: 1;
  }
  .custom_radio label::before {
    position: absolute;
    content: "";
    top: 19px;
    left: 3px;
    height: 14px;
    width: 14px;
    background-color: #234476;
    border-radius: 50px;
    opacity: 0;
  }
  .custom_radio input:checked ~ label .btn_show {
    display: block;
  }
  .btn_show {
    display: none;
    position: absolute;
    right: 0;
  }
  .address_data h3 {
    font-size: 18px;
    font-weight: 700;
  }
  .address_data p {
    margin: 0;
    font-size: 15px;
    color: #8f8f8f;
    line-height: 23px;
  }
  .custom_radio label::after {
    position: absolute;
    content: "";
    top: 16px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #d7d7d7;
    border-radius: 50px;
  }
  .chosse_location .custom_radio label::after {
    top: 1px;
  }
  .chosse_location .custom_radio label::before {
    top: 4px;
  }
  .chosse_location h3 {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 20px;
  }
  .Payment_form .form-group .custom_radio label {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
  }
  .form_design .form-select,
  .form_design .form-control {
    height: 40px !important;
    border: 1px solid;
    padding: 0 18px !important;
    font-size: 12px !important;
    font-weight: 500;
    box-shadow: unset !important;
  }
  .commentBox {
    border: none !important;
    padding: 0 22px;
    font-size: 10px !important;
    box-shadow: unset !important;
  }

  .card_img {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    max-width: 34px;
  }
  .Payment_form .form-group label::after {
    top: 4px;
  }
  .Payment_form .form-group label::before {
    top: 7px;
  }
  .Payment_form .form-group .promo_code label {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }
  .cardname_input {
    width: 20%;
  }
  .addcard_img img {
    max-width: 100%;
  }
  .add_cardform .form-group label {
    margin: 0px;
    text-transform: capitalize;
    color: #000;
    font-weight: 500;
    font-size: 14px;
  }
  .myaccount_profile {
    height: 12rem !important;
  }

  .radioBtn {
    position: relative;
    width: 20px;
  }
  .profileImage {
    width: 15rem;
    max-height: 12rem;
  }
  .account_profile .file-uploadsNew {
    opacity: 0% !important;
    position: absolute;
    width: 100px;
    height: 50px;
    right: 0;
    top: 0;
    font-size: 100px;
  }
  .account_profile {
    position: relative;
    height: 10rem !important;
  }
  .account_profile .circle {
    overflow: hidden;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
  .account_profile .p-image {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ffffff;
    color: #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .account_profile .p-camera {
    position: absolute;
    top: 0;
    right: -5px;
    background-color: #fff;
    color: rgb(14, 13, 13);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .account_detailss h2 {
    font-weight: 700;
    font-size: 20px;
    color: #000;
  }
  .account_detailss span {
    color: #8f8f8f;
    font-size: 16px;
    display: block;
  }
  .myaccount_tabs .nav-tabs {
    border: 0;
  }
  .myaccount_tabs .nav-tabs .nav-link {
    width: 100%;
    border: 0;
    text-align: left;
    background-color: #eb3237;
    border-radius: 0;
    padding: 18px 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    border-bottom: 1px solid #f1f1f1b5 !important;
    isolation: unset;
    display: flex;
    font-size: 14px;
    margin: 0;
    align-items: center;
  }
  .myaccount_tabs .nav-tabs .nav-link i {
    font-size: 20px;
    font-weight: 300;
    width: 30px;
  }
  .myaccount_tabs .nav-tabs .nav-link.active {
    background-color: #234476;
  }

  .filter_table img {
    max-width: 24px;
    margin-top: -4px;
  }
  .filter_table {
    display: inline-block;
    padding-left: 8px;
  }
  
  .cart_content h3 {
    font-weight: 600;
    font-size: 18px;
  }
  .cart_content p {
    color: #8f8f8f;
    font-size: 14px;
    margin: 0 0 18px;
  }
  .cart_table tr td {
    padding: 24px 20px;
    vertical-align: middle;
    text-align: center;
    border: 0;
  }
  .cart_content .ordertext {
    font-size: 14px;
    color: #8f8f8f;
    font-size: 14px;
  }
  .myaccount .cart_table tr td {
    padding: 15px 20px;
  }
  .toggle-password {
    position: absolute;
    right: 26px;
    top: 28px;
    font-size: 14px;
  }
  .toggle-password3 {
    position: relative;
    left: 92%;
    top: -34px;
    font-size: 15px;
  }
  .toggle-password4 {
    position: relative !important;
    left: 94% !important;
    top: -34px;
    font-size: 15px;
  }
  .myfavourites {
    overflow-y: scroll;
    max-height: 22rem;
  }
  .myfavourites::-webkit-scrollbar {
    width: 6px !important;
    margin-left: 2px !important;
  }
  .myfavourites::-webkit-scrollbar-track {
    /* background-color: #3e4093; */
    border-radius: 50px;
  }
  .myfavourites::-webkit-scrollbar-thumb {
    background-color: #3e4093 !important;
    border-radius: 50px;
    cursor: pointer !important;
  }
  .myfavourites .partsproduct_img img {
    max-width: 100%;
    object-fit: contain;
  }
  .myfavourites .partsproduct_img {
    max-height: 120px;
    position: relative;
  }
  .myfavourites .product_content a {
    font-size: 14px;
  }
  .myfavourites .fav_btn {
    position: unset;
  }
  .myfavourites .product_parts_box {
    padding: 5px !important;
    padding-bottom: 3px !important;
  }
  .main_menudata .accordion-button {
    background-color: #eb3237;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
  }
  .main_menudata .accordion-body {
    font-size: 15px;
    font-weight: 400;
  }
  .main_menudata .accordion-item {
    border-radius: 10px;
    overflow: hidden;
  }
  .main_menudata .accordion-button {
    border-radius: 10px !important;
    background-color: #eb3237;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
  }
  .main_menudata .accordion-button::after {
    display: block;
    filter: brightness(40.5);
  }
  .product_single_left .accordion-button::after {
    display: block;
  }
  .subCatImages {
    max-width: 4.5rem;
    max-height: 5rem;
  }
  .subCatImages2 {
    max-width: 10rem;
    max-height: 9rem;
  }
  .previewImages {
    max-width: 5rem;
    max-height: 5.5rem;
    color: #3e4093;
    cursor: zoom-in !important;
  }
  .image_box {
    position: relative;
    left: 0 !important;
  }
  .edit_galllery {
    overflow-y: scroll;
    max-height: 23rem;
  }

  .order_tabs .nav-item .nav-link {
    border: 0;
    border-radius: 0;
    padding: 12px 30px;
    color: #234476;
    font-size: 14px;
    background-color: #f2f2f2;
    border-right: 1px solid #f2f2f242;
    width: 100%;
    font-weight: 500;
  }
  .order_tabs .nav-item {
    width: 50%;
  }
  .order_tabs .nav-item .nav-link.active {
    background-color: #234476;
    color: #fff;
  }
  .data_main {
    font-weight: 500;
    font-size: 14px;
  }
  .data_submain {
    font-weight: 600;
    font-size: 14px;
  }
  .small_header {
    position: absolute;
    top: -10px;
    font-weight: 700;
    font-size: 14px;
    background-color: #fff;
    display: inline-block !important;
    width: auto;
    text-transform: uppercase;
  }
  .track_circle {
    height: 14px;
    width: 14px;
    background-color: #234476;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    margin: 0 auto 10px;
    position: relative;
    z-index: 4;
  }
  .track_order li {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0 30px;
    position: relative;
    z-index: 1;
  }
  .track_order {
    display: flex;
    justify-content: center;
    position: relative;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 2500px) {
    .track_order li::after {
      position: absolute;
      content: "";
      height: 4px;
      width: 150px;
      background-color: #f2f2f2;
      left: 81px;
      top: 4px;
      z-index: 0;
    }
  }
  .track_order li::after {
    position: absolute;
    content: "";
    height: 4px;
    width: 150px;
    background-color: #f2f2f2;
    left: 69px;
    top: 4px;
    z-index: 0;
  }
  .track_order li::before {
    position: absolute;
    content: "";
    height: 4px;
    width: 170px !important;
    background-color: #3e4093;
    left: 81px !important;
    top: 4px;
    z-index: 1;
    opacity: 0;
  }
  .track_order li.active::before {
    opacity: 1;
  }
  .track_order li:last-child:after,
  .track_order li:last-child:before {
    background-color: #fff;
    width: 50px;
  }
  .contact_us .form-design textarea.form-control {
    height: 200px;
    resize: none;
  }
  .header_bottom li .dropdown-menu {
    background: #fff;
    backdrop-filter: blur(15px) saturate(0.5);
    transition: visibility 0.1s, margin-top 0.1s, transform 0.1s,
      opacity 0.1s linear, -webkit-transform 0.1s;
    transform: scale(0.9);
    transform-origin: top;
    display: block;
    height: 0px;
    border-radius: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 40px;
    min-width: 100% !important;
    max-width: 100%;
    overflow: hidden;
  }
  .header_bottom li:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, transform 0.5s, opacity 0.3s linear,
      -webkit-transform 0.5s;
    border: 0;
    background-color: #fff;
    height: auto;
    transform: scale(1);
  }
  .maga_drop__menus .dropdown_heading {
    color: #202325;
    font-size: 15px;
    position: relative;
    text-decoration: none;
    display: block;
    margin-bottom: 14px;
    font-weight: 600;
    background: fixed;
    border-left: 4px solid #3e4093;
    padding: 3px 6px 3px 12px;
  }
  .maga_drop__menus .dropdown_heading::before {
    background: #eb3237;
    color: #fff !important;
    text-decoration: none;
    content: "";
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-in-out;
    z-index: -1;
  }
  .dropdown_heading:hover:before {
    transform: scaleX(1);
    transform-origin: left;
    color: #fff;
  }

  .dropdown_heading:hover {
    color: #fff !important;
    text-decoration: none;
  }

  .mega_dropp {
    width: 10%;
  }

  .dropViewAll {
    position: relative;
    left: 45%;
    top: 10px;
    --b: 0.2em; /* the thickness of the line */
    --c: #3b4093; /* the color */
    width: 10rem;
    color: #0000;
    font-weight: 600;
    cursor: pointer;
    text-align: center !important;
    padding-block: var(--b);
    background: linear-gradient(var(--c) 50%, #000 0) 0%
        calc(100% - var(--_p, 0%)) / 100% 200%,
      linear-gradient(var(--c) 0 0) 0% var(--_p, 0%) / var(--_p, 0%) var(--b)
        no-repeat;
    -webkit-background-clip: text, padding-box;
    background-clip: text, padding-box;
    transition: 0.3s var(--_s, 0s) linear,
      background-size 0.3s calc(0.3s - var(--_s, 0s));
  }
  .dropViewAll:hover {
    --_p: 100%;
    --_s: 0.3s;
  }

  .brands_page {
    background-color: #f6f7fa;
  }
  .maga_drop__menus ul {
    padding: 0 0 0 36px;
    list-style: circle;
  }
  .maga_drop__menus ul li a {
    color: #000;
    font-size: 14px;
    display: block;
    transition: 0.5s all;
  }
  .maga_drop__menus {
    padding: 10px 10px 5px;
  }
  .maga_drop__menus ul li {
    margin: 0;
    color: #000;
    margin-bottom: 4px;
    list-style: circle;
  }
  .notification {
    user-select: none;
    position: relative;
    width: 20rem;
    max-height: 30rem;
    top: 10px !important;
    box-shadow: inset;
    overflow: scroll;
  }
  .notification-heading,
  .notification-footer {
    padding: 0px 5px;
  }

  .notifications {
    min-width: 420px;
  }

  .notifications-wrapper {
    overflow: scroll;
    max-height: 250px;
  }

  .menu-title {
    color: #3b4093;
    font-weight: 700;
    font-size: 1.5rem;
    display: inline-block;
  }

  .glyphicon-circle-arrow-right {
    margin-left: 10px;
  }

  .notification-heading,
  .notification-footer {
    padding: 0px 10px;
  }

  .dropdown-menu.divider {
    margin: 5px 0;
  }

  .item-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    font-size: 0.8rem;
    font-weight: bold;
  }

  .notifications a.content {
    text-decoration: none;
    background: #ccc;
  }

  .remove-Notity {
    position: relative;
    top: -3px;
    left: 8px !important;
    font-size: 0.8rem;
  }

  .notification-Item {
    padding: 4px;
    margin: 3px;
  }
  .notification-Item .dropdown-item {
    background: #eef3ff;
  }
  .notification-Item .dropdown-item .noti-body {
    font-size: 0.7rem;
  }
  .notification-Item .dropdown-item:hover {
    background: #e22f35;
    color: #fff;
  }
  .header_bottom .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .header_bottom .dropdown-toggle::before {
    position: absolute;
    content: "";
    bottom: -20px;
    height: 59px;
    width: 100%;
    background-color: transparent;
    display: flex;
    left: 0;
    right: 0;
    z-index: 0;
  }
  
  .brands_ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 !important;
  }
  .brands_ul li {
    width: 23%;
    margin: 8px;
  }
  .brands_ul li :hover h5 {
    background-color: #000;
  }
  .brands_ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    margin-right: 8px !important;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }
  .brands_ul li a img {
    height: 100px;
    object-fit: contain;
    transition: 0.5s all;
  }
  .brands_ul li a:hover img {
    filter: unset;
    transform: scale(1.3);
  }
  .brands_ul li:nth-child(0n + 4) a {
    border-right: 0;
  }
  .Checkout_address span {
    display: inline-flex;
    position: absolute;
    top: -11px;
    background-color: #fff;
    width: auto;
    font-size: 14px;
    font-weight: 600;
  }
  .Checkout_address {
    border: 2px solid #234476;
    position: relative;
    padding: 20px 10px 15px;
  }
  .Checkout_address h2 {
    font-weight: 700;
    font-size: 24px;
    margin: 0 0 5px;
  }
  .Checkout_address p {
    font-weight: 500;
    font-size: 15px;
  }
  .thankyou {
    height: calc(100vh - 153px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
  }
  .thankyou_content {
    border: 2px dashed #f2f2f263;
    padding: 32px 20px;
    position: relative;
    z-index: 1;
    text-align: center;
    color: #fff;
  }
  .thankyou_content h1 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 15px;
  }
  .thankyou_content p {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 23px;
  }
  .thankyou::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.7;
  }
  .order_heading h2 {
    font-size: 19px;
    font-weight: 700;
  }
  .my_orderbox {
    border: 3px solid #f2f2f2;
    width: 100%;
    background: #f2f2f2;
    border-radius: 10px;
    padding: 10px 20px;
    color: #3d3d3d !important;
  }
  .status_order {
    position: absolute;
    right: 3px;
    background-color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 10px;
    top: 3px;
    border-radius: 7px;
  }
  .order_id {
    font-weight: 500;
    font-size: 14px;
  }
  .date_box {
    font-weight: 600;
    font-size: 14px;
  }
  .items_box {
    background-color: #fff;
    margin-top: 16px;
    padding: 12px 14px;
    border-radius: 5px;
  }
  .items_box h2 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .items_box ul li {
    font-size: 14px;
    font-weight: 500;
    list-style: circle;
    padding-left: 0;
  }
  .items_box ul {
    padding-left: 20px;
  }
  .detail_product_img {
    height: 152px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    border-radius: 8px;
  }
  .detail_product_img img {
    height: 115px;
  }
  .detail_product {
    border: 2px solid #f2f2f2;
    border-radius: 8px;
    padding: 12px 0px;
  }
  .detail_text {
    font-size: 14px;
    margin: 10px 0;
  }
  .detail_text strong {
    font-weight: 600;
  }

  /* home pagecss */

  .home_page {
    background-color: #eef3ff;
  }
  .custom-titles {
    position: relative;
    width: 60% !important;
    top: 12rem;
    left: 5rem !important;
    padding: 1rem;
  }
  .organic-heading {
    font-size: 5rem;
    font-weight: bolder;
  }
  .text-header {
    font-size: 10px;
    margin-left: 2rem;
    overflow: hidden;
  }

  .banner_slide {
    background: linear-gradient(to right, rgb(0 0 0 / 0.8), rgb(0 0 0 / 0));
    background-size: cover;
    /* height: 45rem; */
    background-position: center;
    position: relative;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }
  .frame-container {
    position: relative;
    padding-bottom: 50%; /* 16:9 */
    width: 450%; /* enlarge beyond browser width */
    height: 80%;
    left: -175%; /* center */
  }

  .frame-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .home {
    margin-top: 1.7rem;
  }
  .home .carousel-item::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
  }
  .banner_slide::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.6;
  }
  .slide1 {
    background-image: url("../../assets/img/banner_img1.jpg");
  }
  .banner_content {
    position: relative;
    z-index: 1;
  }
  .banner_content h2 {
    color: #fff;
    font-weight: 800;
    font-size: 46px;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .banner_content p {
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    line-height: 28px;
  }
  .banner_sider .owl-nav button {
    background-color: #eb3237 !important;
    color: #fff !important;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    font-size: 30px !important;
  }
  .signBtnHome {
    z-index: 999;
  }
  .banner_sider .owl-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 95%;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  section {
    overflow: hidden;
  }
  .banner_sider .owl-dots {
    position: absolute;
    z-index: 9;
    bottom: 28px;
    width: 100%;
    text-align: center;
    left: -11px;
    right: 0;
  }
  .banner_sider .owl-dots .owl-dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    display: inline-flex;
    background-color: #eb3237 !important;
    margin: 0 4px;
    transition: 0.5s all;
  }
  .banner_sider .owl-dots .owl-dot.active {
    width: 30px;
  }

  .carousel-caption {
    position: relative;
    top: 250px;
    padding: 0%;
    width: 45%;
  }
  .banner-titles {
    position: relative;
    text-align: center;
    top: 250px;
    left: 400px !important;
    padding: 0%;
    width: 45%;
  }

  .bannerTitle2 {
    top: 250px;
    left: 600px !important;
    padding: 0%;
    width: 45%;
  }

  .Bannertext {
    position: relative;
    z-index: 1;
    top: -70px;
  }
  .bannerTxt {
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 10px;
    font-weight: bolder;
    top: -70px;
    font-family: "Popins" sans-serif;
  }
  .comman_btn22 {
    background-color: #eb3237;
    position: relative;
    top: -60px;
    padding: 14px 32px;
    font-size: 15px;
    font-weight: 600;
    transition: 0.5s all;
    color: #fff;
    border-radius: 50px;
    border: 0;
    display: inline-flex;
  }
  .slideBtn {
    background-color: #eb3237;
    border-radius: 50px !important;
    padding-left: 0;
  }

  .comman_btn2 {
    background-color: #eb3237;
    padding: 14px 32px;
    font-size: 15px;
    font-weight: 600;
    transition: 0.5s all;
    color: #fff;
    border-radius: 50px;
    border: 0;
    display: inline-flex;
  }
  .comman_btn2:hover {
    background-color: #3e4093;
    color: #fff;
  }
  .featured__box img {
    max-width: 100%;
    height: 140px;
    object-fit: contain;
  }
  .featured__box {
    padding: 20px 15px;
    width: 100%;
    max-width: 14%;
    color: #000 !important;
    font-weight: 500;
    font-size: 15px;
  }
  .featured_category {
    background-color: #fff;
    margin-top: 40px;
  }
  .comman_head h2 {
    font-weight: 800;
    font-size: 33px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
  }
  .product_parts_box {
    width: 100%;
    padding: 14px 14px 15px;
    border: 2px solid #e2e0e0;
    border-radius: 8px;
    margin: 12px 0;
    background-color: #fff;
    cursor: pointer;
  }
  .product_parts_box_search {
    width: 100%;
    height: 20rem;
    padding: 12px 12px 13px;
    border: 2px solid #e2e0e0;
    border-radius: 8px;
    margin: 12px 0;
    background-color: #fff;
    cursor: pointer;
  }
  .partsproduct_img {
    background-color: #fff;
    border-radius: 10px;
  }
  .partsproduct_img_buy {
    background-color: #fff;
    border-radius: 10px;
  }
  .partsproduct_img img {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
  .partsproduct_img_search img {
    width: 100%;
    height: 130px;
    object-fit: contain;
  }

  .product_show_home img {
    max-width: 450px;
  }
  .brand_box {
    display: flex;
    background-color: #fff;
    text-align: center;
    height: 210px;
    border-radius: 8px;
    margin: 10px 12px;
    border: 2px solid #e2e0e0;

    align-items: center;
    justify-content: center;
  }
  .brand_box img {
    max-height: 140px;
    object-fit: contain;
  }
  .brand_box img {
    height: 80px;
    object-fit: contain;
  }
  .modalContent {
    margin: 0;
    /* background-image: url("../img/verification_bg.jpg"); */
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    background-position: center;
    top: 0 !important;
  }
  .modalHeadBg {
    background: url("../img/verification_bg.jpg");
    background-size: cover;
    scroll-behavior: smooth;
    background-attachment: fixed;
    background-position: center;
  }
  .modalContent::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .modal-header {
    border-bottom: 0 !important;
  }
  /* end */
  /* terms condition model */
  .wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    padding: 2rem;
    background-color: #eee;
  }
  .modal_content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;
    background-color: #fff;
    width: 768px;
    height: 100%;
    border-radius: 0.25rem;
    box-shadow: 0rem 1rem 2rem -0.25rem rgba(0, 0, 0, 0.25);
  }
  .TCheading {
    font-family: "Popins" sans-serif;
  }
  .TAccept {
    background-color: #eb3237;
    color: #fff;
    border: 1px dashed #eb3237;
    transition: 0.3s all;
    font-size: 15px;
    font-weight: 600;
    padding: 12px 34px;
    display: inline-block;
    border-radius: 30px;
  }

  /*  */
  /* contact us */
  .messageBox {
    height: 10rem;
  }
  .Contacts {
    background-image: url("../../assets/img/banner_img1.jpg");
    background-position: center;
    background-size: cover;
  }
  .errorText {
    font-size: 12px;
    color: red;
  }
  /*  */
  .otp_input {
    width: 16% !important;
    height: 3rem;
  }

  .checkbox {
    color: red;
  }
  .line-break {
    border-bottom: 2px solid gray;
  }
  .SignUp {
    background-color: #eef3ff;
  }

  /* my account */

  .nav-active {
    background-color: #234476;
  }
  .nav-link i {
    font-size: 20px;
    width: 30px;
  }
  .comman_banner {
    background-image: url(../img/banner_2.png);
  }
  .totalPage {
    position: relative;
    font-weight: 600;
    padding: 2px;
    top: 6px;
    border-radius: 8px;
    color: #234476;
  }
  ._banner {
    height: 230px;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    background-position: center;
    display: flex;
    align-items: center;
  }
  .comman_banner .container {
    z-index: 1;
  }
  .comman_banner h1 {
    font-weight: 700;
    color: #fff;
    margin: 0;
  }

  .breadcrumb li {
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .arrow {
    position: relative;
    top: 0px;
    font-size: 18px;
  }

  /*  */
  .profile {
    min-width: 150px;
    max-height: 180px;
  }
  /* DROPdown */
  .dropbtn {
    background-color: #04aa6d;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
  }

  .dropdowns {
    position: relative;
    display: inline-block;
  }

  .dropdown-btns {
    background-color: #4caf50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown-contents {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-contents a {
    color: black;
    padding: 5px 5px;
    text-decoration: none !important;
    display: block;
  }

  .dropdown-contents a:hover {
    background-color: #e5e0e0;
  }

  .dropdowns:hover .dropdown-contents {
    display: block;
  }

  .dropdowns:hover .dropdown-btns {
    background-color: #eb3237;
  }

  .dropdownsp {
    position: relative;
    display: inline-block;
  }

  .dropdown-btnsp {
    background-color: #3b4093;
    color: white;
    width: 70px !important;
    font-size: 20px;
    border: none;
    cursor: pointer;
  }

  .dropdown-contentsp {
    display: none;
    position: absolute;
    right: 0px;
    margin-top: 2px;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-contentsp a {
    color: black;
    padding: 4px 4px;
    text-decoration: none !important;
    display: block;
  }

  .dropdown-contentsp a:hover {
    background-color: #efebeb;
  }

  .dropdownsp:hover .dropdown-contentsp {
    display: block;
  }
  /* sort */
  .dropdown_sort {
    position: relative;
    display: inline-block;
  }

  .dropdown-btn_sort {
    background-color: #eb3237;
    color: white;
    width: 65px !important;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }

  .dropdown-content_sort {
    display: none;
    position: absolute;
    right: 0px;
    background-color: #f9f9f9;
    min-width: 70px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 2px;
  }

  .dropdown-content_sort a {
    color: black;
    padding: 4px 4px;
    text-decoration: none !important;
    display: block;
  }

  .dropdown-content_sort a:hover {
    background-color: #efebeb;
  }

  .dropdown_sort:hover .dropdown-content_sort {
    display: block;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    left: 4px;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    list-style: none;
    left: 3px;
    background-color: #f1f1f1;
    min-width: 105px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 8px 4px;
    text-decoration: none;
    display: block;
    border-radius: 18px;
    margin-top: 0 !important;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    color: #3e4093;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
  .element {
    -webkit-transition: 2s;
    transition: 2s;
  }
  .element:hover {
    /* .element.hidden */
    opacity: 0;
    pointer-events: none; /* <-- This one */
  }

  .dropdown-center {
    padding: 0;
  }

  /* Website Finish */

  /* Admin */
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
  @import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
  h1 h2 p label {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
  }
  .admin_panel_data {
    padding: 50px;
  }
  .admin_panel_data_orders {
    padding: 25px 50px !important;
  }

  .height_adjust {
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .admin_main {
    margin-left: 17%;
    transition: 0.1s all;
  }
  .expanded_main {
    margin-left: 0px;
    transition: 0.1s all;
  }

  .admin_full {
    margin: 0;
  }

  .admin_header {
    height: 60px;
    background-color: #3e4093;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .sidebar_btn {
    font-size: 14px;
  }

 
  .vaccine_category {
    margin: 0 20px;
  }
  .hide_sidebar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    width: 0px;
    height: 100vh;
    background-image: url(../../assets//img/verification_bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-right: 10px solid #eb3237;
    transition: 0.1s all;
  }
  .hide_sidebar::after {
    position: absolute;
    top: 0;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.8;
  }


  .sidebar_logo img {
    max-width: 130px;
    padding: 12px;
    background-color: #fff;
    border-radius: 25px;
  }

  .sidebar_logo {
    text-align: center;
    padding: 40px 20px;
  }

  .hide_logo img {
    display: none;
  }
  

  .admin_panel_data {
    padding: 50px;
  }
  .height_adjust {
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .comman_table_design td {
    color: #000;
    font-size: 13px !important;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 2px solid #f2f2f2;
    white-space: nowrap;
  }
  .dashboard_box {
    background-color: #3e4093;
    border-radius: 10px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px #0003;
  }
  .dashboard_icon {
    color: #3e4093;
    font-size: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: 46px;
    margin-left: -36px;
    border-radius: 50px;
    border-left: 2px dashed #3e4093;
  }
  .dashboard_boxcontent h2 {
    margin: 0 0 6px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
  .dashboard_boxcontent span {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    display: block;
  }
  .comman_header {
    background-color: #eb3237;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px 10px 0 0;
    height: 60px;
  }
  .comman_header h2 {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }
  .design_outter_comman {
    overflow: unset;
    border-radius: 6px !important;
    background-color: #fff;
    margin-top: 6px;
  }
  .design_outter_comman .comman_header .form-design .form-control {
    height: 38px;
    border-radius: 6px;
    padding: 0 17px;
    box-shadow: unset !important;
    border: 0;
    font-size: 13px;
  }
  .design_outter_comman .comman_header .icons_set i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: #3e4093;
    font-weight: 600;
    cursor: pointer;
  }
  .comman_table_design th {
    color: #3e4093;
    background-color: #f2f2f2;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    border: 0;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
  .comman_table_design td {
    color: #000;
    font-size: 14px;
    padding: 15px 20px;
    border: 0;
    text-align: center;
    vertical-align: middle;
    border-bottom: 2px solid #f2f2f2;
    white-space: nowrap;
  }
  .table > :not(:first-child) {
    border-top: 0;
  }
  .table_viewbtn {
    padding: 5px 11px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
  }
  .check_toggle label {
    position: relative;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    padding-right: 27px;
  }

  .check_toggle label::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 38px;
    height: 18px;
    background-color: #cacaca;
    border-radius: 10px;
    transition: 0.5s all;
  }
  .check_toggle {
    width: 100%;
    margin-top: -29px;
    margin-left: 10px;
  }
  .view_eye {
    font-size: 17px;
  }
  .check_toggle label::before {
    position: absolute;
    content: "";
    top: 2px;
    right: 21px;
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    border-radius: 50px;
    z-index: 1;
    transition: 0.5s all;
  }

  .check_toggle input:checked ~ label::after {
    background-color: #3e4093;
  }

  .check_toggle input:checked ~ label::before {
    right: 3px;
    background-color: #fff;
  }

  .check_radio label {
    position: relative;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    padding-right: 27px;
  }
  .check_radio label::after {
    content: "";
    top: 0;
    position: absolute;
    left: 0;
    border: 1px solid #3e4093;
    background-color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 3px;
  }
  .check_radio {
    margin-top: -50px;
  }
  .check_radio label::before {
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    top: 1px;
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    color: #3e4093;
    font-size: 12px;
    font-weight: 700;
    z-index: 1;
    opacity: 0;
    transition: 0.5s all;
  }
  .check_radio input:checked ~ label::before {
    opacity: 1;
  }
  .td_check_radio {
    margin-top: -30px;
  }

  .user_imgg {
    height: 100px;
    width: 100px;
    display: flex;
    margin: 0 auto 20px;
    align-items: center;
    justify-content: center;
    background-color: #eef3ff;
    border-radius: 50px;
  }
  .user-details-part .check_toggle {
    margin: 0;
    position: absolute;
    right: 0;
    left: auto;
    text-align: right;
    top: 33px;
  }
  .order_details_box {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 18px 6px;
  }
  .order_img {
    text-align: center;
  }
  .order_img img {
    max-height: 110px;
  }
  .order_img {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product_details a {
    font-weight: 600;
    color: #3e4093;
    font-size: 16px;
  }
  .price {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    margin-top: 4px;
  }
  .preview_icon {
    font-size: 14px !important;
    position: relative;
    left: -45% !important;
  }
  .preview_icon2 {
    font-size: 14px !important;
    position: relative;
    top: 0px;
  }
  .preview_image {
    width: 100%;
    height: 65vh;
  }
  .deleteBtnCat {
    position: relative;
    top: 25px !important;
    left: 20px !important;
  }
  .flavour {
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0;
  }
  .nav-tabs_usr .nav-link {
    width: 50%;
    border: 0;
    background-color: #f2f2f2;
    font-weight: 600;
    padding: 14px 15px;
    border-right: 3px solid #ffff;
    border-radius: 0;
    color: #3e4093;
  }
  .nav-tabs_prod .nav-link.active {
    background-color: #3e4093;
    color: #fff;
  }

  .nav-tabs_prod .nav-link {
    width: 33.3333333%;
    border: 0;
    background-color: #f2f2f2;
    font-weight: 600;
    padding: 14px 15px;
    border-right: 3px solid #ffff;
    border-radius: 0;
    color: #3e4093;
  }
  .nav-tabs_prod .nav-link.active {
    background-color: #3e4093;
    color: #fff;
  }

  .user-management-tabs .nav-tabs .nav-link {
    width: 33.33%;
    border: 0;
    background-color: #f2f2f2;
    font-weight: 600;
    padding: 14px 15px;
    border-right: 3px solid #ffff;
    border-radius: 0;
    color: #3e4093;
  }
  .user-management-tabs .nav-tabs .nav-link:last-child {
    border: 0;
  }
  .user-management-tabs .nav-tabs .nav-link.active {
    background-color: #3e4093;
    color: #fff;
  }
  .user-management-tabs .nav-tabs {
    border: 0;
  }
  /* .circle_count {
    align-items: center;
    justify-content: center;
    background-color: #3e4093;
    color: #fff;
    font-size: 11px;
    margin-left: 5px;
  } */
  .user-management-tabs .nav-tabs .nav-link.active .circle_count {
    background-color: #fff;
    color: #3e4093;
  }
  .Status_box {
    color: #fff;
    font-size: 15px;
  }
  .closebtn {
    display: flex;
    flex-direction: row-reverse;
    text-decoration: none;
    margin: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #eb3237;
  }
  /* .main_headers {
    font-family: "Rubik", sans-serif;
  } */
  /* pending view */
  .Pending-view .Pending-view_img {
    margin: 0 auto;
    max-height: 150px;
    max-width: 150px;
  }
  .Pending-view_img img {
    max-width: 100%;
    border-radius: 10px;
    max-height: 180px;
  }
  .user_name {
    font-weight: 700;
    font-size: 19px;
    margin-top: 20px;
  }
  .view-inner-box span {
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: -10px;
    background-color: #fff;
    display: inline-flex;
    padding: 0 10px;
    left: 9px;
    width: auto;
  }
  .view-inner-box strong {
    font-size: 14px;
    color: #3e4093;
  }
  .view-inner-box {
    border-radius: 6px;
    padding: 13px 5px;
    position: relative;
  }
  .img_box_show label {
    width: 100%;
    background-color: #f2f2f2;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-radius: 4px;
    cursor: pointer;
  }
  .img_box_show label i {
    font-size: 26px;
    display: block;
    margin-bottom: 8px;
  }
  .circles {
    width: 100px !important;
    position: relative;
    left: 28%;
    max-height: 100px !important;
  }
  .account_profile .circles .profile-pic {
    width: 180px !important;
    max-height: 130px !important;
    object-fit: cover;
    border: 2px solid #0000 !important;
  }
  .flavourImage {
    width: 160px !important;
    height: 100%;
    padding: 0.5rem;
  }
  .flavourImage .imageSection .flavour-pic {
    height: 200px;
    object-fit: cover;
  }
  .account_profile .SlideCms {
    width: 400px !important;
    height: 180px !important;
    object-fit: cover;
  }
  .account_profile .cmsSlide {
    overflow: hidden;
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ffffff;
    margin: 0 auto;
  }
  .account_profile .circle {
    overflow: hidden;
    width: 230px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #ffffff;
    margin: 0 auto;
  }
  .upload-button {
    position: relative;
    left: 0;
  }
  .fileName {
    position: relative;
    top: 0;
  }

  .flavourImage .p-image {
    position: absolute;
    top: -1px;
    right: 6px;
    background-color: #ffffff;
    color: #e0dce1;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    font-size: 13px;
    border: 2px solid #dfdddf;
  }

  .filter_check label {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding-left: 30px;
  }
  .filter_check label::after {
    content: "";
    top: 0;
    position: absolute;
    left: 0;
    border: 2px solid #3e4093;
    background-color: #fff;
    height: 22px;
    width: 22px;
    border-radius: 50px;
  }
  .filter_check label::before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";

    top: 3px;
    position: absolute;
    left: 5px;
    height: 18px;
    width: 15px;
    border-radius: 3px;
    color: #3e4093;
    font-size: 12px;
    font-weight: 700;
    z-index: 1;
    opacity: 0;
    transition: 0.5s all;
  }
  .filter_check input:checked ~ label::before {
    opacity: 1;
  }
  .choose_file span {
    display: block;
    margin: 0 0 8px 0;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
  }
  .choose_fil .form-control {
    height: 52px;
    padding: 14px 15px;
  }
  .choose_fil label {
    position: absolute;
    top: 25px;
    left: 0px;
    background-color: #eb3237;
    height: 50px;
    color: #fff;
    display: flex !important;
    align-items: center;
    width: 110px;
    justify-content: center;
    text-align: center;
    border-radius: 8px 0 0 8px;
    font-size: 12px !important;
    cursor: pointer;
  }

  .containerss {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #fcfcfc;
  }

  .card {
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    width: 600px;
    height: 260px;
    background-color: #ffffff;
    padding: 10px 30px 40px;
  }

  .card h3 {
    font-size: 22px;
    font-weight: 600;
  }

  .drop_box {
    margin: 10px 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 3px dotted #a3a3a3;
    border-radius: 5px;
  }

  .drop_box h4 {
    font-size: 20px;

    font-weight: 600;
    color: #2e2e2e;
  }

  .drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
    color: #8f8f8f;
  }

  .form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
  }
  .user-management-cms .nav-tabs .nav-link {
    width: 16.666%;
    border: 0;
    background-color: #f2f2f2;
    font-weight: 600;
    padding: 14px 15px;
    border-right: 3px solid #ffff;
    border-radius: 0;
    color: #3e4093;
  }
  .user-management-cms .nav-tabs .nav-link:last-child {
    border: 0;
  }
  .user-management-cms .nav-tabs .nav-link.active {
    background-color: #3e4093;
    color: #fff;
  }
  .user-management-cms .nav-tabs {
    border: 0;
  }

  .cms_home_banner {
    background-color: #fff;
  }
  .cms_home_banner .nav-tabs .nav-link {
    width: 100% !important;
    padding: 12px 30px;
    font-size: 13px !important;
    border-radius: 40px;
    color: #eb3237;
  }
  .cms_home_banner .nav-tabs .nav-link.active {
    background-color: #eb3237;
  }
  .circle_count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3e4093;
    border-radius: 30px;
    color: #fff;
    padding: 2px 4px;
    font-size: 13px;
    margin-left: 5px;
  }
  .user-management-tabs .nav-tabs .nav-link.active .circle_count {
    background-color: #fff;
    color: #3e4093;
  }

  .content_management_box h2 {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 2px;
    font-family: "Poppins", sans-serif;
  }
  .content_management_box p {
    font-size: 15px;
    font-weight: bold;
    line-height: 26px;
  }
  .labels {
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif;
  }
  .account_profile .file-uploads {
    opacity: 0% !important;
    position: relative;
    left: -12px;
    font-size: 20px;
  }
  .account_profile .file-uploadIN {
    opacity: 0% !important;
    position: relative;
    left: -15px;
    font-size: 20px;
    padding: 20px;
  }
  .flavourImage .file-uploadIN {
    opacity: 0% !important;
    position: relative;
    left: -15px;
    font-size: 20px;
    padding: 20px;
  }
  .upload-iconIN {
    position: relative;
    object-fit: cover;
    left: 20px !important;
    font-size: 12px;
    color: #081424;
  }

  .upload-icon {
    position: relative;
    object-fit: cover;
    left: 1px !important;
    font-size: 10px;
    color: #081424;
  }
  .uploadFile {
    position: relative;
    object-fit: cover;
    left: 3px !important;
    font-size: 10px;
    color: #081424;
  }

  .Admin-upload {
    opacity: 0% !important;
    position: absolute;
    left: -10px;
    width: 40px;
  }
  .account_profile .p-image i {
    position: relative;
    object-fit: cover;
    left: 5px;
    top: 3px;
    font-size: 20px;
    color: #081424;
    width: 30px;
  }
  /* statuss check */

  .toggle-switch {
    position: relative;
    width: 100px;
    text-align: left;
    top: 8px;
  }

  .checkboxs {
    display: none;
  }
  .labels {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "";
    padding-left: 8px;
    background-color: #3e4093;
    color: #fff;
  }
  .inner:after {
    content: "";
    padding-right: 13px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 18px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .switchF {
    display: block;
    width: 20px;
    height: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .checkboxs:checked + .labels .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }
  .checkbox:checked + .label .switchF {
    right: 0px;
  }
  .admin_img {
    max-width: 200px;
    border-radius: 50px;
    max-height: 14rem;
  }
  .vaccine_category {
    margin: 0 20px;
  }
  .hide_sidebar {
    width: 0;
    left: -100%;
  }


  .sidebar_logo img {
    max-width: 150px;
    margin: 0 auto;
  }

  
  
  .Profile_dropdown .dropdown-menu {
    transform: unset !important;
    top: 78px !important;
    right: 0 !important;
    left: auto !important;
    min-width: 180px;
    text-align: left;
    border: 0 !important;
    border-radius: 17px;
    box-shadow: 0 7px 6px #0000000d;
    padding: 14px 2px;
  }
  .Profile_dropdown .dropdown-menu::after {
    position: absolute !important;
    content: "" !important;
    top: -11px !important;
    right: 23px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 12px solid white;
  }
  /* .sidebar_menus ul li a::after{
position: absolute;
content: '';
right: 0;
top: 0;
height: 30px;
width: 30px;
background-color: #3e4093;
} */
 
  /*--------------------------------------------------------------
# Sidebar css 
--------------------------------------------------------------*/

  /*--------------------------------------------------------------
# Login css
--------------------------------------------------------------*/

  .comman_btn {
    background-color: #eb3237;
    color: #fff;
    border: 1px dashed #eb3237;
    transition: 0.3s all;
    font-size: 14px !important;
    font-weight: 600;
    padding: 12px 25px !important;
    display: inline-block;
    border-radius: 50px;
    text-decoration: none;
  }

  .comman_btn:hover {
    background-color: #fff;
    border-color: #eb3237;
    color: #eb3237;
  }

  .comman_btn2 {
    background-color: #3e4093;
    color: #fff;
    border: 1px dashed #3e4093;
    transition: 0.3s all;
    font-size: 14px !important;
    font-weight: 600;
    padding: 12px 25px !important;
    display: inline-block;
    border-radius: 50px;
    text-decoration: none;
  }

  .comman_btn2:hover {
    background-color: #fff;
    border-color: #3e4093;
    color: #3e4093;
  }

  .yellow-btn {
    background-color: #eb3237;
    color: #000;
    border: 1px dashed #eb3237;
  }
  .yellow-btn:hover {
    background-color: #fff;
    border-color: #eb3237;
    color: #eb3237;
  }

  .plan_details_inner textarea.form-control {
    height: 140px;
  }

  .count_Sec {
    display: block;
    font-weight: 600;
    color: #f20;
    font-size: 16px;
  }
  .Profile_dropdown button img {
    max-width: 100%;
  }

  .Profile_dropdown button {
    height: 70px;
    width: 70px;
    border-radius: 50px;
    border: 0 !important;
    background-color: transparent !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: unset !important;
  }

  .Profile_dropdown .dropdown-item {
    color: #3e4093;
    font-weight: 500;
    font-size: 13px;
  }
  .formheader img {
    max-width: 150px;
    margin-bottom: 35px;
  }
  .translation-links a img {
    max-width: 22px;
    margin-right: 10px;
  }

  .translation-links a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 128px;
    margin: 0 8px;
    border-radius: 10px;
    padding: 0;
    color: #3e4093;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    border: 2px solid #ffffff;
    height: 50px;
  }

  div#google_translate_element {
    display: none;
  }

  .skiptranslate {
    display: none;
  }

  .translation-links a:focus {
    background-color: #3e4093;
    color: #fff;
    border-color: #3e4093;
  }
  /*--------------------------------------------------------------
# Login css
--------------------------------------------------------------*/

  /*--------------------------------------------------------------
# Admin css
--------------------------------------------------------------*/
  .admin_panel_data {
    padding: 50px;
  }
  .height_adjust {
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dashboard_box {
    background-color: #3e4093;
    border-radius: 10px;
    padding: 15px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 20px #0003;
  }
  .dashboard_icon {
    color: #3e4093;
    font-size: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: 46px;
    margin-left: -36px;
    border-radius: 50px;
    border-left: 2px dashed #3e4093;
  }
  .dashboard_boxcontent h2 {
    margin: 0 0 6px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
  .dashboard_boxcontent span {
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    display: block;
  }
  .comman_header {
    background-color: #eb3237;
    align-items: center;
    padding: 0 15px;
    border-radius: 10px 10px 0 0;
    height: 60px;
  }
  .comman_header h2 {
    color: #fff;
    font-size: 18px;
    margin: 0;
  }
  .design_outter_comman {
    overflow: unset;
    border-radius: 6px !important;
    background-color: #fff;
  }
  .design_outter_comman .comman_header .form-design .form-control {
    height: 38px;
    border-radius: 6px;
    padding: 0 17px;
    box-shadow: unset !important;
    border: 0;
    font-size: 13px;
  }
  .design_outter_comman .comman_header .icons_set i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: #3e4093;
    font-weight: 600;
    cursor: pointer;
  }
  .comman_table_design th {
    color: #3e4093;
    background-color: #f2f2f2;
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px;
    border: 0;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
  }
  .comman_table_design td {
    color: #000;
    font-size: 14px;
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    border-bottom: 2px solid #f2f2f2;
    white-space: nowrap;
  }
  .table > :not(:first-child) {
    border-top: 0;
  }
  .table_viewbtn {
    padding: 5px 11px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 4px;
  }
  .check_toggle label {
    position: relative;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    padding-right: 27px;
  }

  .check_toggle label::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 38px;
    height: 18px;
    background-color: #cacaca;
    border-radius: 10px;
    transition: 0.5s all;
  }
  .check_toggle {
    width: 100%;
    margin-top: -29px;
    margin-left: 10px;
  }
  .view_eye {
    font-size: 17px;
  }
  .check_toggle label::before {
    position: absolute;
    content: "";
    top: 2px;
    right: 21px;
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    border-radius: 50px;
    z-index: 1;
    transition: 0.5s all;
  }

  .check_toggle input:checked ~ label::after {
    background-color: #3e4093;
  }

  .check_toggle input:checked ~ label::before {
    right: 3px;
    background-color: #fff;
  }

  .check_radio label {
    position: relative;
    display: inline-block;
    font-weight: 400;
    color: #fff;
    font-size: 14px;
    padding-right: 27px;
  }
  .check_radio label::after {
    content: "";
    top: 0;
    position: absolute;
    left: 0;
    border: 1px solid #3e4093;
    background-color: #fff;
    height: 20px;
    width: 20px;
    border-radius: 3px;
  }
  .check_radio {
    margin-top: -50px;
  }
  .check_radio label::before {
    content: "\f00c";
    font-family: "Font Awesome 5 free";
    top: 1px;
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    color: #3e4093;
    font-size: 12px;
    font-weight: 700;
    z-index: 1;
    opacity: 0;
    transition: 0.5s all;
  }
  .check_radio input:checked ~ label::before {
    opacity: 1;
  }
  .td_check_radio {
    margin-top: -30px;
  }

  .user_imgg {
    height: 100px;
    width: 100px;
    display: flex;
    margin: 0 auto 20px;
    align-items: center;
    justify-content: center;
    background-color: #eef3ff;
    border-radius: 50px;
  }
  .user-details-part .check_toggle {
    margin: 0;
    position: absolute;
    right: 0;
    left: auto;
    text-align: right;
    top: 33px;
  }
  .order_details_box {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 18px 6px;
  }
  .order_img {
    text-align: center;
  }
  .order_img img {
    max-height: 110px;
  }
  .order_img {
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product_details a {
    font-weight: 600;
    color: #3e4093;
    font-size: 16px;
  }
  .price {
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    margin-top: 4px;
  }

  .flavour {
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0;
  }
  .user-management-tabs .nav-tabs .nav-link {
    width: 33.33%;
    border: 0;
    background-color: #f2f2f2;
    font-weight: 600;
    padding: 14px 15px;
    border-right: 3px solid #ffff;
    border-radius: 0;
    color: #3e4093;
  }
  .user-management-tabs .nav-tabs .nav-link:last-child {
    border: 0;
  }
  .user-management-tabs .nav-tabs .nav-link.active {
    background-color: #3e4093;
    color: #fff;
  }
  .user-management-tabs .nav-tabs {
    border: 0;
  }
  /* .circle_count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #3e4093;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    color: #fff;
    font-size: 13px;
    margin-left: 5px;
  } */
  .user-management-tabs .nav-tabs .nav-link.active .circle_count {
    background-color: #fff;
    color: #3e4093;
  }
  .Status_box {
    color: #fff;
    font-size: 15px;
  }
  .Pending-view_img {
    margin: 0 auto;
    height: 150px;
    width: 150px;
  }
  .Pending-view_img img {
    max-width: 100%;
    border-radius: 10px;
  }
  .user_name {
    font-weight: 700;
    font-size: 19px;
    margin-top: 20px;
  }
  .view-inner-box span {
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: -10px;
    background-color: #fff;
    display: inline-flex;
    padding: 0 10px;
    left: 9px;
    width: auto;
  }
  .view-inner-box strong {
    font-size: 14px;
    color: #3e4093;
  }
  .view-inner-box {
    border-radius: 6px;
    padding: 13px 5px;
    position: relative;
  }
  .img_box_show label {
    width: 100%;
    background-color: #f2f2f2;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-radius: 4px;
    cursor: pointer;
  }
  .img_box_show label i {
    font-size: 26px;
    display: block;
    margin-bottom: 8px;
  }
  .choose_fileInvent label {
    position: absolute;
    background-color: #eb3237;
    height: 50px;
    left: 4px !important;
    color: #fff;
    display: flex !important;
    align-items: center;
    width: 110px;
    justify-content: center;
    text-align: center;
    border-radius: 8px 0 0 8px;
    font-size: 13px !important;
    cursor: pointer;
  }
  .choose_fileAdmin label {
    position: absolute;
    top: 29px;
    background-color: #3e4093;
    height: 50px;
    color: #fff;
    left: 4px !important;
    display: flex !important;
    align-items: center;
    width: 110px;
    justify-content: center;
    text-align: center;
    border-radius: 8px 0 0 8px;
    font-size: 13px !important;
    cursor: pointer;
  }
  .choose_file .inputText {
    position: absolute;
    top: 28px;
    background-color: #3e4093;
    height: 52px;
    color: #fff;
    display: flex !important;
    align-items: center;
    width: 110px !important;
    justify-content: center;
    text-align: center;
    border-radius: 8px 0 0 8px;
    font-size: 12px !important;
    cursor: pointer;
  }
  .choose_fileAdmin span {
    display: block;
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
  .choose_file .form-control {
    line-height: 26px;
  }
  .table_img {
    max-width: 64px;
  }
  .comman_modal .modal-header {
    background-color: #3e4093;
    color: #fff;
  }
  .comman_modal .modal-header .btn-close {
    opacity: 1;
    filter: brightness(50.5);
  }
  .comman_modal .modal-header .btn-close {
    opacity: 1;
    filter: invert(1);
  }
  .category_management .user-management-tabs .nav-tabs .nav-link {
    width: 50%;
  }
  .order_tab .nav-link {
    width: 33.3% !important;
  }

  .flavour_box .comman_btn {
    padding: 13px 18px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .small_header {
    position: absolute;
    top: -10px;
    font-weight: 700;
    font-size: 14px;
    background-color: #fff;
    display: inline-block !important;
    width: auto;
    text-transform: uppercase;
  }
  .data_main {
    font-weight: 500;
    font-size: 14px;
  }
  .data_submain {
    font-weight: 600;
    font-size: 14px;
  }
  .cart_table tr td:first-child {
    width: 800px;
  }
  .cart_table tr td {
    padding: 24px 20px;
    vertical-align: middle;
    text-align: center;
    border: 0;
  }
  .cart_product {
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    border-radius: 8px;
  }
 
  .cart_table tr th {
    border: 0;
    font-weight: 500;
    text-align: center;
    color: #8f8f8f;
    font-size: 16px;
    padding: 14px 20px;
    text-transform: uppercase;
    vertical-align: middle;
  }

  .table_main {
    overflow-y: scroll !important;
    height: 40rem;
    scroll-behavior: smooth;
  }

  /* .table_main::-webkit-scrollbar {
    width: 6px !important;
  } */

  /* Track */
  /* .table_main::-webkit-scrollbar-track {
      background: #f1f1f1;
    } */

  /* Handle */
  /* .table_main ::-webkit-scrollbar-thumb {
    background: #fff;
  } */

  /* Handle on hover */
  /* ::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
  .table > :not(:first-child) {
    border-top: 0;
  }
  .cart_table tr th:first-child,
  .cart_table tr td:first-child {
    text-align: left;
  }
  .cart_content h3 {
    font-weight: 600;
    font-size: 18px;
  }
  .cart_content p {
    color: #8f8f8f;
    font-size: 14px;
    margin: 0 0 18px;
  }
  .cart_content .ordertext {
    font-size: 14px;
    color: #8f8f8f;
    font-size: 14px;
  }
  .cms_management .user-management-tabs .nav-tabs .nav-link {
    width: 20% !important;
    font-size: 15px;
  }
  .cms_home_banner textarea.form-control {
    height: 120px;
  }

  .banner_slide_img img {
    max-width: 100%;
    border-radius: 10px;
    height: 380px;
    width: 100%;
    object-fit: cover;
  }
  .slide_img .account_profile .circle {
    width: 100%;
    border-radius: 10px;
    height: 300px;
  }
  .slide_img .account_profile .p-image {
    height: 50px;
    width: 50px;
    top: -10px;
    right: -11px;
    font-size: 18px;
  }
  .cms_home_banner .nav-tabs .nav-link {
    width: 100% !important;
    padding: 12px 30px;
    font-size: 13px !important;
    border-radius: 40px;
    color: #eb3237;
  }
  .cms_home_banner .nav-tabs .nav-link.active {
    background-color: #eb3237;
  }
  .upload_brand img {
    max-height: 120px;
  }
  .upload_brand {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid #f2f2f2;
    border-radius: 10px;
    height: 180px;
  }
  .camera_btn {
    position: absolute;
    bottom: 10px;
    background-color: #3e4093;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 50px;
    font-size: 13px;
    right: 10px;
  }
  .add_more {
    background-color: #3e4093;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 178px;
    color: #fff !important;
  }
  .add_more i {
    display: block;
    text-align: center;
    font-size: 34px;
    font-weight: 200;
    margin-bottom: 10px;
  }
  .upload_brand img {
    max-height: 80px;
  }
  .content_management_box {
    border: 2px solid #fff;
    border-radius: 10px;

    padding: 25px 18px;
    position: relative;
    background-color: #f2f2f2;
  }
  .edit_content_btn {
    border-radius: 5px;
    padding: 6px 11px;
    font-size: 15px;
    font-weight: 400;
    width: auto;
    position: absolute;
    right: 30px;
    top: -20px;
    color: #fff !important;
    text-decoration: none;
    background-color: #eb3237 !important;
  }
  .content_management_box h2 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 18px;
  }
  .content_management_box p {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: auto;
  }
  .noteee {
    font-size: 12px;
    color: #fff;
  }
  .filter_check label {
    position: relative;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding-left: 30px;
  }
  .filter_check label::after {
    content: "";
    top: 0;
    position: absolute;
    left: 0;
    border: 2px solid #3e4093;
    background-color: #fff;
    height: 22px;
    width: 22px;
    border-radius: 50px;
  }
  .filter_check label::before {
    content: "\f00c";
    font-family: "Font Awesome 5 free";
    top: 3px;
    position: absolute;
    left: 5px;
    height: 18px;
    width: 15px;
    border-radius: 3px;
    color: #3e4093;
    font-size: 12px;
    font-weight: 700;
    z-index: 1;
    opacity: 0;
    transition: 0.5s all;
  }
  .filter_check input:checked ~ label::before {
    opacity: 1;
  }

  .wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    text-decoration: none;
  }
  .editor-class {
    padding: 0.5rem;
    border: 1px solid #ccc;
    text-decoration: none;
  }
  .toolbar-class {
    border: 1px solid #ccc;
    text-decoration: none;
  }
  /* admin Login */

  .LoginHead {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    letter-spacing: 0;
  }
  .login_page {
    background-image: url(../../assets/img/banner_img3.jpg);
    height: 100vh;
    background-size: cover;
    background-position: right;
    position: relative;
  }
  .login_page::after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.6;
  }
  .login_page_form {
    height: 100vh;
    position: relative;
    left: 0 !important;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 45px;
    position: relative;
    z-index: 1;
    text-align: left;
  }
  .formheader h1 {
    font-size: 20px;
    font-weight: 700;
    color: #3e4093;
    margin-bottom: 6px;
  }
  .formheader p {
    font-size: 14px;
    margin: 0;
  }
  .form-design .form-control {
    border-radius: 10px;
    height: 50px;
    font-size: 14px;
    padding: 11px 18px;
    box-shadow: unset;
  }
  .form-design .form-control-sub {
    border-radius: 4px;
    height: 38px !important;
    font-size: 14px;
    width: 100%;
    padding: 11px 18px;
    box-shadow: unset;
  }

  .form-design .form-control:focus {
    border: 1px solid #3e4093;
  }
  .form-design .form-group label {
    display: block;
    margin: 0 0 8px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
  .form-design .form-group {
    margin-bottom: 22px;
  }
  .forgot_password {
    color: #3e4093;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 18px;
    font-weight: bold;
  }
  /*  */
  .textArea {
    height: 15rem !important;
  }
  .rmv_btn {
    margin-top: 30px !important;
  }
  .add_btn {
    position: relative;
    top: 25px;
  }
  /* tags input*/

  .tags-input-container {
    border: 1px solid rgb(152, 152, 152);
    padding: 0.3em;
    border-radius: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5em;
  }

  .tag-item {
    background-color: rgb(218, 216, 216);
    display: inline-block;
    padding: 0.2em 0.45em;
    border-radius: 10px;
  }
  .tag-text {
    font-size: 14px;
  }
  .tag-item .close {
    height: 15px;
    width: 15px;
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.4em;
    font-size: 15px;
    cursor: pointer;
  }

  .tags-input {
    flex-grow: 1;

    padding: 0.5em 0;
    border: none;
    outline: none;
    font-size: 16px;
  }
  .return_Reason .list-group {
    font-size: 15px;
  }

  .pagination {
    display: flex;
    list-style: none;
    outline: none;
    border-radius: 30%;
  }

  .pagination > .active > a {
    background-color: #3b4093;
    color: #fff;
    cursor: pointer !important;
  }

  .pagination > li > a {
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }

  .pagination > li > a,
  .pagination > li > span {
    color: #000;
    background-color: #f2f2f2;
    text-decoration: none;
    cursor: pointer !important;
  }
  .switch {
    position: relative;
    display: inline-block;
    top: 9px;
    left: -10px;
    width: 125px;
    height: 30px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  span.slider.round {
    background-color: gray;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 6px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #3b4093;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(80px);
    -ms-transform: translateX(80px);
    transform: translateX(80px);
  }

  .switchUser {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 30px;
  }

  .switchUser input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  span.sliderUser.round {
    background-color: gray;
  }
  .sliderUser {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: gray;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .sliderUser.round {
    border-radius: 34px;
  }

  .sliderUser.round:before {
    border-radius: 50%;
  }
  .sliderUser:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .sliderUser {
    background-color: #3b4093;
  }

  input:focus + .sliderUser {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .sliderUser:before {
    -webkit-transform: translateX(38px);
    -ms-transform: translateX(38px);
    transform: translateX(38px);
  }

  #pagination {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  #pagination li {
    display: inline;
  }
  #pagination li a {
    display: inline-block;
    text-decoration: none;
    padding: 5px 10px;
    color: #000;
  }

  /* Active and Hoverable Pagination */
  #pagination li a {
    border-radius: 5px;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  #pagination li a.active {
    background-color: #3b4093;
    color: #fff;
  }
  #pagination li a:hover:not(.active) {
    background-color: #ddd;
  }

  /* border-pagination */
  .b-pagination-outer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    display: flex;
  }
  #border-pagination {
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }
  #border-pagination li {
    display: inline;
  }
  #border-pagination li a {
    display: block;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
    border: 1px solid #ddd;
    float: left;
  }
  #border-pagination li a {
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
  }
  #border-pagination li a.active {
    background-color: #4caf50;
    color: #fff;
  }
  #border-pagination li a:hover:not(.active) {
    background: #ddd;
  }
  /* Admin Finish */
}

@media screen and (min-device-width: 200px) and (max-device-width: 999px) {
  /* Reboot CSS */

  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

  .star_imp_app {
    display: block !important;
    margin-top: 0rem !important;
  }
  @media (max-width: 300px) {
    body,
    html {
      font-family: "Poppins", sans-serif !important;
      color: #747794 !important;
      overflow-x: hidden;
      background-color: #fff !important;
      scrollbar-width: thin;
      padding-right: 0 !important;
      letter-spacing: -0.2px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #020310;
      font-weight: 300 !important;
      font-size: 0.5rem !important;
    }

    a,
    a:hover,
    a:focus {
      -webkit-transition-duration: 500ms;
      -o-transition-duration: 500ms;
      transition-duration: 500ms;
      text-decoration: none;
      outline: 0 solid transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: #3e4093;
      font-weight: 200;
      font-size: small !important;
    }

    .header-area {
      -webkit-transition-duration: 500ms;
      -o-transition-duration: 500ms;
      transition-duration: 500ms;
      background-color: #ffffff;
      width: 100%;
      height: 50px !important;
      padding: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      -webkit-box-shadow: 0 0 4px rgb(15 15 15 / 10%);
      box-shadow: 0 0 4px rgb(15 15 15 / 10%);
    }
    .dwn_btn {
      width: 4rem !important;
      height: 2rem;
    }
  }
  /* .dwn_btn {
    width: 6rem;
    height: 2rem;
    margin-left: 4px;
  } */

  body,
  html {
    font-family: "Poppins", sans-serif !important;
    color: #747794 !important;
    overflow-x: hidden;
    background-color: #fff !important;
    scrollbar-width: thin;
    padding-right: 0 !important;
    letter-spacing: -0.5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #020310;
    font-weight: 500;
  }

  p {
    color: #747794;
  }

  a,
  a:hover,
  a:focus {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    text-decoration: none;
    outline: 0 solid transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #3e4093;
    font-weight: 500;
  }

  ul {
    margin: 0;
  }
  ul li {
    list-style: none;
    text-decoration: none;
  }
  ul li:hover,
  ul li:focus {
    list-style: none;
    text-decoration: none;
  }

  ol {
    margin: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  /* Section Padding */
  .section-padding-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-padding-0-30 {
    padding-top: 0;
    padding-bottom: 30px;
  }

  .section-padding-30-0 {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .section-padding-30-14 {
    padding-top: 30px;
    padding-bottom: 14px;
  }

  /* Scrollup */
  #scrollUp {
    bottom: 30px;
    font-size: 16px;
    right: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    border-radius: 6px;
    background-color: #020310;
  }
  #scrollUp:hover,
  #scrollUp:focus {
    background-color: #3e4093;
  }

  input:required,
  textarea:required {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  input:invalid,
  textarea:invalid {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .no-boxshadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  /* Preloader CSS */
  #preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    top: 0;
    left: 0;
    background-color: #fef8ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
  }

  /* Shortcodes CSS */
  .fz-14 {
    font-size: 14px;
  }

  .h-100vh {
    height: 100vh !important;
  }

  .bg-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .bg-fixed {
    background-attachment: fixed;
  }

  .bg-overlay {
    position: relative;
    z-index: 1;
  }
  .bg-overlay::after {
    position: absolute;
    content: "";
    background: #3e4093;
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(#3e4093),
      to(#083afb)
    );
    background: -o-linear-gradient(right, #3e4093, #083afb);
    background: linear-gradient(to left, #3e4093, #083afb);
    opacity: 0.85;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  .bg-gray {
    background-color: #fef8ff;
  }

  .btn,
  .btn:hover,
  .btn:focus {
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0;
  }

  .btn {
    font-size: 14px !important;
  }

  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 1rem;
  }

  .btn-group-sm > .btn,
  .btn-sm {
    font-size: 12px;
  }

  .btn-warning {
    color: #020310;
    background-color: #eb3237;
    border-color: #eb3237;
  }

  .btn-danger {
    background-color: #eb3237;
    border-color: #eb3237;
  }

  .btn-primary {
    background-color: #3e4093;
    border-color: #3e4093;
  }

  .btn-success {
    background-color: #00b894 !important;
    border-color: #00b894 !important;
  }

  .btn-info {
    background-color: #21d6f1;
    border-color: #21d6f1;
    color: #ffffff;
  }

  .bg-primary,
  .badge-primary {
    background-color: #3e4093 !important;
  }

  .bg-danger,
  .badge-danger {
    background-color: #eb3237 !important;
  }

  .bg-warning,
  .badge-warning {
    background-color: #eb3237 !important;
    color: #020310;
  }

  .bg-info,
  .badge-info {
    background-color: #21d6f1 !important;
  }

  .bg-success,
  .badge-success {
    background-color: #00b894 !important;
  }

  .badge {
    font-weight: 500;
  }

  .text-dark {
    color: #020310 !important;
  }

  .section-heading {
    margin-bottom: 1rem;
    line-height: 1;
  }
  .section-heading h6 {
    margin-bottom: 0 !important;
    color: #020310;
    line-height: 25px;
  }
  .section-heading p {
    margin-bottom: 0;
  }
  .section-heading a {
    line-height: 1;
    display: block;
  }

  .card {
    border: 0 !important;
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    border-radius: 6px;
  }
  .card .card-body {
    padding: 1rem;
  }

  .card-img-top {
    border-top-left-radius: calc(0.75rem - 1px);
    border-top-right-radius: calc(0.75rem - 1px);
  }

  .border,
  .border-start,
  .border-right,
  .border-bottom,
  .border-top,
  .list-group-item {
    border-color: #ebebeb !important;
  }

  .mfp-iframe-holder .mfp-content {
    max-width: 1100px;
  }

  .internet-connection-status {
    position: fixed;
    background-color: transparent;
    width: 100%;
    height: 26px;
    z-index: 99999;
    text-align: center;
    color: #ffffff;
    bottom: 58px !important;
    left: 0;
    right: 0;
    line-height: 26px;
    font-weight: 700;
    font-size: 12px;
  }

  .dark-mode-wrapper {
    position: relative;
    z-index: 1;
    background-color: #020310;
    overflow: hidden;
    border-radius: 6px;
  }
  .dark-mode-wrapper::after {
    content: "";
    border-radius: 6px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-image: url("img/bg-img/dark-bg.jpg"); */
    background-position: center center;
    top: 0;
    opacity: 0.3;
    background-size: cover;
    left: 0;
    z-index: -100;
    -webkit-animation: darkModeBGMove 16s linear 0s infinite;
    animation: darkModeBGMove 16s linear 0s infinite;
  }
  .dark-mode-wrapper .form-check-label {
    cursor: pointer;
  }

  @-webkit-keyframes darkModeBGMove {
    50% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
  }

  @keyframes darkModeBGMove {
    50% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
  /* Animation CSS */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes burst {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    90% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes burst {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    90% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @-webkit-keyframes flashing {
    0%,
    90% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
  }
  @keyframes flashing {
    0%,
    90% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
      opacity: 0;
    }
  }
  @keyframes fade-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
      opacity: 0;
    }
  }
  @keyframes fade-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-up {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      opacity: 0;
    }
  }
  @keyframes fade-up {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-down {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
      opacity: 0;
    }
  }
  @keyframes fade-down {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes tada {
    from,
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
      -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
      transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    }
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }
    40%,
    60%,
    80% {
      -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    }
  }
  @keyframes tada {
    from,
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
      -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
      transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    }
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }
    40%,
    60%,
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
  }
  .lni-spin-effect,
  .lni-spin-effect-hover:hover {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  .lni-tada-effect,
  .lni-tada-effect-hover:hover {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite;
  }

  .lni-flashing-effect,
  .lni-flashing-effect-hover:hover {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear;
  }

  .lni-burst-effect,
  .lni-burst-effect-hover:hover {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear;
  }

  .lni-fade-up-effect,
  .lni-fade-up-effect-hover:hover {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear;
  }

  .lni-fade-down-effect,
  .lni-fade-down-effect-hover:hover {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear;
  }

  .lni-fade-left-effect,
  .lni-fade-left-effect-hover:hover {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear;
  }

  .searchModule {
    width: 500px;
    color: #252a32;
    position: relative;
    overflow: hidden;
  }
  .searchModule input {
    padding: 15px;
    border-radius: 3px;
    outline: none;
    border: none;
    width: 100%;
    font-size: 1.3rem;
    border: 1px solid transparent;
    background-color: #ffffff;
  }
  /* .searchModule input:focus {
    border: 1px solid rgb(47, 110, 245);
  } */
  /* .searchModule ul {
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    margin-top: 1px;
    border-radius: 3px;
    max-height: 310px;
    background-color: #ffffff;
  } */
  /* .searchModule ul li {
    padding: 15px;
    background: white;
    border-bottom: 1px solid #c7c0c0;
    margin-top: 0.5px;
  } */
  .searchListMain {
    position: absolute !important;
    background-color: #fff !important;
    width: 100% !important;
    left: 0;
    right: 0;
    list-style: none;
    top: 40px;
    padding: 20px 20px;
    z-index: 9999 !important;
  }
  /* .searchModule li:hover {
    color: rgb(241, 249, 249);
    border-color: rgb(47, 110, 245);
    background-color: rgb(47, 110, 245);
  } */

  .lni-fade-right-effect,
  .lni-fade-right-effect-hover:hover {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear;
  }

  /* Demo CSS */

  .preview-iframe-wrapper .preview-hero-area {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#e5e8ff),
      color-stop(#ebecff),
      color-stop(#f1f0ff),
      color-stop(#f6f5ff),
      color-stop(#faf9ff),
      color-stop(#faf9ff),
      color-stop(#faf9ff),
      color-stop(#faf9ff),
      color-stop(#f6f5ff),
      color-stop(#f1f0ff),
      color-stop(#ebecff),
      to(#e5e8ff)
    );
    background-image: -o-linear-gradient(
      left,
      #e5e8ff,
      #ebecff,
      #f1f0ff,
      #f6f5ff,
      #faf9ff,
      #faf9ff,
      #faf9ff,
      #faf9ff,
      #f6f5ff,
      #f1f0ff,
      #ebecff,
      #e5e8ff
    );
    background-image: linear-gradient(
      to right,
      #e5e8ff,
      #ebecff,
      #f1f0ff,
      #f6f5ff,
      #faf9ff,
      #faf9ff,
      #faf9ff,
      #faf9ff,
      #f6f5ff,
      #f1f0ff,
      #ebecff,
      #e5e8ff
    );
  }

  .preview-iframe-wrapper .preview-hero-area::after {
    position: absolute;
    content: "";
    /* background-image: url(img/core-img/curve.png); */
    bottom: 0;
    width: 142px;
    height: 121px;
    background-repeat: no-repeat;
    right: 0;
    z-index: auto;
  }
  .preview-iframe-wrapper .preview-hero-area iframe {
    position: relative;
    z-index: 1;
    width: 375px;
    height: 748px;
    border: 5px solid #747794;
    border-radius: 1.25rem;
    -webkit-box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
    box-shadow: 0 8px 15px rgba(14, 14, 14, 0.175);
  }

  .preview-iframe-wrapper .preview-hero-area .badge {
    font-size: 16px;
  }
  .preview-iframe-wrapper .preview-hero-area .alert {
    margin-bottom: 80px;
  }

  .preview-iframe-wrapper .preview-hero-area .live-preview-btn {
    margin-bottom: 2rem;
  }
  .preview-iframe-wrapper .preview-content-wrapper .demo-title {
    margin-bottom: 1rem;
    letter-spacing: -1px;
    font-weight: 700;
    line-height: 1.3;
  }
  .preview-iframe-wrapper .preview-content-wrapper .demo-title span {
    color: #3e4093;
  }
  .preview-iframe-wrapper .preview-content-wrapper .demo-desc {
    font-size: 1rem;
  }
  .preview-iframe-wrapper .qr-code-wrapper {
    background-color: #ffffff;
    text-align: center;
    max-width: 100%;
    padding: 1.5rem;
    border-radius: 6px;
  }
  .preview-iframe-wrapper .qr-code-wrapper h6 {
    font-size: 14px;
  }

  .preview-iframe-wrapper .features-area {
    padding: 80px 0;
    border-radius: 3rem 0 0 0;
  }

  .preview-iframe-wrapper .features-area ul li {
    position: relative;
    z-index: 1;
    padding-left: 1.5rem;
    margin-top: 1rem;
  }
  .preview-iframe-wrapper .features-area ul li::before {
    position: absolute;
    content: "\f00c";
    top: 0;
    left: 0;
    font-family: "FontAwesome";
    font-size: 16px;
    color: #747794;
    z-index: 1;
  }
  .preview-iframe-wrapper .features-area ul li.active {
    color: #020310;
    font-weight: 500;
  }
  .preview-iframe-wrapper .features-area ul li.active::before {
    color: #020310;
  }

  .border-top.margin-100,
  .tablet-wrap {
    margin-top: 80px;
  }

  .ipad-frame-wrap {
    text-align: center;
  }
  .ipad-frame-wrap iframe {
    width: 768px;
    height: 1024px;
    border: 10px solid #d7def4;
    border-radius: 1.5rem;
  }

  .preview-footer-area {
    width: 100%;
    height: 80px;
  }

  .preview-footer-area .footer-nav a {
    margin-left: 1rem;
    font-size: 14px;
    color: #747794;
    font-weight: 600;
  }
  .preview-footer-area .footer-nav a:hover,
  .preview-footer-area .footer-nav a:focus {
    color: #3e4093;
  }
  .preview-footer-area .footer-logo img {
    max-height: 50px;
  }

  /* .pwa-install-alert {
    position: fixed;
    z-index: 999;
    border-radius: 6px;
    max-width: 18rem;
    top: 62px;
    right: 12px;
    border: 0;
  }
  .pwa-install-alert .toast-body {
    padding: 1.25rem;
  }
  .pwa-install-alert .toast-body .content img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    width: 30px;
    max-width: 30px;
    margin-right: 0.5rem;
  }
  .pwa-install-alert .toast-body span {
    font-size: 13px;
  }
  .pwa-install-alert .close {
    position: absolute;
    top: 1rem;
    right: 1.25rem;
    z-index: 10;
  }
  .pwa-install-alert .close span {
    font-size: 18px;
    color: #ffffff;
  } */

  .page-nav {
    position: relative;
    z-index: 1;
  }
  .page-nav li a {
    color: #020310;
    display: block;
    padding: 0.625rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0.25rem;
    border-radius: 6px;
    background-color: #ffffff;
    font-size: 15px;
  }
  .page-nav li a i {
    margin-left: auto;
    color: #747794;
    font-size: 16px;
  }
  .page-nav li a:hover,
  .page-nav li a:focus {
    color: #3e4093;
    background-color: #fef8ff;
  }
  .page-nav li:last-child a {
    margin-bottom: 0;
  }
  .page-nav li.nav-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: 1rem 0;
    font-size: 14px;
    padding-left: 4px;
    line-height: 1;
  }

  .widget .widget-title {
    display: block;
  }
  .widget .form-check-label {
    font-size: 14px;
  }

  .select-product-catagory select {
    padding: 0;
    border: 0 !important;
    padding-right: 0.875rem !important;
    font-size: 14px;
    background-color: transparent;
    color: #747794;
    background-position: right 0rem center;
  }
  .select-product-catagory select:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .layout-options a {
    display: inline-block;
    width: 30px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    text-align: center;
    font-size: 12px;
    color: #747794;
  }
  .layout-options a i {
    line-height: 24px;
  }
  .layout-options a.active,
  .layout-options a:hover,
  .layout-options a:focus {
    color: #ffffff;
    background-color: #eb3237;
  }

  .collection-card {
    position: relative;
    z-index: 1;
  }
  .collection-card img {
    border-radius: 6px;
  }
  .collection-card .collection-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    color: #ffffff;
    height: 2rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 0 6px 6px;
    font-size: 14px;
    padding: 0 0.5rem;
  }

  /* Header CSS */
  .header-area {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    background-color: #ffffff;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    -webkit-box-shadow: 0 0 4px rgb(15 15 15 / 10%);
    box-shadow: 0 0 4px rgb(15 15 15 / 10%);
  }
  .cart-icon-wrap a {
    display: block;
    position: relative;
    z-index: 1;
    color: #020310;
    font-size: 1.375rem;
    line-height: 1;
    margin-bottom: 3px;
    margin-right: 6px;
  }
  .cart-icon-wrap a i {
    color: #3e4093;
    font-size: 1.5rem;
  }
  .cart-icon-wrap a img {
    max-width: 1.625rem;
    line-height: 1;
  }
  .cart-icon-wrap a span {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    text-align: center;
    color: #000000;
    font-size: 10px;
    background-color: #ffffff;
    top: 12px;
    right: -6px;
    line-height: 16px;
    border: 1px solid #3e4093;
  }
  .user-profile-icon {
    border-radius: 50%;
    padding: 2px;
  }
  .user-profile-icon a {
    border-radius: 50%;
    padding: 2px;
  }
  .user-profile-icon .userProfile {
    width: 150px;
    height: 130px !important;
    border-radius: 50%;
    margin-left: 20px;
  }
  .headerProfile {
    width: 35px;
    height: 35px !important;
    border-radius: 50%;
  }

  .search-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .search-form form {
    position: relative;
    z-index: 1;
    flex: 0 0 calc(100% - 90px);
    max-width: calc(100% - 90px);
    width: calc(100% - 90px);
  }
  .search-new {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .search-new form {
    position: relative;
    z-index: 1;
    max-width: 100%;
    width: 100%;
  }
  .search-new form .form-control {
    width: 100%;
    height: 39px;
    font-size: 13px;
    padding: 10px 14px;
    padding-left: 48px;
    border: 1px solid #c2d4f8;
    color: #000000 !important;
    border-radius: 0.4rem;
  }
  .search-new form button {
    background-color: #3b4093;
    position: absolute;
    top: 1px;
    left: -1px;
    margin-left: 2px;
    height: 37px;
    border: 0;
    width: 2.5rem;
    border-radius: 0.4rem 0 0 0.4rem;
    z-index: 100;
    color: #fff;
  }
  .search-new form button:focus {
    outline: none;
  }
  .search-form form .form-control {
    width: 100%;
    height: 39px;
    font-size: 13px;
    padding: 10px 14px;
    padding-left: 48px;
    border: 1px solid #c2d4f8;
    color: #000000 !important;
    border-radius: 0.4rem;
  }
  .search-form form button {
    background-color: #3b4093;
    position: absolute;
    top: 1px;
    left: -1px;
    margin-left: 2px;
    height: 37px;
    border: 0;
    border-radius: 0.4rem 0 0 0.4rem;
    z-index: 100;
    color: #fff;
  }
  .search-form form button:focus {
    outline: none;
  }
  .search-form .alternative-search-options {
    width: 40px;
    margin: 0;
    display: flex;
  }
  .search-form .alternative-search-options .dropdown-toggle {
    border-radius: 0.4rem;
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    font-size: 1rem;
    background-color: #eb3237;
  }
  .search-form .alternative-search-options .dropdown-toggle::after {
    display: none;
  }
  .search-form .alternative-search-options .dropdown-menu {
    border: 0;
    min-width: 8rem;
  }
  .search-form .alternative-search-options .dropdown-menu .dropdown-item {
    color: #747794;
    font-size: 14px;
    font-weight: 500;
  }
  .search-form .alternative-search-options .dropdown-menu .dropdown-item i {
    margin-right: 0.25rem;
  }
  .search-form .alternative-search-options .dropdown-menu .dropdown-item:hover,
  .search-form .alternative-search-options .dropdown-menu .dropdown-item:focus {
    font-weight: 500;
    background-color: transparent;
    color: #020310;
  }

  .suha-navbar-toggler {
    position: relative;
    z-index: 1;
    width: 32px;
    height: 32px;
    border: 2px solid #ebebeb;
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .suha-navbar-toggler div {
    position: relative;
    z-index: 1;
  }
  .suha-navbar-toggler span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 16px;
    height: 2px;
    background-color: #747794;
    margin-bottom: 3px;
    display: block;
  }
  .suha-navbar-toggler span:nth-child(2) {
    width: 8px;
  }
  .suha-navbar-toggler span:last-child {
    margin-bottom: 0;
    width: 12px;
  }
  .suha-navbar-toggler:hover span,
  .suha-navbar-toggler:focus span {
    width: 16px;
  }
  .single-product-slide img {
    max-width: 100%;
    margin: 0 auto;
    height: 290px;
    width: auto !important;
    animation: fadeinout 1s ease-in !important;
    transition: 0.8s ease-in !important;
  }
  .filter-option {
    cursor: pointer;
    text-align: right;
    font-size: 21px;
  }

  .back-button a {
    display: block;
    color: #747794;
    font-size: 18px;
  }
  .back-button a:hover,
  .back-button a:focus {
    color: #3e4093;
  }

  .logo-wrapper {
    display: block;
  }
  .logo-wrapper img {
    position: relative !important;
    top: 20% !important;
    left: 20% !important;
    max-width: 4rem !important;
    height: 1.6rem !important;
  }
  /* Footer CSS */
  .footer-nav-area {
    position: fixed;
    z-index: 1000;
    width: 96%;
    left: 0;
    right: 0;
    bottom: 0px;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 8px rgb(15 15 15 / 15%);
    box-shadow: 0 0 8px rgb(15 15 15 / 15%);
    background-color: #3e4093;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .suha-footer-nav {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    height: 55px;
    width: 380px;
  }

  .suha-footer-nav ul {
    width: 87%;
    margin: 0 auto;
  }
  .suha-footer-nav ul li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0;
    position: relative;
    z-index: 1;
  }
  .suha-footer-nav ul li a {
    position: relative;
    display: block;
    font-size: 16px;
    text-align: center;
    text-transform: capitalize;
    line-height: 1;
    color: #ffffff;
    z-index: 1;
  }
  .suha-footer-nav ul li a i {
    display: block;
    font-size: 22px;
    margin-bottom: 6px;
  }
  .suha-footer-nav ul li a:hover,
  .suha-footer-nav ul li a:focus {
    color: #ffffff;
  }
  .suha-footer-nav ul li a img {
    max-height: 1.5rem;
    margin: 0 auto 0.25rem;
    display: block;
  }

  /* Register CSS */
  .login-wrapper {
    position: relative;
    width: 100%;
    min-height: 100vh;
    color: #fff;
    background-color: #3e4093;
    position: relative;
    z-index: 10;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow-x: hidden;
  }
  .login-wrapperWelcome {
    position: relative;
    width: 100%;
    min-height: 100vh;
    color: #fff;
    background: url("../img/bg.jpg") top center no-repeat;
    background-size: cover;
    position: relative;
    z-index: 10;
    overflow-y: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    overflow-x: hidden;
  }

  .login-wrapper .success-check {
    color: #ffffff;
    margin: 0 auto;
  }
  .login-wrapper .success-check i {
    font-size: 3rem;
  }

  .register-form {
    position: relative;
    z-index: 1;
  }
  .register-form .form-group {
    position: relative;
    z-index: 1;
  }
  .register-form span {
    color: #ffffff;
    font-size: 14px;
    display: block;
  }
  .register-form form > .form-group > label {
    position: absolute;
    text-align: center;
    z-index: 100;
    border-radius: 50%;
    left: 0;
    font-size: 16px;
    margin-bottom: 0;
    color: #ffffff;
    top: 28px;
  }
  .register-form .form-control {
    border-radius: 0;
    border: 0;
    color: #ffffff;
    font-size: 13px;
    background-color: transparent !important;
    height: 40px;
    padding: 8px 8px 8px 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }

  .register-form .form-control::-webkit-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .register-form .form-control::-moz-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .register-form .form-control:-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .register-form .form-control::-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .register-form .form-control::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .register-form .progress {
    width: 100%;
    height: 5px;
    margin-top: 1rem;
    border-radius: 6px;
    margin-bottom: 0.25rem;
  }
  .register-form .password-score {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .register-form .password-score span {
    font-size: 18px;
    margin-left: 1rem;
  }
  .register-form .password-recommendation ul {
    padding-left: 0;
    font-size: 14px;
    color: #ffffff;
  }
  .register-form #password-recommendation-heading {
    font-weight: 600;
    color: #ffffff;
    font-size: 16px;
  }

  .view-as-guest .btn {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #ffffff;
    opacity: 0.75;
    text-transform: capitalize;
    font-size: 14px;
  }
  .view-as-guest .btn:hover,
  .view-as-guest .btn:focus {
    opacity: 1;
  }

  .login-meta-data a {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300 !important;
  }
  .login-meta-data a:hover,
  .login-meta-data a:focus {
    color: #eb3237;
  }
  .login-meta-data p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }
  .login-meta-data p a {
    color: #ffffff;
  }
  .login-meta-data p a:hover,
  .login-meta-data p a:focus {
    color: #eb3237;
  }

  .go-back-btn {
    -webkit-transition-duration: 400ms;
    -o-transition-duration: 400ms;
    transition-duration: 400ms;
    position: fixed;
    top: 30px;
    right: 30px;
    background-color: #eb3237;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    line-height: 42px;
    z-index: 10;
    cursor: pointer;
  }
  .go-back-btn:hover,
  .go-back-btn:focus {
    background-color: #3e4093;
    color: #ffffff;
  }

  .otp-form {
    position: relative;
    z-index: 1;
  }
  .otp-form .form-control {
    padding: 0.25rem 0.75rem;
    height: 50px;
    border: 0;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.25);
    font-weight: 500;
    color: #ffffff;
    border-radius: 6px;
  }
  .otp-form .form-control::-webkit-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-form .form-control::-moz-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-form .form-control:-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-form .form-control::-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-form .form-control::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-form .nice-select {
    height: 50px;
    border: 0;
    background-color: rgba(255, 255, 255, 0.25);
    font-weight: 600;
    color: #ffffff;
    border-radius: 6px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    max-width: 80px;
    width: 80px;
    line-height: 50px;
  }
  .otp-form .nice-select .list {
    background-color: #3e4093;
  }
  .otp-form .nice-select .list .option:hover,
  .otp-form .nice-select .list .option.focus,
  .otp-form .nice-select .list .option.selected.focus {
    background-color: #eb3237;
  }
  .otp-form .nice-select:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .otp-form .nice-select::after {
    border-bottom-color: #ffffff;
    border-right-color: #ffffff;
  }
  /* .otp-form input.form-control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  } */

  .otp-verify-form {
    position: relative;
    z-index: 1;
  }
  .otp-verify-form .form-control {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    max-width: 50px;
    width: 50px;
    padding: 6px;
    border: 0;
    text-align: center;
    font-size: 1.25rem;
    background-color: rgba(255, 255, 255, 0.25);
    color: #ffffff;
    font-weight: 500;
  }
  .otp-verify-form .form-control::-webkit-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-verify-form .form-control::-moz-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-verify-form .form-control:-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-verify-form .form-control::-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-verify-form .form-control::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }
  .otp-verify-form .form-control:focus {
    background-color: #eb3237;
    color: #020310;
  }

  .suha-offcanvas-wrap {
    width: 240px !important;
    border-right: 0 !important;
    background: #3e4093 !important;
  }
  .suha-offcanvas-wrap .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  }
  .suha-offcanvas-wrap .offcanvas-body {
    scrollbar-width: thin;
  }

  .sidenav-profile {
    position: relative;
    z-index: 1;
  }
  .sidenav-profile .user-profile {
    position: relative;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 1rem auto 1.5rem;
  }
  .sidenav-profile .user-profile::before {
    position: absolute;
    width: 110px;
    height: 110px;
    top: -15px;
    left: -15px;
    /* background-image: url(img/core-img/dot.png); */
    content: "";
    z-index: -10;
    opacity: 0.2;
    border-radius: 50%;
  }
  .sidenav-profile .user-profile img {
    border-radius: 50%;
  }
  .sidenav-profile .user-profile .user-designation {
    position: absolute;
    z-index: 1;
    height: 46px;
    width: 46px;
    font-size: 12px;
    border: 3px solid #ffffff;
    background-color: #3e4093;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    top: 0;
    right: 0;
  }
  .sidenav-profile .user-info {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .sidenav-profile .user-info .available-balance {
    margin-bottom: 0;
    display: block;
    font-size: 14px;
    color: #ffffff;
  }

  .sidenav-nav {
    margin: 2rem 0;
    position: relative;
    z-index: 1;
  }
  .sidenav-nav li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    border-radius: 0.375rem;
    margin-bottom: 0.25rem;
    padding: 8px 14px;
    font-weight: 400;
  }
  .sidenav-nav li a i {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    display: inline-block;
    font-size: 1rem;
    color: #ffffff;
    width: 24px;
  }
  .sidenav-nav li a:hover,
  .sidenav-nav li a:focus {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .sidenav-nav li:last-child a {
    margin-bottom: 0;
  }

  li.suha-dropdown-menu {
    position: relative;
    z-index: 1;
  }
  li.suha-dropdown-menu ul {
    display: none;
    padding-left: 1rem;
  }
  li.suha-dropdown-menu ul li a {
    font-size: 13px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  li.suha-dropdown-menu .dropdown-trigger-btn {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    height: 41px;
    line-height: 41px;
    color: #ffffff;
    font-size: 12px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    text-align: right;
    padding-right: 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  li.suha-dropdown-menu .dropdown-trigger-btn i {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }
  li.suha-dropdown-menu .dropdown-trigger-btn:hover,
  li.suha-dropdown-menu .dropdown-trigger-btn:focus {
    background-color: rgba(255, 255, 255, 0.15);
  }
  li.suha-dropdown-menu .dropdown-trigger-btn.active {
    background-color: rgba(255, 255, 255, 0.15);
  }
  li.suha-dropdown-menu .dropdown-trigger-btn.active i {
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .suha-filter-offcanvas-wrap {
    width: 240px !important;
    border-right: 0;
    background-color: #ffffff;
  }
  .suha-filter-offcanvas-wrap .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
  }
  .suha-filter-offcanvas-wrap .offcanvas-body {
    scrollbar-width: thin;
  }

  /* Welcome Page CSS */
  .comman_btn {
    display: flex;
    background-color: #eb3237;
    border-radius: 4px;
    padding: 11px 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: capitalize;
    font-size: 15px;
    border: 0;
    width: 100%;
    outline: 0;
    font-weight: 500;
  }
  .comman_btn2 {
    display: flex;
    background-color: #3e4093;
    border-radius: 4px;
    padding: 11px 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    text-transform: capitalize;
    font-size: 15px;
    border: 0;
    width: 100%;
    outline: 0;
    font-weight: 500;
  }
  .intro-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #eef3ff;
    z-index: 1;
  }
  .big-logo {
    max-width: 160px;
  }
  .welcomeForm2 {
    background-color: #fff;
    width: 50% !important;
    margin-right: 1rem;
  }
  .welcomeForm {
    background-color: #fff;
    width: 50% !important;
  }
  .get-started-btn {
    position: fixed;
    z-index: 100;
    bottom: 40px;
    width: 280px;
    left: 50%;
    margin-left: -140px;
  }

  /* Hero Slide CSS */
  .hero-slides {
    position: relative;
    z-index: 1;
  }
  .hero-slides .owl-prev,
  .hero-slides .owl-next {
    position: absolute;
    top: 50%;
    background-color: rgba(255, 255, 255, 0.11);
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    left: 0;
    margin-top: -15px;
    color: #ffffff;
    display: none;
  }
  .hero-slides .owl-next {
    left: auto;
    right: 0;
  }
  .hero-slides .owl-dots {
    position: absolute;
    width: 100%;
    bottom: -14px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .hero-slides .owl-dots .owl-dot {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: relative;
    z-index: 1;
    margin: 0 5px;
    width: 8px;
    height: 8px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8px;
    flex: 0 0 8px;
    max-width: 8px;
    background-color: #a9a9a9 !important;
    border-radius: 50%;
  }
  .hero-slides .owl-dots .owl-dot.active {
    background-color: #eb3237 !important;
  }
  .hero-slides .owl-dots .owl-dot.active::after {
    position: absolute;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    z-index: 1;
    content: "";
    border: 2px solid #eb3237;
    left: -4px;
    top: -4px;
    border-radius: 50%;
  }

  .single-hero-slide {
    position: relative;
    z-index: 1;
    width: 110% !important;
    height: 100% !important;
    max-height: 10rem !important;
    background-position: center center;
    background-size: cover;
    border-radius: 10px;
  }

  .single-hero-slide .slide-content {
    position: absolute;
    width: 100% !important;

    left: 0;
    z-index: 10;
  }

  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    min-height: 10rem !important;
  }

  .single-hero-slide .slide-content .slide-text {
    padding-left: 20px;
    padding-right: 3rem;
  }

  /* Chat CSS */
  .live-chat-intro {
    position: relative;
    z-index: 1;
    background-color: #3e4093;
    padding: 2rem 0;
    text-align: center;
    border-radius: 0 0 3rem 3rem;
  }
  .live-chat-intro p {
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  .live-chat-intro img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto 0.5rem;
    display: block;
  }
  .live-chat-intro .status {
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-size: 14px;
    display: inline-block;
  }
  .live-chat-intro .status.online {
    padding-left: 15px;
  }
  .live-chat-intro .status.online::after {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #00b894;
    border-radius: 50%;
    content: "";
    margin-top: -5px;
    top: 50%;
    left: 0;
    z-index: 10;
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear;
  }

  .agent-message-content {
    position: relative;
    z-index: 1;
    margin-bottom: 0.5rem;
  }
  .agent-message-content .agent-thumbnail {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    max-width: 30px;
    width: 30px;
  }
  .agent-message-content .agent-thumbnail img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 3px 0 rgba(15, 15, 15, 0.175);
    box-shadow: 0 1px 3px 0 rgba(15, 15, 15, 0.175);
    background-color: #ffffff;
    padding: 2px;
  }
  .agent-message-content .agent-message-text {
    position: relative;
    z-index: 1;
  }
  .agent-message-content .agent-message-text p {
    display: inline-block;
    background-color: #ffffff;
    padding: 0.625rem 0.75rem;
    border-radius: 0.5rem;
    margin-bottom: 0.375rem;
  }
  .agent-message-content .agent-message-text .writing-mode {
    display: inline-block;
    background-color: #ffffff;
    padding: 0.625rem 0.75rem;
    border-radius: 0.5rem;
    margin-bottom: 0.375rem;
  }
  .agent-message-content .agent-message-text .writing-mode .dot {
    position: relative;
    display: inline-block;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background-color: #ebebeb;
    -webkit-animation: writingdot 1000ms linear 0s infinite;
    animation: writingdot 1000ms linear 0s infinite;
  }
  .agent-message-content .agent-message-text .writing-mode .dot:first-child {
    margin-left: 0;
  }
  .agent-message-content .agent-message-text .writing-mode .dot:nth-child(2) {
    -webkit-animation-delay: 250ms;
    animation-delay: 250ms;
  }
  .agent-message-content .agent-message-text .writing-mode .dot:nth-child(3) {
    -webkit-animation-delay: 500ms;
    animation-delay: 500ms;
  }
  .agent-message-content .agent-message-text span {
    display: block;
    font-size: 12px;
    color: #747794;
    margin-left: 0.5rem;
    font-weight: 700;
    line-height: 1;
  }

  @-webkit-keyframes writingdot {
    0% {
      top: 0;
    }
    50% {
      top: -6px;
    }
    100% {
      top: 0;
    }
  }

  @keyframes writingdot {
    0% {
      top: 0;
    }
    50% {
      top: -6px;
    }
    100% {
      top: 0;
    }
  }
  .user-message-content {
    position: relative;
    z-index: 1;
    text-align: right;
    margin-bottom: 0.5rem;
  }
  .user-message-content .user-message-text {
    padding-left: 3rem;
  }
  .user-message-content .user-message-text p {
    display: inline-block;
    margin-bottom: 0.375rem;
    background-color: #3e4093;
    color: #ffffff;
    padding: 0.625rem 0.75rem;
    border-radius: 0.5rem;
  }
  .user-message-content .user-message-text span {
    font-size: 12px;
    display: block;
    color: #747794;
    margin-right: 0.5rem;
    font-weight: 700;
    line-height: 1;
  }

  .type-text-form {
    position: fixed;
    width: 100%;
    height: 55px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 99999;
  }
  .type-text-form .file-upload {
    position: absolute;
    width: 26px;
    height: 26px;
    top: 50%;
    left: 0.5rem;
    z-index: 20;
    border: 2px solid #ebebeb;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
  }
  .type-text-form .file-upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    opacity: 0;
    cursor: pointer;
    border-radius: 50%;
  }
  .type-text-form .file-upload i {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    z-index: 50;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    line-height: 21px;
  }
  .type-text-form .form-control {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 100%;
    height: 55px;
    border-color: transparent;
    color: #747794;
    font-size: 13px;
    padding-top: 17px;
    padding-bottom: 17px;
    padding-left: 2.5rem;
  }
  .type-text-form .form-control:focus {
    border-color: transparent;
  }
  .type-text-form button[type="submit"] {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    right: 0.5rem;
    z-index: 30;
    border: 0;
    background-color: #ffffff;
    color: #020310;
    outline: none !important;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  /* Setting Card CSS */
  .settings-card {
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
  }
  .settings-card .card-body {
    padding: 0.75rem 1rem;
  }
  .settings-card:last-child {
    margin-bottom: 0;
  }

  .single-settings .title {
    font-size: 14px;
    position: relative;
  }
  .single-settings .title i {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    background-color: #eb3237;
    text-align: center;
    color: #ffffff;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    font-size: 14px;
  }
  .single-settings .title span {
    font-weight: 500;
  }
  .single-settings .title span span {
    font-size: 10px;
    margin-left: 0.5rem;
  }
  .single-settings .data-content {
    font-size: 14px;
    font-weight: 700;
  }
  .single-settings .data-content a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #747794;
  }
  .single-settings .data-content i {
    margin-left: 0.25rem;
  }
  .single-settings .toggle-button-cover {
    position: relative;
    z-index: 1;
  }
  .single-settings .knobs,
  .single-settings .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .single-settings .button.r {
    position: relative;
    width: 45px;
    height: 21px;
    overflow: hidden;
    border: 1px solid #ebebeb;
  }
  .single-settings .button.r,
  .single-settings .button.r .layer {
    border-radius: 100px;
  }
  .single-settings .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  .single-settings .knobs {
    z-index: 2;
  }
  .single-settings .layer {
    width: 100%;
    background-color: transparent;
    -webkit-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    transition: 0.3s ease all;
    z-index: 1;
  }
  .single-settings .knobs:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 15px;
    height: 15px;
    background-color: #eb3237;
    border-radius: 50%;
    -webkit-transition: 0.3s ease all,
      left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    -o-transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }
  .single-settings .checkbox:active + .knobs:before {
    width: 45px;
    border-radius: 100px;
  }
  .single-settings .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
  }
  .single-settings .checkbox:checked + .knobs:before {
    content: "";
    left: 24px;
    background-color: #3e4093;
  }
  .single-settings .checkbox:checked ~ .layer {
    background-color: #fef8ff;
  }

  /* Catagory CSS */
  .catagory-card {
    position: relative;
    z-index: 1;
    text-align: center;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }
  .catagory-card a {
    display: block;
  }
  .catagory-card i {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
    display: block;
    color: #020310;
  }
  .catagory-card img {
    margin: 0 auto 0.2rem;
    display: block;
    max-height: 36px;
  }
  .catagory-card span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    display: block;
    font-size: 12px;
    line-height: 1;
    color: #747794;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* overflow: hidden; */
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  .catagory-card.active,
  .catagory-card:hover,
  .catagory-card:focus {
    background-color: #c2d4f8;
  }
  .catagory-card.active span,
  .catagory-card:hover span,
  .catagory-card:focus span {
    color: #020310;
  }
  .catagory-single-img {
    width: 100%;
    height: 190px;
    background-position: center center;
    background-size: cover;
    border-radius: 6px;
  }

  /* Flash Sale CSS */
  .flash-sale-card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .flash-sale-card .card-body {
    padding: 0.5rem;
  }
  .flash-sale-card img {
    height: 80px;
    margin: 0 auto 10px;
    width: auto !important;
  }
  .flash-sale-card .product-title {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #020310;
    font-size: 13px;
    display: block;
    line-height: 1.2;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0.25rem;
  }
  .flash-sale-card .sale-price {
    font-size: 1rem;
    color: #020310;
    margin-bottom: 0.25rem;
    line-height: 1;
  }
  .flash-sale-card .real-price {
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    text-decoration: line-through;
    color: #747794;
  }
  .flash-sale-card .progress {
    height: 0.25rem;
  }
  .flash-sale-card .progress-title {
    color: #747794;
    font-size: 12px;
    display: block;
    font-weight: 400;
  }

  /* Product Card CSS */
  .product-card {
    position: relative;
    z-index: 1;
  }
  .product-card .badge {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 10;
  }
  .product-card .wishlist-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    color: #eb3237;
    font-size: 1rem;
    line-height: 1;
  }
  .product-card .wishlist-btn:hover,
  .product-card .wishlist-btn:focus {
    -webkit-transform: scale(1.25);
    -ms-transform: scale(1.25);
    transform: scale(1.25);
  }
  .product-card .product-thumbnail {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .product-card .product-thumbnail img {
    width: auto !important;
    height: 130px;
    margin: auto;
  }
  .product-card .product-thumbnail .offer-countdown-timer {
    background-color: #eb3237;
    position: absolute;
    bottom: 0.75rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    border-radius: 3px;
    padding: 4px 8px 3px !important;
  }
  .product-card .product-thumbnail .offer-countdown-timer li {
    font-size: 12px;
    line-height: 1;
    margin-right: 0.25rem;
    color: #fff;
    font-weight: 400;
  }
  .product-card .product-thumbnail .offer-countdown-timer li:last-child {
    margin-right: 0;
  }
  .product-card .product-thumbnail .offer-countdown-timer li span {
    font-weight: 500;
    color: #fff;
  }
  .product-card .delete-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    color: #747794;
    font-size: 14px;
    line-height: 1;
  }
  .product-card .product-title {
    font-size: 13px;
    margin-bottom: 0.5rem;
    color: #020310;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  .product-card .product-title:hover,
  .product-card .product-title:focus {
    color: #3e4093;
  }
  .product-card .sale-price {
    font-weight: 500;
    margin-bottom: 0.125rem;
    color: #020310;
    font-size: 1.25rem;
    line-height: 1;
  }
  .product-card .sale-price span {
    color: #747794;
    margin-left: 4px;
    text-decoration: line-through;
    font-size: 14px;
  }
  .product-card .product-rating {
    color: #ffd000;
    line-height: 1;
  }
  .product-card .product-rating i {
    font-size: 12px;
    margin: 0 1px;
  }
  .product-card .btn {
    position: absolute;
    padding: 0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    right: 1rem;
    bottom: 1rem;
    z-index: 99;
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  /* Weekly Product Card CSS */
  .horizontal-product-card .product-thumbnail-side {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    width: 120px;
    max-width: 120px;
  }
  .horizontal-product-card .product-thumbnail-side .product-thumbnail {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    text-align: center;
    border-radius: 0.5rem;
    margin-right: 1rem;
    padding: 5px;
    height: 110px;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .horizontal-product-card .product-thumbnail-side .product-thumbnail img {
    max-height: 80px;
  }
  .horizontal-product-card
    .product-thumbnail-side
    .product-thumbnail
    .offer-countdown-timer {
    background-color: #eb3237;
    position: absolute;
    bottom: 1.75rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
    border-radius: 5rem;
    padding: 4px 8px 3px !important;
  }
  .horizontal-product-card
    .product-thumbnail-side
    .product-thumbnail
    .offer-countdown-timer
    li {
    font-size: 11px;
    line-height: 1;
    margin-right: 0.25rem;
    color: #020310;
    font-weight: 500;
  }
  .horizontal-product-card
    .product-thumbnail-side
    .product-thumbnail
    .offer-countdown-timer
    li:last-child {
    margin-right: 0;
  }
  .horizontal-product-card
    .product-thumbnail-side
    .product-thumbnail
    .offer-countdown-timer
    li
    span {
    font-weight: 700;
  }
  .horizontal-product-card .product-description {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 120px);
    flex: 0 0 calc(100% - 120px);
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);
    padding-right: 1.5rem;
  }
  .horizontal-product-card .product-description .product-title {
    margin-bottom: 0.5rem;
    font-size: 0.8rem !important;
    color: #020310;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  .horizontal-product-card .product-description .wishlist-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: #eb3237;
    z-index: 11;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .horizontal-product-card .product-description .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: #747794;
    z-index: 11;
    font-size: 12px;
    background-color: #ffffff;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .horizontal-product-card .product-description .sale-price {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    color: #020310;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
  }
  .horizontal-product-card .product-description .sale-price i {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    max-width: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #332858;
    font-size: 0.625rem;
    text-align: center;
    margin-right: 0.25rem;
  }
  .horizontal-product-card .product-description .sale-price span {
    text-decoration: line-through;
    color: #eb3237;
    margin-left: 0.25rem;
  }
  .horizontal-product-card .product-description .product-rating {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
  }
  .horizontal-product-card .product-description .product-rating i {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    max-width: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #eb3237;
    font-size: 0.625rem;
    text-align: center;
    margin-right: 0.25rem;
  }

  /* Featured Product Card CSS */
  .featured-product-card .custom-badge {
    position: absolute;
    left: 1rem;
    top: -1px;
    z-index: 100;
    height: 22px;
    border-radius: 0;
    padding: 0 0 0 0.5rem;
    line-height: 22px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .featured-product-card .custom-badge::before {
    border-color: #eb3237 transparent #eb3237 #eb3237;
    border-style: solid;
    border-width: 11px 6px;
    content: "";
    height: 22px;
    position: absolute;
    right: -13px;
    top: 0;
    width: 17px;
    z-index: -2;
  }
  .featured-product-card .product-thumbnail-side {
    position: relative;
    z-index: 1;
    margin-bottom: 0.5rem;
  }
  .featured-product-card .product-thumbnail-side .product-thumbnail {
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .featured-product-card .product-thumbnail-side .product-thumbnail img {
    max-height: 150px;
  }
  .featured-product-card .product-description .product-title {
    margin-bottom: 0.25rem;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #747794;
  }
  .featured-product-card .product-description .product-title:hover,
  .featured-product-card .product-description .product-title:focus {
    color: #3e4093;
  }
  .featured-product-card .product-description .sale-price {
    margin-bottom: 0;
    font-size: 18px;
    color: #020310;
    line-height: 1;
  }

  /* Product Details CSS */
  .product-slide-wrapper {
    position: relative;
    z-index: 1;
  }
  .product-slide-wrapper .video-btn {
    position: absolute;
    z-index: 100;
    top: 1rem;
    left: 1rem;
    width: 2rem;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    color: #eb3237;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .mfp-image-holder .mfp-close,
  .mfp-iframe-holder .mfp-close {
    right: 0;
    text-align: center;
    padding-right: 0;
    width: 100%;
    top: -30px !important;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #eb3237;
    line-height: 24px;
    border-radius: 0.25rem;
    font-size: 24px;
  }

  .mfp-image-holder .mfp-close {
    top: 0 !important;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.125);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.125);
  }

  .mfp-bg {
    background: #020310;
    opacity: 0.5;
  }

  .mfp-figure::after {
    background: #ffffff;
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }

  .mfp-iframe-scaler iframe {
    -webkit-box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    background: #020310;
  }

  .product-slides {
    position: relative;
    z-index: 1;
  }
  .product-slides .owl-prev,
  .product-slides .owl-next {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #ffffff !important;
    z-index: 10;
    top: 50%;
    margin-top: -15px;
    left: 15px;
    font-size: 20px;
    color: #3e4093;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .product-slides .owl-prev.disabled,
  .product-slides .owl-next.disabled {
    opacity: 0;
    visibility: hidden;
  }
  .product-slides .owl-next {
    left: auto;
    right: 15px;
  }

  .product-title-meta-data {
    border-top-left-radius: 2rem;
    padding-top: 1.5rem !important;
  }
  .product-title-meta-data::after {
    position: absolute;
    content: "";
    /* background-image: url(img/core-img/curve.png); */
    top: -120px;
    width: 142px;
    height: 121px;
    background-repeat: no-repeat;
    right: 0;
    z-index: auto;
  }
  .product-title-meta-data .p-wishlist-share {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    width: 80px;
    max-width: 80px;
    text-align: right;
  }

  .product-description {
    position: relative;
    z-index: 1;
  }
  .product-description .sale-price {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #eb3237;
  }
  .product-description .sale-price span {
    margin-left: 0.25rem;
    color: #747794;
    text-decoration: line-through;
    font-size: 14px;
  }
  .product-description .ratings i {
    color: #eb3237;
    font-size: 12px;
  }
  .product-description .ratings span {
    font-size: 12px;
    color: #747794;
  }
  .product-description .total-result-of-ratings span:first-child {
    line-height: 1;
    background-color: #eb3237;
    padding: 3px 6px;
    color: #ffffff;
    display: inline-block;
    border-radius: 0.25rem;
    font-size: 12px;
    margin-right: 0.25rem;
    font-weight: 500;
  }
  .product-description .total-result-of-ratings span:last-child {
    color: #00b894;
    font-size: 12px;
    font-weight: 600;
  }

  .cart-form {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .cart-form .form-control {
    max-width: 50px;
    height: 35px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    text-align: center;
    font-weight: 500;
    padding: 0.375rem 0.5rem;
  }

  .cart-form .quantity-button-handler {
    width: 35px;
    height: 35px;
    background-color: #fef8ff;
    border: 1px solid #ebebeb;
    color: #020310;
    line-height: 33px;
    font-size: 1.25rem;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }
  .cart-form .quantity-button-handler:hover {
    color: #3e4093;
  }

  .p-wishlist-share a {
    display: inline-block;
    color: #eb3237;
    font-size: 1.5rem;
  }

  .choose-color-radio .form-check-input {
    border: 0;
    background-color: #fef8ff;
  }
  .choose-color-radio .form-check-input.blue {
    background-color: #3e4093;
  }
  .choose-color-radio .form-check-input.yellow {
    background-color: #eb3237;
  }
  .choose-color-radio .form-check-input.green {
    background-color: #00b894;
  }
  .choose-color-radio .form-check-input.purple {
    background-color: #eb3237;
  }
  .choose-color-radio .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  .choose-size-radio {
    position: relative;
    z-index: 1;
    text-align: right;
  }
  .choose-size-radio .form-check-input {
    background-color: #fef8ff;
    border-radius: 0.25rem;
  }
  .choose-size-radio .form-check-input:checked {
    background-color: #eb3237;
    border-color: #eb3237;
    background-image: none;
  }
  .choose-size-radio .form-check-label {
    font-size: 12px;
    font-weight: 700;
  }

  .single-user-review {
    border-bottom: 2px dashed #ebebeb;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .single-user-review:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .single-user-review .user-thumbnail {
    margin-top: 0.5rem;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40px;
    flex: 0 0 40px;
    width: 40px;
    max-width: 40px;
    margin-right: 0.5rem;
  }
  .single-user-review .user-thumbnail img {
    border-radius: 50%;
  }
  .single-user-review .rating-comment .rating {
    font-size: 12px;
    color: #ffd000;
  }
  .single-user-review .rating-comment .rating i {
    margin-right: 1px;
  }
  .single-user-review .rating-comment .name-date {
    display: block;
    font-size: 12px;
  }
  .single-user-review .rating-comment .review-image {
    display: inline-block;
    margin-right: 0.5rem;
  }
  .single-user-review .rating-comment .review-image img {
    max-width: 50px;
  }

  .ratings-submit-form {
    position: relative;
    z-index: 1;
  }
  .ratings-submit-form textarea.form-control {
    height: 80px;
    font-size: 14px;
  }
  .ratings-submit-form .stars {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==")
      repeat-x 0 0;
    width: 150px;
  }
  .ratings-submit-form .stars:before,
  .ratings-submit-form .stars:after {
    display: table;
    content: "";
  }
  .ratings-submit-form .stars:after {
    clear: both;
  }
  .ratings-submit-form .stars input[type="radio"] {
    position: absolute;
    opacity: 0;
  }
  .ratings-submit-form .stars input[type="radio"].star-5:checked ~ span {
    width: 100%;
  }
  .ratings-submit-form .stars input[type="radio"].star-4:checked ~ span {
    width: 80%;
  }
  .ratings-submit-form .stars input[type="radio"].star-3:checked ~ span {
    width: 60%;
  }
  .ratings-submit-form .stars input[type="radio"].star-2:checked ~ span {
    width: 40%;
  }
  .ratings-submit-form .stars input[type="radio"].star-1:checked ~ span {
    width: 20%;
  }
  .ratings-submit-form .stars label {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 !important;
    padding: 0 !important;
    text-indent: -99999rem;
    float: left;
    position: relative;
    z-index: 10;
    background: transparent !important;
    cursor: pointer;
  }
  .ratings-submit-form .stars label:hover ~ span {
    background-position: 0 -30px;
  }
  .ratings-submit-form .stars label.star-5:hover ~ span {
    width: 100% !important;
  }
  .ratings-submit-form .stars label.star-4:hover ~ span {
    width: 80% !important;
  }
  .ratings-submit-form .stars label.star-3:hover ~ span {
    width: 60% !important;
  }
  .ratings-submit-form .stars label.star-2:hover ~ span {
    width: 40% !important;
  }
  .ratings-submit-form .stars label.star-2:hover ~ span {
    width: 20% !important;
  }
  .ratings-submit-form .stars span {
    display: block;
    width: 0;
    position: relative;
    top: 0;
    left: 0;
    height: 30px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==")
      repeat-x 0 -60px;
    -webkit-transition: width 0.5s;
    -o-transition: width 0.5s;
    transition: width 0.5s;
  }

  .offline-area-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 108px);
  }
  .offline-area-wrapper i {
    font-size: 3rem;
    display: block;
    margin-bottom: 1rem;
  }

  .sales-offer-content .sales-end {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    width: 60%;
  }
  .sales-offer-content .sales-volume {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
    width: 40%;
  }
  .sales-offer-content .sales-volume .progress {
    border-radius: 100px;
  }
  .sales-end-timer li {
    line-height: 1;
    margin-left: 0.5rem;
    color: #747794;
    font-weight: 500;
    font-size: 14px;
  }
  .sales-end-timer li:first-child {
    margin-left: 0;
  }

  .product-catagories a {
    padding: 0.425rem 0.5rem;
    background-color: #ffffff;
    border-radius: 6px;
    color: #020310;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .product-catagories a img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.25rem;
    flex: 0 0 1.25rem;
    width: 1.25rem;
    max-width: 1.25rem;
    margin-right: 0.25rem;
  }
  .product-catagories a:hover,
  .product-catagories a:focus {
    color: #eb3237;
  }

  .single-product-slide,
  .blog-details-post-thumbnail {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 380px;
    background-position: center center;
    background-size: cover;
  }

  .choose-color-wrapper .form-check-input {
    width: 1.125rem;
    height: 1.125rem;
  }

  .single-related-product-slide {
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }

  .table_user {
    word-wrap: break-word !important;
    width: 2rem !important;
    background-color: #000 !important;
  }
  /* Cart CSS */
  .cart-table {
    position: relative;
    z-index: 1;
  }
  .cart-table table {
    max-width: 100%;
  }
  .cart-table table tbody tr {
    background-color: #ffffff;
    border-bottom: 2px dashed #ebebeb;
  }
  .cart-table table tbody tr:last-child {
    border-bottom: 0;
  }
  .cart-table table a {
    color: #020310;
  }
  .cart-table table a.remove-product {
    color: #ffffff;
    width: 24px;
    height: 24px;
    background-color: #eb3237;
    display: inline-block;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;
    font-size: 12px;
  }
  .cart-table table a.remove-product:hover,
  .cart-table table a.remove-product:focus {
    color: #ffffff;
    background-color: #020310;
  }
  .cart-table table a span {
    display: block;
    color: #747794;
  }
  .cart-table table a:hover,
  .cart-table table a:focus {
    color: #3e4093;
  }
  .cart-table .table td,
  .cart-table .table th {
    vertical-align: middle;
    color: #747794;
    font-size: 14px;
    padding: 1rem 0.5rem;
    border: 0;
  }
  .cart-table img {
    max-width: 100%;
    border: 0;
    height: 40px;
  }
  .notifyBtn {
    border: 4px seashell;
  }
  .cart-table .qty-text {
    width: 3.5rem;
    text-align: center;
    color: #000;
    height: 1.8rem;
    border-radius: 0.25rem;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
    border: 0 !important;
    background-color: #c2d4f8;
  }
  .cart-table .qty-text2 {
    width: 4rem;
    text-align: center;
    color: #000;
    height: 1.8rem;
    border-radius: 0.25rem;
    -webkit-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms;
    border: 0 !important;
    background-color: #c2d4f8;
  }
  .coupon-card .card-body {
    padding: 1.25rem;
  }

  .cart-amount-area .card-body {
    padding: 0.75rem 1.25rem;
  }

  .apply-coupon {
    position: relative;
    z-index: 1;
  }
  .apply-coupon p {
    font-size: 12px;
  }

  .coupon-form {
    position: relative;
    z-index: 1;
  }
  .coupon-form .form-control {
    height: 35px;
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 0.4rem;
  }
  .coupon-form button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 35px;
  }

  /* Order CSS */
  .my-order-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100vh !important;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
  }
  .my-order-wrapper .card {
    border-radius: 1rem;
  }
  .my-order-wrapper .card::after {
    width: 2px;
    height: calc(100% - 3rem);
    border-right: 2px dashed #ebebeb;
    content: "";
    position: absolute;
    top: 1.5rem;
    right: 35px;
  }

  .single-order-status {
    position: relative;
    z-index: 1;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  .single-order-status:last-child {
    margin-bottom: 0;
  }
  .single-order-status .order-icon {
    margin-right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #c2d4f8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    color: #747794;
    font-size: 1.25rem;
  }
  .single-order-status .order-text h6 {
    font-size: 14px;
    margin-bottom: 0.375rem;
    line-height: 1;
    color: #747794;
  }
  .single-order-status .order-text span {
    font-size: 12px;
    display: block;
  }
  .single-order-status .order-status {
    margin-left: auto !important;
  }
  .single-order-status .order-status i {
    color: #c2d4f8;
    font-size: 1.5rem;
  }
  .single-order-status.active .order-icon {
    color: #ffffff;
    background-color: #eb3237;
  }
  .single-order-status.active .order-text h6 {
    color: #020310;
  }
  .single-order-status.active .order-status i {
    color: #00b894;
  }

  .order-success-wrapper {
    position: relative;
    width: 100%;
    background: #3e4093;
    background: -o-linear-gradient(right, #3e4093, #0d5cd1);
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(#3e4093),
      to(#0d5cd1)
    );
    background: linear-gradient(to left, #3e4093, #0d5cd1);
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 3rem;
  }
  .order-success-wrapper i {
    display: inline-block;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    color: #ffffff;
  }

  .billing-information-card {
    position: relative;
    z-index: 1;
  }

  .billing-information-title-card,
  .shipping-method-choose-title-card {
    border-radius: 0.5rem 0.5rem 0 0 !important;
    border-color: #eb3237;
    background-color: #3e4093 !important;
  }

  .shipping-method-choose-title-card {
    border-radius: 0.5rem 0.5rem 0 0;
    border-color: #00b894;
  }

  .shipping-method-choose-card {
    border-radius: 0 0 0.5rem 0.5rem !important;
  }

  .single-payment-method a {
    display: block;
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    text-align: center;
    padding: 2rem 1rem;
    border-radius: 6px;
  }
  .single-payment-method a::after {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
    content: "\f00c";
    font-family: "FontAwesome";
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    background-color: #00b894;
    color: #ffffff;
    line-height: 1.75rem;
    opacity: 0;
  }
  .single-payment-method a i {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    font-size: 3rem;
    margin-bottom: 0.5rem;
    display: block;
    color: #00b894;
  }
  .single-payment-method a h6 {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    margin-bottom: 0;
    color: #747794;
  }
  .single-payment-method a.active::after,
  .single-payment-method a:hover::after,
  .single-payment-method a:focus::after {
    opacity: 1;
  }
  .single-payment-method a.active h6,
  .single-payment-method a:hover h6,
  .single-payment-method a:focus h6 {
    color: #020310;
  }

  .credit-card-info-wrapper img {
    margin: 0 auto;
    max-width: 300px;
  }

  .pay-credit-card-form {
    position: relative;
    z-index: 1;
  }
  .pay-credit-card-form label {
    font-size: 14px;
    font-weight: 500;
    margin-left: 0.25rem;
    text-transform: capitalize;
  }
  .pay-credit-card-form small {
    font-size: 12px;
  }
  .pay-credit-card-form .form-control {
    height: 44px;
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-shadow: 0 1px 1px 0px #d7def4;
    box-shadow: 0 1px 1px 0px #d7def4;
  }

  .bank-ac-info .list-group-item {
    font-size: 14px;
  }

  .language-area-wrapper,
  .shipping-method-choose {
    position: relative;
  }
  .language-area-wrapper ul,
  .shipping-method-choose ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .language-area-wrapper ul li,
  .shipping-method-choose ul li {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
  }
  .language-area-wrapper ul li input[type="radio"],
  .shipping-method-choose ul li input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }
  .language-area-wrapper ul li label,
  .shipping-method-choose ul li label {
    display: block;
    position: relative;
    padding: 10px 10px 10px 45px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
    margin-bottom: 0;
    border-radius: 0.4rem;
    font-size: 14px;
    font-weight: 500;
  }
  .language-area-wrapper ul li label span,
  .shipping-method-choose ul li label span {
    font-size: 11px;
    margin-left: 0.5rem;
  }
  .language-area-wrapper ul li .check,
  .shipping-method-choose ul li .check {
    display: block;
    position: absolute;
    border: 4px solid #00b894;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 50%;
    margin-top: -10px;
    left: 15px;
    z-index: 5;
    -webkit-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
  }
  .language-area-wrapper ul li .check::before,
  .shipping-method-choose ul li .check::before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 50%;
    left: 50%;
    margin: -4px;
    -webkit-transition: background 0.25s linear;
    -o-transition: background 0.25s linear;
    transition: background 0.25s linear;
  }
  .language-area-wrapper input[type="radio"]:checked ~ .check,
  .shipping-method-choose input[type="radio"]:checked ~ .check {
    border-color: #3e4093;
  }
  .language-area-wrapper input[type="radio"]:checked ~ .check::before,
  .shipping-method-choose input[type="radio"]:checked ~ .check::before {
    background: #3e4093;
  }
  .language-area-wrapper input[type="radio"]:checked ~ label,
  .shipping-method-choose input[type="radio"]:checked ~ label {
    color: #3e4093;
    background-color: rgba(16, 13, 209, 0.103);
  }

  /* Accordion CSS */
  .accordian-card {
    position: relative;
    z-index: 1;
  }
  .accordian-card .card-body {
    padding: 1.25rem;
  }
  .accordian-card p {
    padding-left: 35px;
  }
  .accordian-card p:last-child {
    margin-bottom: 0;
  }
  .accordian-card .accordian-header.collapse {
    display: block;
  }
  .accordian-card .accordian-header button {
    color: #3e4093;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
  .accordian-card .accordian-header button span i {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #3e4093;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    margin-right: 0.5rem;
    font-size: 14px;
  }
  .accordian-card .accordian-header button > i {
    color: #747794;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  .accordian-card .accordian-header button:hover,
  .accordian-card .accordian-header button:focus {
    color: #3e4093;
  }
  .accordian-card .accordian-header button.collapsed > span {
    color: #020310;
  }
  .accordian-card .accordian-header button.collapsed > i {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  .accordian-card.seller-card .accordian-header button span i {
    background-color: #eb3237;
  }
  .accordian-card.others-card .accordian-header button span i {
    background-color: #00b894;
  }

  /* FAQ Search CSS */
  .faq-search-form {
    position: relative;
    z-index: 1;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .faq-search-form input {
    border-radius: 60px;
    padding-left: 40px;
    font-weight: 500;
    height: auto !important;
  }
  .faq-search-form input:focus {
    border-color: #3e4093;
  }
  .faq-search-form button {
    position: absolute;
    width: 50px;
    height: 38px;
    border: 0;
    background-color: transparent;
    text-align: center;
    top: 0;
    left: 0;
    z-index: 10;
    outline: none !important;
  }

  .support-wrapper .faq-heading {
    background: #3e4093;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#3e4093),
      to(#00b894)
    );
    background: -o-linear-gradient(left, #3e4093, #00b894);
    background: linear-gradient(to right, #3e4093, #00b894);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }

  /* Blog CSS */
  .post-bookmark-wrap {
    position: relative;
    z-index: 1;
  }

  .blog-card {
    position: relative;
    z-index: 1;
    border: 0;
  }
  .blog-card .post-img {
    position: relative;
    z-index: 1;
    border-radius: 6px;
  }
  .blog-card .post-img img {
    border-radius: 6px;
  }
  .blog-card .post-img::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background-color: #020310;
    opacity: 0.3;
    z-index: 1;
    border-radius: 6px;
  }
  .blog-card .post-bookmark {
    position: absolute;
    z-index: 100;
    color: #ffffff;
    top: 0.5rem;
    right: 0.5rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .blog-card .post-bookmark:hover,
  .blog-card .post-bookmark:focus {
    background-color: #ffffff;
    color: #eb3237;
  }
  .blog-card .post-content {
    position: absolute !important;
    width: calc(100% - 1rem);
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 100;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 0.75rem;
    overflow: hidden;
  }
  .blog-card .post-content .bg-shapes .circle1 {
    width: 30px;
    height: 30px;
    position: absolute;
    border-radius: 50%;
    top: -15px;
    right: -15px;
    background-color: transparent;
    border: 1px solid #ebebeb;
    z-index: -1;
  }
  .blog-card .post-content .bg-shapes .circle2 {
    width: 50px;
    height: 50px;
    position: absolute;
    border-radius: 50%;
    top: -25px;
    right: -25px;
    background-color: transparent;
    border: 1px solid #ebebeb;
    z-index: -1;
  }
  .blog-card .post-content .bg-shapes .circle3 {
    width: 70px;
    height: 70px;
    position: absolute;
    border-radius: 50%;
    top: -35px;
    right: -35px;
    background-color: transparent;
    border: 1px solid #ebebeb;
    z-index: -1;
  }
  .blog-card .post-content .bg-shapes .circle4 {
    width: 90px;
    height: 90px;
    position: absolute;
    border-radius: 50%;
    top: -45px;
    right: -45px;
    background-color: transparent;
    border: 1px solid #ebebeb;
    z-index: -1;
  }
  .blog-card .post-content .post-catagory {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    color: #eb3237;
    margin-bottom: 0.125rem;
    line-height: 1;
  }
  .blog-card .post-content .post-title {
    font-size: 1rem;
    color: #020310;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
  .blog-card .post-content .post-title:hover,
  .blog-card .post-content .post-title:focus {
    color: #3e4093;
  }
  .blog-card .post-content .post-meta a {
    font-size: 12px;
    line-height: 1;
    color: #747794;
  }
  .blog-card .post-content .post-meta a:hover,
  .blog-card .post-content .post-meta a:focus {
    color: #3e4093;
  }
  .blog-card .post-content .post-meta a i {
    margin-right: 0.25rem;
  }
  .blog-card .post-content .post-meta span {
    font-size: 12px;
    line-height: 1;
    color: #747794;
  }
  .blog-card .post-content .post-meta span i {
    margin-right: 0.25rem;
  }
  .blog-card.list-card .post-content {
    width: calc(50% - 1rem);
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .blog-card.list-card .post-bookmark {
    top: 0.75rem;
    right: 0.75rem;
  }
  .blog-card.list-card .read-more-btn {
    position: absolute;
    min-width: 100px;
    right: 0.75rem;
    bottom: 0.75rem;
    z-index: 99;
  }

  .blog-catagory-card a {
    display: block;
  }
  .blog-catagory-card a i {
    color: #c2d4f8;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    display: block;
  }
  .blog-catagory-card a span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #747794;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
  .blog-catagory-card a:hover span,
  .blog-catagory-card a:focus span {
    color: #3e4093;
  }

  .blog-details-post-thumbnail {
    position: relative;
    z-index: 1;
    margin-bottom: -40px;
  }
  .blog-details-post-thumbnail .post-bookmark {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ffffff;
    text-align: center;
    position: absolute;
    top: 1rem;
    left: 0;
    z-index: 100;
    color: #eb3237;
  }
  .blog-details-post-thumbnail .post-bookmark i {
    line-height: 35px;
  }
  .blog-details-post-thumbnail .post-bookmark:hover,
  .blog-details-post-thumbnail .post-bookmark:focus {
    background-color: #eb3237;
    color: #ffffff;
  }

  .product-title-meta-data {
    position: relative;
    z-index: 1;
  }
  .product-title-meta-data .post-catagory {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: #eb3237;
    font-weight: 500;
  }

  .post-meta-data a {
    color: #747794;
    font-size: 12px;
  }
  .post-meta-data a img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.25rem;
  }
  .post-meta-data a span {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 3px 8px;
    border: 1px solid #eb3237;
    border-radius: 0.25rem;
    margin-left: 0.5rem;
    color: #020310;
    background-color: #eb3237;
  }
  .post-meta-data span {
    color: #747794;
    font-size: 12px;
  }
  .post-meta-data span i {
    margin-right: 0.25rem;
  }

  .post-content p:last-child {
    margin-bottom: 0;
  }

  /* Contact CSS */
  .form-control {
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    border-color: #ebebeb;
    height: 50px;
    padding: 0.375rem 1rem;
    font-size: 14px;
  }
  .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .contact-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 10;
    overflow-y: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-x: hidden;
    background-color: #e7eaf5;
  }

  .contact-form {
    position: relative;
    z-index: 1;
  }
  .contact-form .form-group {
    position: relative;
    z-index: 1;
  }
  .contact-form form > .form-group > label {
    position: absolute;
    text-align: center;
    z-index: 100;
    border-radius: 50%;
    left: 10px;
    font-size: 16px;
    margin-bottom: 0;
    color: #747794;
    top: 11px;
  }
  .contact-form .form-control {
    border-radius: 6px;
    border: 0;
    color: #747794;
    font-size: 14px;
    background-color: #ffffff;
    height: 42px;
    padding: 0.25rem 1rem;
  }
  .contact-form .form-control::-webkit-input-placeholder {
    color: #747794;
    opacity: 1;
  }
  .contact-form .form-control::-moz-placeholder {
    color: #747794;
    opacity: 1;
  }
  .contact-form .form-control:-ms-input-placeholder {
    color: #747794;
    opacity: 1;
  }
  .contact-form .form-control::-ms-input-placeholder {
    color: #747794;
    opacity: 1;
  }
  .contact-form .form-control::placeholder {
    color: #747794;
    opacity: 1;
  }
  .contact-form .nice-select ul.list {
    width: 100%;
  }
  .contact-form textarea.form-control {
    height: 120px;
  }

  .google-maps-wrap {
    position: relative;
    z-index: 1;
  }
  .google-maps-wrap iframe {
    width: 100%;
    height: 300px;
    border: 0;
  }

  /* CTA Area */
  .cta-text {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 6px;
    background-image: url(../img/banner_1.jpg);
    background-size: cover;
    background-position: center;
  }
  .cta-text::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: 0.7;
  }
  .cta-text .row {
    z-index: 2;
    position: relative;
  }
  .cta-text img {
    position: absolute;
    max-height: 120px;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .cta-text .comman_btn {
    font-size: 13px;
    display: inline-flex;
    padding: 8px 16px;
    width: auto;
  }
  .video-cta-content {
    position: relative;
    z-index: 1;
    height: 170px;
  }
  .video-cta-content a {
    padding: 0 !important;
    width: 3rem;
    height: 3rem;
  }
  .video-cta-content a i {
    font-size: 1.125rem;
    line-height: 46px;
    padding-left: 0.25rem;
  }

  .discount-coupon-card {
    border-radius: 6px;
    background: #3e4093;
    background: -o-linear-gradient(right, #3e4093, #0d5cd1);
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(#3e4093),
      to(#0d5cd1)
    );
    background: linear-gradient(to left, #3e4093, #0d5cd1);
  }
  .discount-coupon-card .discountIcon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 4rem;
    flex: 0 0 4rem;
    max-width: 4rem;
    width: 4rem;
    margin-right: 1rem;
  }

  /* Profile Data CSS */
  .single-profile-data {
    position: relative;
    z-index: 1;
    margin-bottom: 1.25rem;
  }
  .edit_pen {
    position: relative;
    top: 12px !important;
    left: 3px;
    z-index: 999;
  }
  .file_selector_app {
    opacity: 1% !important;
    position: relative;
    font-size: 10px;
    top: 8px;
    left: -30px;
    width: 40px !important;
    z-index: 99999;
  }
  .single-profile-data:last-child {
    margin-bottom: 0;
  }
  .single-profile-data .title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    font-size: 12px;
    color: #020310;
  }
  .single-profile-data .title i {
    width: 22px;
    height: 22px;
    display: inline-block;
    background-color: #8a4821;
    text-align: center;
    color: #ffffff;
    line-height: 23px;
    border-radius: 0.25rem;
    margin-right: 0.4rem;
    font-size: 12px;
  }
  .single-profile-data .title span {
    line-height: 1;
  }
  .single-profile-data .data-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    font-size: 13px;
    text-align: right;
    color: #747794;
  }

  .user-data-card {
    position: relative;
    z-index: 1;
    border-color: #ffffff;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .user-data-card .card-body {
    padding: 1.25rem;
  }
  .user-data-card .title {
    font-size: 14px;
    color: #020310;
    font-weight: 500;
    margin-bottom: 0;
  }
  .user-data-card .title i {
    width: 24px;
    height: 24px;
    display: inline-block;
    background-color: #3e4093;
    text-align: center;
    color: #ffffff;
    line-height: 24px;
    border-radius: 0.25rem;
    margin-right: 0.4rem;
    font-size: 14px;
  }
  .user-data-card .form-control {
    height: 36px;
    padding: 0 15px;
    color: #747794;
    font-size: 13px;
  }
  .user-data-card textarea.form-control {
    height: auto;
    padding: 15px;
  }
  .user-data-card .progress {
    width: 100%;
    height: 5px;
    margin-top: 1rem;
    border-radius: 6px;
    margin-bottom: 0.25rem;
  }
  .user-data-card .password-score {
    font-size: 14px;
    font-weight: 600;
    color: #020310;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .user-data-card .password-score span {
    font-size: 18px;
    margin-left: 1rem;
  }
  .user-data-card .password-recommendation ul {
    padding-left: 0;
    font-size: 14px;
  }
  .user-data-card #password-recommendation-heading {
    font-weight: 600;
    color: #00b894;
    font-size: 16px;
  }

  .change-user-thumb {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0;
  }
  .change-user-thumb input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 10;
    opacity: 0;
  }
  .change-user-thumb button {
    width: 100%;
    height: 100%;
    background-color: #fef8ff;
    border: none;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    z-index: 1;
    border: 1px solid #ebebeb;
  }

  .user-info-card {
    position: relative;
    z-index: 1;
    background-color: #eb3237 !important;
    border-color: #eb3237 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .user-info-card .user-profile {
    position: relative;
    z-index: 1;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  .user-info-card .user-profile img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  /* Notification CSS */
  .notification-area {
    position: relative;
    z-index: 1;
  }
  .notification-area .list-group-item {
    border-color: #ebebeb;
    margin-bottom: 0.5rem;
    border-radius: 6px;
  }
  .notification-area .list-group-item .noti-icon {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    max-width: 30px;
    background: #3e4093;
    background: -o-linear-gradient(right, #3e4093, #0d5cd1);
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(#3e4093),
      to(#0d5cd1)
    );
    background: linear-gradient(to left, #3e4093, #0d5cd1);
    color: #ffffff;
    text-align: center;
    font-size: 1rem;
    margin-right: 0.75rem;
    border-radius: 50%;
  }
  .notification-area .list-group-item .noti-icon i {
    line-height: 30px;
  }
  .notification-area .list-group-item .noti-icon::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #eb3237;
    content: "";
    top: -2px;
    right: -2px;
    border-radius: 50%;
    z-index: 1;
  }
  .notification-area .list-group-item p {
    margin-bottom: 0.5rem;
  }
  .notification-area .list-group-item span {
    font-size: 12px;
    display: block;
    color: #747794;
  }
  .notification-area .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .notification-area .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .notification-area .list-group-item.readed {
    background-color: #fef8ff;
  }
  .notification-area .list-group-item.readed .noti-icon {
    background: #00b894;
  }
  .notification-area .list-group-item.readed .noti-icon::before {
    display: none;
  }
  .notification-area .list-group-item.readed h6 {
    color: #747794;
  }
  .notification-area .unread-notifications {
    position: relative;
    z-index: 1;
  }

  .notification-clear-all {
    font-size: 14px;
  }

  .single-vendor-wrap {
    position: relative;
    z-index: 1;
    border-radius: 0.5rem;
  }
  .single-vendor-wrap.bg-overlay::after {
    border-radius: 0.5rem;
    background: #020310;
    opacity: 0.5;
  }
  .single-vendor-wrap .vendor-info p {
    font-size: 12px;
  }
  .single-vendor-wrap .vendor-info .ratings > i {
    color: #eb3237;
    margin-right: 2px;
    font-size: 10px;
  }
  .single-vendor-wrap .vendor-info span {
    font-size: 12px;
    margin-left: 4px;
  }
  .single-vendor-wrap .vendor-profile {
    padding: 0.5rem;
    position: absolute;
    width: 4rem;
    height: 4rem;
    bottom: 1.5rem;
    right: 1.5rem;
    background-color: #ffffff;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 100;
  }
  .single-vendor-wrap .vendor-profile img {
    max-width: 2.5rem;
  }

  .vendor-details-wrap {
    position: relative;
    z-index: 1;
  }
  .vendor-details-wrap.bg-overlay::after {
    background: #020310;
    opacity: 0.75;
  }
  .vendor-details-wrap .vendor-profile {
    padding: 0.5rem;
    width: 4rem;
    height: 4rem;
    background-color: #ffffff;
    border-radius: 0.25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 100;
  }
  .vendor-details-wrap .vendor-profile img {
    max-width: 2.5rem;
  }
  .vendor-details-wrap .vendor-info p {
    font-size: 12px;
  }
  .vendor-details-wrap .vendor-info .ratings > i {
    color: #eb3237;
    margin-right: 0;
    font-size: 12px;
  }
  .vendor-details-wrap .vendor-info span {
    font-size: 12px;
    margin-left: 4px;
  }
  .vendor-details-wrap .single-basic-info {
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 1rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .vendor-details-wrap .single-basic-info:last-child {
    margin-right: 0;
  }
  .vendor-details-wrap .single-basic-info .icon {
    line-height: 1;
    margin-right: 0.25rem;
    color: #eb3237;
    font-size: 14px;
  }
  .vendor-details-wrap .single-basic-info span {
    font-size: 13px;
  }

  .vendor-tabs {
    background-color: #ffffff;
  }
  .vendor-tabs .nav-tabs {
    border-bottom: 0;
  }
  .vendor-tabs .nav-link {
    margin-bottom: 0;
    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #747794;
    font-size: 14px;
    font-weight: 500;
  }
  .vendor-tabs .nav-item.show .nav-link,
  .vendor-tabs .nav-link.active {
    color: #020310;
  }

  /* Night Mode */
  [theme-color="dark"] {
    background-color: #0c153b;
  }
  [theme-color="dark"] body,
  [theme-color="dark"] #preloader {
    background-color: #0c153b;
  }
  [theme-color="dark"] a,
  [theme-color="dark"] a:hover,
  [theme-color="dark"] a:focus,
  [theme-color="dark"] h1,
  [theme-color="dark"] h2,
  [theme-color="dark"] h3,
  [theme-color="dark"] h4,
  [theme-color="dark"] h5,
  [theme-color="dark"] h6 {
    color: #ffffff;
  }
  [theme-color="dark"] .header-area {
    background-color: #0c153b;
    -webkit-box-shadow: 0 3px 12px rgba(255, 255, 255, 0.1);
    box-shadow: 0 3px 12px rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .card {
    border-color: #242644;
    background-color: #242644;
  }
  [theme-color="dark"] .border,
  [theme-color="dark"] .border-start,
  [theme-color="dark"] .border-right,
  [theme-color="dark"] .border-bottom,
  [theme-color="dark"] .border-top,
  [theme-color="dark"] .list-group-item {
    border-color: rgba(255, 255, 255, 0.1) !important;
  }
  [theme-color="dark"] .user-profile-icon a,
  [theme-color="dark"] .suha-navbar-toggler {
    border-color: rgba(255, 255, 255, 0.2);
  }
  [theme-color="dark"] .cart-icon-wrap a span {
    background-color: #3e4093;
  }
  [theme-color="dark"] .dropdown-menu {
    background-color: #332858;
  }
  [theme-color="dark"] .dropdown-menu .dropdown-item:hover,
  [theme-color="dark"] .dropdown-menu .dropdown-item:focus {
    color: #ffffff !important;
  }
  [theme-color="dark"] .bg-white {
    background-color: #332858 !important;
  }
  [theme-color="dark"] .btn-warning,
  [theme-color="dark"] .btn-info {
    color: #020310;
  }
  [theme-color="dark"] .btn-warning:hover,
  [theme-color="dark"] .btn-warning:focus,
  [theme-color="dark"] .btn-info:hover,
  [theme-color="dark"] .btn-info:focus {
    color: #020310;
  }
  [theme-color="dark"] .btn-close {
    -webkit-filter: invert(1) grayscale(100%) brightness(200%);
    filter: invert(1) grayscale(100%) brightness(200%);
  }
  [theme-color="dark"] .catagory-card.active,
  [theme-color="dark"] .catagory-card:hover,
  [theme-color="dark"] .catagory-card:focus {
    background-color: #332858;
  }
  [theme-color="dark"] .catagory-card.active span,
  [theme-color="dark"] .catagory-card:hover span,
  [theme-color="dark"] .catagory-card:focus span {
    color: #eb3237;
  }
  [theme-color="dark"] .flash-sale-card .product-title {
    color: #ffffff;
  }
  [theme-color="dark"] .flash-sale-card .sale-price {
    color: #ffffff;
  }
  [theme-color="dark"] .product-card .product-title {
    color: #ffffff;
  }
  [theme-color="dark"] .product-card .product-title:hover,
  [theme-color="dark"] .product-card .product-title:focus {
    color: #eb3237;
  }
  [theme-color="dark"] .product-card .sale-price {
    color: #ffffff;
  }
  [theme-color="dark"]
    .horizontal-product-card
    .product-thumbnail-side
    .product-thumbnail {
    background-color: #332858;
  }
  [theme-color="dark"]
    .horizontal-product-card
    .product-description
    .product-title {
    color: #ffffff;
  }
  [theme-color="dark"]
    .horizontal-product-card
    .product-description
    .wishlist-btn {
    color: #eb3237;
    background-color: #242644;
  }
  [theme-color="dark"]
    .horizontal-product-card
    .product-description
    .delete-btn {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"]
    .horizontal-product-card
    .product-description
    .sale-price {
    color: #ffffff;
  }
  [theme-color="dark"]
    .featured-product-card
    .product-description
    .product-title {
    color: #ffffff;
  }
  [theme-color="dark"]
    .featured-product-card
    .product-description
    .product-title:hover,
  [theme-color="dark"]
    .featured-product-card
    .product-description
    .product-title:focus {
    color: #eb3237;
  }
  [theme-color="dark"] .featured-product-card .product-description .sale-price {
    color: #ffffff;
  }
  [theme-color="dark"] .page-nav li a {
    background-color: #332858;
    color: #ffffff;
  }
  [theme-color="dark"] .page-nav li a:hover,
  [theme-color="dark"] .page-nav li a:focus {
    color: #eb3237;
  }
  [theme-color="dark"] .back-button a {
    color: #ffffff;
  }
  [theme-color="dark"] .back-button a:hover,
  [theme-color="dark"] .back-button a:focus {
    color: #eb3237;
  }
  [theme-color="dark"] .suha-filter-offcanvas-wrap {
    background-color: #332858;
  }
  [theme-color="dark"] .form-check-input {
    background-color: #0c153b;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  [theme-color="dark"] .form-control {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #242644;
    color: #ffffff;
  }
  [theme-color="dark"] .product-catagories a {
    background-color: #332858;
    color: #ffffff;
  }
  [theme-color="dark"] .nice-select {
    background-color: #332858;
    border-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .nice-select .list {
    background-color: #332858;
  }
  [theme-color="dark"] .nice-select .list .option:hover,
  [theme-color="dark"] .nice-select .list .option.focus,
  [theme-color="dark"] .nice-select .list .option.selected.focus {
    background-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
  }
  [theme-color="dark"] .product-title-meta-data {
    background-color: #242644 !important;
  }
  [theme-color="dark"] .product-title-meta-data::after {
    /* background-image: url(img/core-img/curve2.png); */
  }
  [theme-color="dark"] .p-wishlist-share a {
    color: #eb3237;
  }
  [theme-color="dark"] .progress {
    background-color: rgba(255, 255, 255, 0.15);
  }
  [theme-color="dark"] .cart-form .quantity-button-handler {
    background-color: #242644;
    border-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
  }
  [theme-color="dark"] .product-slide-wrapper .video-btn {
    background-color: #0c153b;
    color: #eb3237;
  }
  [theme-color="dark"] .product-slides .owl-prev,
  [theme-color="dark"] .product-slides .owl-next {
    background-color: #0c153b !important;
    color: #ffffff;
  }
  [theme-color="dark"] .single-user-review {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }
  [theme-color="dark"] .layout-options a {
    background-color: #332858;
  }
  [theme-color="dark"] .layout-options a.active,
  [theme-color="dark"] .layout-options a:hover,
  [theme-color="dark"] .layout-options a:focus {
    color: #ffffff;
    background-color: #eb3237;
  }
  [theme-color="dark"] .single-order-status.active .order-text h6 {
    color: #ffffff;
  }
  [theme-color="dark"] .my-order-wrapper .card::after {
    border-right-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .vendor-tabs {
    background-color: #332858;
  }
  [theme-color="dark"] .vendor-tabs .nav-item.show .nav-link,
  [theme-color="dark"] .vendor-tabs .nav-link.active {
    color: #eb3237;
    background-color: transparent;
  }
  [theme-color="dark"] .register-form .form-control {
    background-color: transparent;
  }
  [theme-color="dark"] .cart-table table tbody tr {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .cart-table table a {
    color: #ffffff;
  }
  [theme-color="dark"] .cart-table table a:hover,
  [theme-color="dark"] .cart-table table a:focus {
    color: #eb3237;
  }
  [theme-color="dark"] .cart-table img {
    border-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"]
    .language-area-wrapper
    input[type="radio"]:checked
    ~ label,
  [theme-color="dark"]
    .language-area-wrapper
    input[type="radio"]:checked
    ~ label,
  [theme-color="dark"]
    .shipping-method-choose
    input[type="radio"]:checked
    ~ label,
  [theme-color="dark"]
    .shipping-method-choose
    input[type="radio"]:checked
    ~ label {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.103);
  }
  [theme-color="dark"]
    .language-area-wrapper
    input[type="radio"]:checked
    ~ .check,
  [theme-color="dark"]
    .language-area-wrapper
    input[type="radio"]:checked
    ~ .check,
  [theme-color="dark"]
    .shipping-method-choose
    input[type="radio"]:checked
    ~ .check,
  [theme-color="dark"]
    .shipping-method-choose
    input[type="radio"]:checked
    ~ .check {
    border-color: #eb3237;
  }
  [theme-color="dark"]
    .language-area-wrapper
    input[type="radio"]:checked
    ~ .check::before,
  [theme-color="dark"]
    .language-area-wrapper
    input[type="radio"]:checked
    ~ .check::before,
  [theme-color="dark"]
    .shipping-method-choose
    input[type="radio"]:checked
    ~ .check::before,
  [theme-color="dark"]
    .shipping-method-choose
    input[type="radio"]:checked
    ~ .check::before {
    background: #eb3237;
  }
  [theme-color="dark"] .single-payment-method a {
    background-color: #332858;
  }
  [theme-color="dark"] .single-payment-method a.active h6,
  [theme-color="dark"] .single-payment-method a:hover h6,
  [theme-color="dark"] .single-payment-method a:focus h6 {
    color: #eb3237;
  }
  [theme-color="dark"] .list-group-item {
    background-color: #242644;
    color: #ffffff;
  }
  [theme-color="dark"] .pay-credit-card-form .form-control {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  [theme-color="dark"] .otp-form input.form-control {
    background-color: rgba(255, 255, 255, 0.25);
  }
  [theme-color="dark"] .otp-form .nice-select {
    background-color: rgba(255, 255, 255, 0.25);
    color: #ffffff;
  }
  [theme-color="dark"] .otp-verify-form .form-control {
    background-color: rgba(255, 255, 255, 0.25);
  }
  [theme-color="dark"] .user-info-card {
    background-color: #eb3237;
    border-color: #eb3237;
  }
  [theme-color="dark"] .user-info-card h5 {
    color: #020310;
  }
  [theme-color="dark"] .user-data-card .title {
    color: #ffffff;
  }
  [theme-color="dark"] .user-data-card .password-score {
    color: #ffffff;
  }
  [theme-color="dark"] .blog-card .post-content {
    background-color: #332858;
  }
  [theme-color="dark"] .blog-card .post-content .post-title {
    color: #ffffff;
  }
  [theme-color="dark"] .blog-card .post-content .post-title:hover,
  [theme-color="dark"] .blog-card .post-content .post-title:focus {
    color: #eb3237;
  }
  [theme-color="dark"] .blog-card .post-content .bg-shapes .circle1,
  [theme-color="dark"] .blog-card .post-content .bg-shapes .circle2,
  [theme-color="dark"] .blog-card .post-content .bg-shapes .circle3,
  [theme-color="dark"] .blog-card .post-content .bg-shapes .circle4,
  [theme-color="dark"] .blog-card .post-content .bg-shapes .circle5 {
    border-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .blog-details-post-thumbnail .post-bookmark {
    background-color: #242644;
    color: #ffffff;
  }
  [theme-color="dark"] .blog-details-post-thumbnail .post-bookmark:hover,
  [theme-color="dark"] .blog-details-post-thumbnail .post-bookmark:focus {
    background-color: #eb3237;
    color: #ffffff;
  }
  [theme-color="dark"] .agent-message-content .agent-message-text p {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"]
    .agent-message-content
    .agent-message-text
    .writing-mode {
    background-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .type-text-form .form-control {
    border: 0 !important;
    border-radius: 0;
  }
  [theme-color="dark"] .type-text-form .file-upload {
    border-color: rgba(255, 255, 255, 0.1);
  }
  [theme-color="dark"] .type-text-form .file-upload i {
    background-color: #332858;
    color: #ffffff;
  }
  [theme-color="dark"] .type-text-form button[type="submit"] {
    background-color: transparent;
    color: #eb3237;
  }
  [theme-color="dark"] .notification-area .list-group-item.readed {
    background-color: #332858;
  }
  [theme-color="dark"] .faq-search-form button {
    color: #eb3237;
  }
  [theme-color="dark"] .accordian-card .accordian-header button {
    color: #eb3237;
  }
  [theme-color="dark"]
    .accordian-card
    .accordian-header
    button.collapsed
    > span {
    color: #ffffff;
  }
  [theme-color="dark"] .preview-iframe-wrapper .preview-hero-area {
    background-image: none;
    background-color: #0c153b;
  }
  [theme-color="dark"] .preview-iframe-wrapper .preview-hero-area::after {
    /* background-image: url(img/core-img/curve2.png); */
  }
  [theme-color="dark"]
    .preview-iframe-wrapper
    .preview-content-wrapper
    .demo-title
    span {
    color: #eb3237;
  }
  [theme-color="dark"] .preview-iframe-wrapper .qr-code-wrapper {
    background-color: #242644;
  }
  [theme-color="dark"] .preview-iframe-wrapper .features-area {
    background-color: #242644 !important;
  }
  [theme-color="dark"] .preview-iframe-wrapper .features-area ul li.active {
    color: #ffffff;
  }
  [theme-color="dark"]
    .preview-iframe-wrapper
    .features-area
    ul
    li.active::before {
    color: #eb3237;
  }
  [theme-color="dark"] .preview-footer-area .footer-nav a:hover,
  [theme-color="dark"] .preview-footer-area .footer-nav a:focus {
    color: #eb3237;
  }

  [view-mode="rtl"] {
    /* Demo */
  }
  [view-mode="rtl"] .rtl-flex-d-row-r {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] .dir-rtl {
    direction: rtl;
  }
  [view-mode="rtl"] .rtl-text-right {
    text-align: right !important;
  }
  [view-mode="rtl"] .me-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }
  [view-mode="rtl"] .ms-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }
  [view-mode="rtl"] .me-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }
  [view-mode="rtl"] .ms-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }
  [view-mode="rtl"] .me-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }
  [view-mode="rtl"] .ms-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  [view-mode="rtl"] .pe-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }
  [view-mode="rtl"] .ps-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }
  [view-mode="rtl"] .pe-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }
  [view-mode="rtl"] .ps-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }
  [view-mode="rtl"] .pe-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  [view-mode="rtl"] .ps-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }
  [view-mode="rtl"] .ms-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  [view-mode="rtl"] .btn {
    direction: rtl;
  }
  [view-mode="rtl"] .pwa-install-alert {
    right: auto;
    left: 12px;
  }
  [view-mode="rtl"] .pwa-install-alert .toast-body {
    direction: rtl;
  }
  [view-mode="rtl"] .pwa-install-alert .toast-body .content img {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  [view-mode="rtl"] .back-button a {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [view-mode="rtl"] .select-product-catagory .nice-select.right {
    float: left;
  }
  [view-mode="rtl"] .select-product-catagory .nice-select {
    padding-left: 30px;
    padding-right: 18px;
  }
  [view-mode="rtl"] .select-product-catagory .nice-select::after {
    right: auto;
    left: 12px;
  }
  [view-mode="rtl"] .select-product-catagory .nice-select .option {
    padding-left: 29px;
    padding-right: 18px;
    text-align: right;
  }
  [view-mode="rtl"] .search-form .alternative-search-options {
    margin-left: 0;
    margin-right: auto;
  }
  [view-mode="rtl"] .search-form .alternative-search-options .dropdown-menu {
    left: 0 !important;
  }
  [view-mode="rtl"]
    .search-form
    .alternative-search-options
    .dropdown-menu
    .dropdown-item {
    direction: rtl;
    text-align: right;
  }
  [view-mode="rtl"]
    .search-form
    .alternative-search-options
    .dropdown-menu
    .dropdown-item
    i {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"] .search-form form .form-control {
    padding-left: 15px;
    padding-right: 42px;
    text-align: right;
  }
  [view-mode="rtl"] .search-form form button {
    left: auto;
    right: 1rem;
  }
  [view-mode="rtl"] .cart-icon-wrap {
    margin-right: 0.5rem;
  }
  [view-mode="rtl"] .single-hero-slide .slide-content {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  [view-mode="rtl"] .single-hero-slide .slide-content .slide-text {
    text-align: right;
    padding-right: 2rem;
    padding-left: 1rem;
  }
  [view-mode="rtl"] .sales-end-timer li {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  [view-mode="rtl"] .sales-end-timer li:first-child {
    margin-left: 0;
  }
  [view-mode="rtl"] .flash-sale-card {
    direction: rtl;
  }
  [view-mode="rtl"] .flash-sale-card .real-price {
    margin-left: 0;
    margin-right: 4px;
  }
  [view-mode="rtl"] .dark-mode-wrapper {
    direction: rtl;
  }
  [view-mode="rtl"] .form-check {
    padding-right: 1.5em;
    padding-left: 0;
    text-align: right;
  }
  [view-mode="rtl"] .form-check .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0;
  }
  [view-mode="rtl"] .form-check.form-switch {
    padding-right: 2.5em;
    padding-left: 0;
    text-align: right;
  }
  [view-mode="rtl"] .form-check.form-switch .form-check-input {
    margin-right: -2.5em;
    margin-left: 0;
  }
  [view-mode="rtl"] .form-check.form-switch .form-check-input {
    float: right;
  }
  [view-mode="rtl"] .product-card {
    direction: rtl;
  }
  [view-mode="rtl"] .product-card .btn {
    right: auto;
    left: 1rem;
  }
  [view-mode="rtl"] .product-card .badge {
    left: auto;
    right: 1rem;
  }
  [view-mode="rtl"] .product-card .wishlist-btn {
    right: auto;
    left: 1rem;
  }
  [view-mode="rtl"]
    .product-card
    .product-thumbnail
    .offer-countdown-timer
    li:first-child {
    margin-right: 0;
  }
  [view-mode="rtl"]
    .product-card
    .product-thumbnail
    .offer-countdown-timer
    li:last-child {
    margin-right: 0.25rem;
  }
  [view-mode="rtl"] .cta-text img {
    right: auto;
    bottom: auto;
    left: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    top: 0;
  }
  [view-mode="rtl"] .horizontal-product-card {
    direction: rtl;
  }
  [view-mode="rtl"]
    .horizontal-product-card
    .product-thumbnail-side
    .product-thumbnail {
    margin-right: 0;
    margin-left: 1rem;
  }
  [view-mode="rtl"] .horizontal-product-card .product-description {
    padding-right: 0;
    padding-left: 1.5rem;
  }
  [view-mode="rtl"]
    .horizontal-product-card
    .product-description
    .wishlist-btn {
    right: auto;
    left: 0;
  }
  [view-mode="rtl"]
    .horizontal-product-card
    .product-description
    .sale-price
    i {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"]
    .horizontal-product-card
    .product-description
    .sale-price
    span {
    margin-left: 0;
    margin-right: 0.25rem;
  }
  [view-mode="rtl"]
    .horizontal-product-card
    .product-description
    .product-rating
    i {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"] .discount-coupon-card .discountIcon {
    margin-right: 0;
    margin-left: 1rem;
  }
  [view-mode="rtl"] .featured-product-card {
    direction: rtl;
  }
  [view-mode="rtl"] .featured-product-card .custom-badge {
    left: auto;
    right: 1rem;
  }
  [view-mode="rtl"] .collection-card .collection-title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] .sidenav-nav {
    margin: 2rem 0;
    text-align: right;
  }
  [view-mode="rtl"] .sidenav-nav li a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] li.suha-dropdown-menu .dropdown-trigger-btn {
    right: auto;
    left: 0;
    text-align: left;
    padding-right: 0;
    padding-left: 1rem;
  }
  [view-mode="rtl"] .suha-offcanvas-wrap .btn-close {
    right: auto;
    left: 1rem;
  }
  [view-mode="rtl"] .section-heading a i {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [view-mode="rtl"] .product-slide-wrapper .video-btn {
    left: auto;
    right: 1rem;
  }
  [view-mode="rtl"] .product-title-meta-data .p-wishlist-share {
    text-align: left;
  }
  [view-mode="rtl"] .product-title-meta-data .p-title-price {
    text-align: right;
  }
  [view-mode="rtl"] .product-description .total-result-of-ratings {
    direction: rtl;
  }
  [view-mode="rtl"]
    .product-description
    .total-result-of-ratings
    span:first-child {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"] .product-ratings .ratings {
    direction: rtl;
  }
  [view-mode="rtl"] .sales-offer-content {
    direction: rtl;
  }
  [view-mode="rtl"] .widget .widget-title {
    text-align: right;
  }
  [view-mode="rtl"] .suha-filter-offcanvas-wrap .btn-close {
    right: auto;
    left: 1rem;
  }
  [view-mode="rtl"] .selection-panel {
    text-align: right;
  }
  [view-mode="rtl"] .cart-form {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] .p-specification {
    direction: rtl;
  }
  [view-mode="rtl"] .single-user-review .user-thumbnail {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  [view-mode="rtl"] .single-user-review .rating-comment .review-image {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  [view-mode="rtl"] .ratings-submit-form .stars label {
    float: right;
  }
  [view-mode="rtl"] .layout-options a {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  [view-mode="rtl"] .product-card .delete-btn {
    right: auto;
    left: 1rem;
  }
  [view-mode="rtl"] .horizontal-product-card .product-description .delete-btn {
    right: auto;
    left: 0;
  }
  [view-mode="rtl"] .single-order-status {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    text-align: right;
  }
  [view-mode="rtl"] .single-order-status .order-status {
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  [view-mode="rtl"] .my-order-wrapper .card::after {
    right: auto;
    left: 35px;
  }
  [view-mode="rtl"] .single-order-status .order-icon {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  [view-mode="rtl"] .single-vendor-wrap {
    direction: rtl;
  }
  [view-mode="rtl"] .single-vendor-wrap .vendor-profile {
    right: auto;
    left: 1.5rem;
  }
  [view-mode="rtl"] .single-vendor-wrap .vendor-info span {
    margin-left: 0;
    margin-right: 4px;
  }
  [view-mode="rtl"] .vendor-details-wrap {
    direction: rtl;
  }
  [view-mode="rtl"] .vendor-basic-info {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] .vendor-details-wrap .single-basic-info .icon {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"] .cart-table {
    direction: rtl;
  }
  [view-mode="rtl"] .apply-coupon {
    direction: rtl;
  }
  [view-mode="rtl"] .coupon-form button {
    right: auto;
    left: 0;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  [view-mode="rtl"] .cart-amount-area {
    direction: rtl;
  }
  [view-mode="rtl"] .user-data-card {
    direction: rtl;
  }
  [view-mode="rtl"] .user-data-card .title i {
    margin-right: 0;
    margin-left: 0.4rem;
  }
  [view-mode="rtl"] .single-profile-data .data-content {
    text-align: left;
  }
  [view-mode="rtl"] .language-area-wrapper ul li label,
  [view-mode="rtl"] .shipping-method-choose ul li label {
    padding: 10px 45px 10px 10px;
    text-align: right;
  }
  [view-mode="rtl"] .language-area-wrapper ul li .check,
  [view-mode="rtl"] .shipping-method-choose ul li .check {
    left: auto;
    right: 15px;
  }
  [view-mode="rtl"] .credit-card-info-wrapper {
    direction: rtl;
  }
  [view-mode="rtl"] .form-control {
    text-align: right;
  }
  [view-mode="rtl"] .page-nav li a {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] .page-nav li a i {
    margin-left: 0;
    margin-right: auto;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [view-mode="rtl"] .register-form .form-control {
    padding: 8px 24px 8px 8px;
  }
  [view-mode="rtl"] .register-form form > .form-group > label {
    left: auto;
    right: 0;
  }
  [view-mode="rtl"] .register-form span {
    text-align: right;
  }
  [view-mode="rtl"] .register-form .progress {
    direction: rtl;
  }
  [view-mode="rtl"] .register-form .password-score {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  [view-mode="rtl"] #password-recommendation-heading {
    text-align: right !important;
  }
  [view-mode="rtl"] .password-recommendation {
    text-align: right;
  }
  [view-mode="rtl"] .otp-form input.form-control {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  [view-mode="rtl"] .otp-form .nice-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  [view-mode="rtl"] .otp-verify-form .form-control {
    text-align: center !important;
  }
  [view-mode="rtl"] .user-info-card {
    direction: rtl;
  }
  [view-mode="rtl"] .blog-card .post-content {
    direction: rtl;
  }
  [view-mode="rtl"] .blog-card .post-content .post-meta a i,
  [view-mode="rtl"] .blog-card .post-content .post-meta span i {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"] .blog-card .post-bookmark {
    right: 0;
    left: 0.5rem;
  }
  [view-mode="rtl"] .blog-catagory-card {
    text-align: right;
  }
  [view-mode="rtl"] .blog-card.list-card .read-more-btn {
    right: auto;
    left: 0.75rem;
  }
  [view-mode="rtl"] .blog-card.list-card .post-content {
    left: auto;
    right: 0.75rem;
  }
  [view-mode="rtl"] .blog-details-post-thumbnail .post-bookmark {
    left: auto;
    right: 0;
  }
  [view-mode="rtl"] .post-meta-data a img {
    margin-right: 0;
    margin-left: 0.25rem;
  }
  [view-mode="rtl"] .post-meta-data a span {
    margin-left: 0;
    margin-right: 0.5rem;
  }
  [view-mode="rtl"] .agent-message-content {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  [view-mode="rtl"] .agent-message-content .agent-message-text {
    text-align: right;
  }
  [view-mode="rtl"] .user-message-content {
    text-align: left;
  }
  [view-mode="rtl"] .user-message-content .user-message-text {
    padding-left: 0;
    padding-right: 3rem;
  }
  [view-mode="rtl"] .type-text-form button[type="submit"] {
    right: auto;
    left: 0.5rem;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  [view-mode="rtl"] .type-text-form .file-upload {
    left: auto;
    right: 0.5rem;
  }
  [view-mode="rtl"] .type-text-form .form-control {
    padding-left: 0.5rem;
    padding-right: 2.5rem;
  }
  [view-mode="rtl"] .notification-area .list-group-item {
    direction: rtl;
  }
  [view-mode="rtl"] .notification-area .list-group-item .noti-icon {
    margin-right: 0;
    margin-left: 0.75rem;
  }
  [view-mode="rtl"] .contact-form .nice-select {
    text-align: right !important;
  }
  [view-mode="rtl"] .contact-form .nice-select .option {
    text-align: right;
  }
  [view-mode="rtl"] .offline-text {
    direction: rtl;
  }
  [view-mode="rtl"] .privacy-policy-wrapper {
    direction: rtl;
  }
  [view-mode="rtl"] .single-settings {
    direction: rtl;
  }
  [view-mode="rtl"] .single-settings .title i {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  [view-mode="rtl"] .single-settings .data-content i {
    margin-left: 0;
    margin-right: 0.25rem;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [view-mode="rtl"] .accordian-title {
    text-align: right;
  }
  [view-mode="rtl"] .accordian-card .accordian-header button span i {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  [view-mode="rtl"] .accordian-card .accordian-header button.collapsed > i {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  [view-mode="rtl"] .accordian-card p {
    padding-left: 0;
    padding-right: 35px;
    text-align: right;
  }
  [view-mode="rtl"] .preview-iframe-wrapper .preview-hero-area .alert {
    direction: rtl;
  }
  [view-mode="rtl"] .preview-content-wrapper {
    direction: rtl;
  }
  [view-mode="rtl"] .preview-iframe-wrapper .features-area ul li {
    padding-left: 0;
    padding-right: 1.5rem;
    direction: rtl;
    text-align: right;
  }
  [view-mode="rtl"] .preview-iframe-wrapper .features-area ul li::before {
    left: auto;
    right: 0;
  }
  [view-mode="rtl"] .preview-footer-area {
    direction: rtl;
  }
  [view-mode="rtl"] .preview-footer-area .footer-nav a {
    margin-left: 0;
    margin-right: 1rem;
  }
  [view-mode="rtl"]
    .preview-iframe-wrapper
    .preview-hero-area
    .live-preview-btn {
    text-align: right;
  }
  [view-mode="rtl"] .preview-iframe-wrapper .qr-code-wrapper {
    margin-left: auto;
  }
  .logo_comman {
    background-color: #fff;
    display: inline-block;
    border-radius: 10px;
    padding: 12px 12px;
  }
  .logo-wrapper img {
    max-width: 36px;
  }
  .cart_icon {
    height: 75px;
    width: 75px;
    border: 1px solid #f2f2f2;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-info p,
  .user-info h5 {
    color: #fff !important;
  }

  .single-product-slide {
    height: 370px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flavour_box a {
    background: #f2f2f2;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: #3d3d3d;
    margin: 0 4px 7px 0;
    transition: 0.5s all;
  }
  .flavour_box .disabled {
    opacity: 0.5;
  }
  .p-specification p {
    font-size: 14px;
  }
  .p-specification li {
    font-size: 14px;
  }
  .rating-comment p {
    font-size: 14px;
  }
  .brands_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    background-color: #fff;
    border-radius: 6px;
  }
  .brands_box img {
    height: 70px;
  }
  .privacy-policy-wrapper p {
    font-size: 14px;
  }
  .noti-info h6 {
    font-size: 14px;
  }
  .notification-area .list-group-item p {
    margin-bottom: 0.5rem;
    font-size: 14px;
  }
  .thankyou_text h2 {
    font-weight: 800;
    font-size: 30px;
    color: #fff;
  }
  .thankyou_text p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
  }
  .my_order_new {
    padding: 60px 0 80px;
  }
  .my_orderbox {
    border: 3px solid #f2f2f2;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 10px 15px;
    color: #3d3d3d !important;
    display: block;
  }
  .order_id {
    font-weight: 500;
    font-size: 14px;
  }
  .status_order {
    position: absolute;
    right: 3px;
    background-color: #e9eeff;
    font-size: 13px;
    font-weight: 500;
    padding: 4px 10px;
    top: 3px;
    border-radius: 7px;
  }
  .date_box {
    font-weight: 600;
    font-size: 14px;
  }
  .items_box {
    background-color: #e9eeff;
    margin-top: 16px;
    padding: 12px 14px;
    border-radius: 5px;
  }
  .items_box h2 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .items_box ul {
    padding-left: 20px;
  }
  .items_box ul li {
    font-size: 14px;
    font-weight: 500;
    list-style: circle;
    padding-left: 0;
  }

  .choose_file label {
    position: absolute;
    top: 22px !important;
    background-color: #eb3237;
    height: 34px;
    color: #fff;
    display: flex !important;
    align-items: center;
    width: 112px;
    justify-content: center;
    text-align: center;
    border-radius: 4px !important;
    font-size: 12px !important;
    cursor: pointer;
  }
  .small_header {
    font-weight: 800;
    font-size: 15px;
    color: #3e4093;
    margin-bottom: 8px;
  }
  .data_main,
  .data_submain {
    font-size: 14px;
    display: block;
    font-weight: 500;
    color: #3d3d3d;
  }
  .data_submain {
    font-weight: 600;
  }
  .bar_code {
    font-size: 12px;
    font-weight: 500;
    color: #3d3d3d;
  }
  a {
    text-decoration: none !important;
  }
  .form-error {
    color: #eb3237 !important;
    font-size: 14px;
    text-align: start;
  }
  .form-control:focus {
    box-shadow: none !important;
    color: #fff !important;
  }
  .form-control2:focus {
    color: #000 !important;
  }
  .form-select {
    font-size: 14px !important;
  }
  .Toastify__toast--error {
    border: 2px solid #eb5757;
    border-radius: 50px !important;
    background: #fae1e2 !important;
  }
  .Toastify__toast--error::after {
    position: absolute;
    color: #333333;
    font-size: 5px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }
  .Toastify__toast--error::before {
    font-weight: 900 !important;
    font-family: "Font Awesome 5 Free"; /* This is the correct font-family*/
    font-size: 25px;
    color: #eb5757 !important;
    position: relative;
    z-index: 100000;
    left: 12px;
  }
  .Toastify__toast--success {
    border: 2px solid #07bc0c !important;
    border-radius: 50px !important;
    background: #f0f9fa !important;
  }
  .Toastify__toast--success::before {
    font-weight: 900 !important;
    font-family: "Font Awesome 5 Free"; /* This is the correct font-family*/
    font-size: 25px;
    color: #07bc0c !important;
    position: relative;
    z-index: 100000;
    left: 12px;
  }
  .Toastify__toast--success::after {
    position: absolute;
    color: #333333;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }
  .Toastify__toast--warning {
    border: 1px solid #e78326 !important;
    border-radius: 50px !important;
    background: #fadfc5 !important;
  }
  .Toastify__toast--warning::before {
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
  }
  .Toastify__toast--warning::after {
    position: absolute;
    color: #e78326;
    font-size: 15px;
    font-weight: 700;
    left: 265px;
    padding-top: 14px !important;
  }
  .Toastify__toast-body {
    color: #444c63;
    font-size: 15px;
    padding-left: 20px;
    padding: 7px !important;
    width: 100%;
    font-weight: 400;
    margin-left: 25px !important;
    padding-top: 25px;
  }
  .Toastify__toast > button > svg {
    display: block;
    margin-top: 10px;
    margin-right: 10px;
  }
  .Toastify__toast {
    position: relative;
    min-height: 0px !important;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 7px !important;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
  }
  .Toastify__toast-body::first-letter {
    text-transform: capitalize !important;
  }
  .Toastify__toast-body::first-line {
    text-transform: lowecase !important;
  }

  .cardTp {
    --font-color: #323232;
    --font-color-sub: #666;
    --bg-color: #fff;
    --main-color: #323232;
    --main-focus: #2d8cf0;
    /* width: 230px; */
    max-height: 16rem !important;
    background: var(--bg-color);
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    gap: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .cardTp:last-child {
    justify-content: flex-end;
  }

  .cardTp-img {
    /* clear and add new css */
    transition: all 0.5s;
    display: flex;
    justify-content: center;
  }

  .cardTp-img .img {
    /* delete */
    transform: scale(1);
    position: relative;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    background-color: #228b22;
    background-image: linear-gradient(
      to top,
      transparent 10px,
      rgba(0, 0, 0, 0.3) 10px,
      rgba(0, 0, 0, 0.3) 13px,
      transparent 13px
    );
  }

  .cardTp-img .img::before {
    /* delete */
    content: "";
    position: absolute;
    width: 65px;
    height: 110px;
    margin-left: -32.5px;
    left: 50%;
    bottom: -4px;
    background-repeat: no-repeat;
    background-image: radial-gradient(
        ellipse at center,
        rgba(0, 0, 0, 0.7) 30%,
        transparent 30%
      ),
      linear-gradient(
        to top,
        transparent 17px,
        rgba(0, 0, 0, 0.3) 17px,
        rgba(0, 0, 0, 0.3) 20px,
        transparent 20px
      ),
      linear-gradient(to right, black 2px, transparent 2px),
      linear-gradient(to left, black 2px, transparent 2px),
      linear-gradient(to top, black 2px, #228b22 2px);
    background-size: 60% 10%, 100% 100%, 100% 65%, 100% 65%, 100% 50%;
    background-position: center 3px, center bottom, center bottom, center bottom,
      center bottom;
    border-radius: 0 0 4px 4px;
    z-index: 2;
  }

  .cardTp-img .img::after {
    /* delete */
    content: "";
    position: absolute;
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    margin-left: -14px;
    left: 50%;
    top: -13px;
    background-repeat: no-repeat;
    background-image: linear-gradient(80deg, #ffc0cb 45%, transparent 45%),
      linear-gradient(-175deg, #ffc0cb 45%, transparent 45%),
      linear-gradient(80deg, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 51%),
      linear-gradient(-175deg, rgba(0, 0, 0, 0.2) 51%, rgba(0, 0, 0, 0) 51%),
      radial-gradient(
        circle at center,
        #ffa6b6 45%,
        rgba(0, 0, 0, 0.2) 45%,
        rgba(0, 0, 0, 0.2) 52%,
        rgba(0, 0, 0, 0) 52%
      ),
      linear-gradient(
        45deg,
        rgba(0, 0, 0, 0) 48%,
        rgba(0, 0, 0, 0.2) 48%,
        rgba(0, 0, 0, 0.2) 52%,
        rgba(0, 0, 0, 0) 52%
      ),
      linear-gradient(
        65deg,
        rgba(0, 0, 0, 0) 48%,
        rgba(0, 0, 0, 0.2) 48%,
        rgba(0, 0, 0, 0.2) 52%,
        rgba(0, 0, 0, 0) 52%
      ),
      linear-gradient(
        22deg,
        rgba(0, 0, 0, 0) 48%,
        rgba(0, 0, 0, 0.2) 48%,
        rgba(0, 0, 0, 0.2) 54%,
        rgba(0, 0, 0, 0) 54%
      );
    background-size: 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%,
      100% 75%, 100% 95%, 100% 60%;
    background-position: center center;
    border-top-left-radius: 120px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 70px;
    transform: rotate(45deg);
    z-index: 1;
  }

  .cardTp-title {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    color: var(--font-color);
  }

  .cardTp-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: var(--font-color-sub);
  }

  .cardTp-divider {
    width: 100%;
    margin-top: 0 !important;
    border: 1px solid var(--main-color);
  }

  .cardTp-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
  }

  .cardTp-price {
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color);
  }

  .cardTp-price span {
    font-size: 20px;
    font-weight: 500;
    color: var(--font-color-sub);
  }

  .cardTp-btn {
    height: 30px !important;
    background: var(--bg-color);
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 0 15px;
    transition: all 0.3s;
  }

  .cardTp-btn svg {
    width: 100%;
    height: 100%;
    fill: var(--main-color);
    transition: all 0.3s;
  }

  .cardTp-img:hover {
    transform: translateY(-3px);
  }

  .cardTp-btn:hover {
    border: 2px solid var(--main-focus);
  }

  .cardTp-btn:hover svg {
    fill: var(--main-focus);
  }

  .cardTp-btn:active {
    transform: translateY(3px);
  }

  @-webkit-keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loader-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loader {
    position: absolute;
    margin: -18px 0 0 -18px;
    border: 3.6px solid #ff974d;
    box-sizing: border-box;
    overflow: hidden;
    width: 36px;
    height: 36px;
    left: 50%;
    top: 50%;
    animation: loader-spin 2s linear infinite reverse;
    filter: url(#goo);
    box-shadow: 0 0 0 1px #ff974d inset;
  }
  .loader:before {
    content: "";
    position: absolute;
    -webkit-animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69)
      infinite;
    animation: loader-spin 2s cubic-bezier(0.59, 0.25, 0.4, 0.69) infinite;
    background: #ff974d;
    transform-origin: top center;
    border-radius: 50%;
    width: 150%;
    height: 150%;
    top: 50%;
    left: -12.5%;
  }
  .custom_loader {
    height: 100vh;
    width: 100%;
    position: absolute;

    z-index: 99999999;
  }
  .modal-backdrop {
    /* background-color: rgba(0, 0, 0, 0.5) !important; */
  }

  .d-none {
    display: none;
  }
  .banner_text {
    font-size: 18px;
    width: 200px;
    line-height: 25px;
  }
  .form-group span strong {
    color: #eb3237;
  }

  .swal2-icon.swal2-error {
    border-color: #eb3237 !important;
    color: #eb3237 !important;
    font-size: 10px !important;
  }
  .swal2-styled.swal2-confirm {
    background-color: #eb3237 !important;
  }
  .swal2-html-container {
    font-size: 1em !important;
  }
  .swal2-title {
    font-size: 1.25em !important;
  }
  .swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
  }
  .swal2-icon.swal2-success {
    border-color: #3e4093 !important;
    color: #3e4093 !important;
    font-size: 10px !important;
  }
  .swal2-icon.swal2-success .swal2-success-ring {
    border: 0.25em solid #3e4093 !important;
  }
  .swal2-icon.swal2-success [class^="swal2-success-line"] {
    background-color: #3e4093 !important;
  }
  .error-bottom {
    border-bottom: 1px solid #eb3237 !important;
  }

  voice_search .modal-content {
    border: 0;
    border-radius: 4px;
  }
  .voice_search_ouuter {
    position: relative;
  }
  .voice_search_ouuter .btn-close {
    position: absolute;
    right: 2px;
    top: -8px;
    font-size: 14px;
  }
  .voice_box {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 0;
  }
  .voice_box p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .voice_box {
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 14px 0;
  }
  .voice_box_btn a {
    height: 54px;
    margin: 0 auto;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #000;
    font-size: 22px;
  }
  .voice_search .modal-dialog {
    margin: 0 auto;
    max-width: 90%;
  }
  .voice_search {
    padding: 0 !important;
  }
  .didnt_hear a {
    background-color: #f2f2f2;
  }
  .hear a {
    background-color: #3e4093;
    color: #fff;
    position: relative;
  }
  .hear a::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: #3e4093 solid 2px;
    border-radius: 100%;
    animation: ringPulse 2s infinite linear;
  }
  @keyframes ringPulse {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }
    10% {
      opacity: 1;
    }
    80%,
    100% {
      opacity: 0;
      transform: scale(1.7);
    }
  }
  .no-data {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 65%;
    margin-top: 130px;
  }
  .custom_select_design select {
    height: 37px;
    font-size: 13px;
    font-weight: 600;
    border: 0;
    background: #fff;
    color: #3e4093;
    border-radius: 4px;
    padding: 0 34px 0 12px;
    outline: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    width: 100%;
    -webkit-appearance: none;
    position: relative;
    /* background-image: url(img/arrow_down.png); */
    background-size: 9px;
    background-repeat: no-repeat;
    background-position: 90% center;
  }
  .product-card .product-title {
    font-size: 14px;
    margin-bottom: 0;
    color: #020310;
    line-height: 20px;
  }
  .cart_bttn {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    background-color: #3e4093;
    border-radius: 50px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    color: #fff;
  }
}
a:hover {
  opacity: 1;
}

#container {
  width: 160px;
  height: 36px;
  margin: auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
}

.inner-container {
  position: absolute;
  left: 0;
  top: 0;
  width: inherit;
  height: inherit;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 0.2em;
}

.inner-container:first-child {
  background: #e9e9e9;
  color: #a9a9a9;
}

.inner-container:nth-child(2) {
  background: dodgerblue;
  color: white;
  clip-path: inset(0 50% 0 0);
  transition: 0.3s cubic-bezier(0, 0, 0, 1);
}

.toggle {
  width: 50%;
  position: absolute;
  height: inherit;
  display: flex;
  box-sizing: border-box;
}

.toggle p {
  margin: auto;
  font-size: 11px !important;
}

.toggle:nth-child(1) {
  right: 0;
}
.swiper-button-prev {
  color: #eb3237 !important;
}

.swiper-button-next {
  color: #eb3237 !important;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

/* loader */

.loading-screen {
  margin-top: 12rem;
  justify-content: center;
  display: flex;
}

.centered {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  filter: blur(10px) contrast(20);
}
.blob-1,
.blob-2 {
  width: 70px;
  height: 70px;
  position: absolute;
  background: #3e4093;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blob-1 {
  left: 20%;
  animation: osc-l 2.5s ease infinite;
}
.blob-2 {
  left: 80%;
  animation: osc-r 2.5s ease infinite;
  background: #eb3237;
}
@keyframes osc-l {
  0% {
    left: 20%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 20%;
  }
}
@keyframes osc-r {
  0% {
    left: 80%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 80%;
  }
}
.quanityField {
  display: inline-flex;
  align-items: center;
  width: 5rem !important;
  position: relative;
  top: 2px;
  justify-content: center !important;
  height: 2.5rem !important;
  padding: 8px;
  border-radius: 10px;
  font-size: 13px;
  margin: 5px !important;
}

.quanityField:focus {
  border: 2px solid #eb3237 !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cart-quantity-input {
  display: block;
  width: 50% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  margin: 5px;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.frame-container {
  position: relative;
  padding-bottom: 43%; /* 16:9 */
  width: 600%; /* enlarge beyond browser width */
  height: 70%;
  left: -250%; /* center */
}

.video_slide {
  max-width: 100%;
  height: 710px !important;
  width: 1500px !important;
  object-fit: cover;
}

.features_products {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.product_show_home {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 500px;
}

.product_show_home_Gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-size: cover !important;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: soft-light;
}

.forground_img {
  background-color: #000;
  width: 33rem !important;
  padding: 2rem;
  border-radius: 1.5rem !important;
  opacity: 80%;
}
.recent_orders_new {
  overflow-y: scroll !important;
  max-height: 42rem !important;
}
.recent_orders_new::-webkit-scrollbar {
  color: #083afb;
}
.recent_orders_invent {
  overflow-y: scroll !important;
  max-height: 32rem !important;
  padding: 0 !important;
}
.recent_orders_cate {
  overflow-y: scroll !important;
  scroll-behavior: smooth;
  max-height: 40rem !important;
  padding: 0 !important;
}

.recent_orders_order {
  overflow-y: scroll !important;
  max-height: 30rem !important;
  padding: 0 !important;
}
.plus:hover {
  background-color: #3e4093;
  color: #fff;
}
.minus:hover {
  background-color: #3e4093;
  color: #fff;
}
.load_position {
  position: absolute;
  margin: -18px 0 0 -18px;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
}
.loader_new {
  animation: rotate 1.6s infinite;
  height: 50px;
  width: 50px;
}

.loader_new:before,
.loader_new:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader_new:before {
  animation: ball1 1s infinite;
  background-color: #3e4093;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}

.loader_new:after {
  animation: ball2 1s infinite;
  background-color: #eb3237;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

/* loaders and galler
 */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;

  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  z-index: 1002;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;

  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;

  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #222222;
  z-index: 1001;
  -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0); /* IE 9 */
  transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%); /* IE 9 */
  transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%); /* IE 9 */
  transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

p {
  line-height: 1.8;
}

a {
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: #009999;
}
.container-fluid {
  max-width: 1770px;
}
.tm-container-small {
  max-width: 1050px;
}
.btn {
  border-radius: 0;
}
.btn-primary {
  background-color: #009999;
  border: 0;
  border-radius: 5px;
  padding: 12px 50px 14px;
  font-size: 1.2rem;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #086969;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #009999;
  pointer-events: all;
  cursor: not-allowed;
}

.form-control {
  padding: 10px 20px;
  width: 100%;
  height: auto;
}

.form-group {
  margin-bottom: 25px;
}
.tm-btn-big {
  padding: 12px 90px 14px;
}

.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-brand {
  color: #3399cc;
  font-size: 1.6rem;
}

.navbar-brand i {
  font-size: 2rem;
}

.tm-hero {
  min-height: 200px;
  background: transparent;
}

.tm-mt-60 {
  margin-top: 60px;
}
.tm-mb-50 {
  margin-bottom: 50px;
}
.tm-mb-74 {
  margin-bottom: 74px;
}
.tm-mb-90 {
  margin-bottom: 90px;
}
.tm-text-primary {
  color: #009999;
}
.tm-text-secondary {
  color: #cc6699;
}

.tm-bg-gray {
  background-color: #eeeeee;
}
.tm-input-paging {
  width: 40px;
  border-radius: 0;
  border: 1px solid #cccccc;
  background: #f4f4f4;
  text-align: center;
}

.nav-item {
  margin-right: 30px;
}
.nav-item:last-child {
  margin-right: 0;
}

.nav-link {
  color: #666666;
  border-bottom: 4px solid transparent;
  font-size: 1.2rem;
}

.nav-link-1.active,
.nav-link-1:hover {
  border-color: #33ccff;
}

.nav-link-2.active,
.nav-link-2:hover {
  border-color: #ff6666;
}

.nav-link-3.active,
.nav-link-3:hover {
  border-color: #33cc66;
}

.nav-link-4.active,
.nav-link-4:hover {
  border-color: #cc66cc;
}

.tm-search-input {
  width: 360px;
  border-radius: 0;
  padding: 12px 15px;
  color: #009999;
  border: none;
}

.tm-search-input:focus {
  border-color: #009999;
  box-shadow: 0 0 0 0.25rem rgb(0 153 153 / 0.25);
}

.tm-search-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #009999;
  opacity: 1; /* Firefox */
}

.tm-search-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #009999;
}

.tm-search-btn {
  color: white;
  background-color: #009999;
  border: none;
  width: 100px;
  height: 50px;
  margin-left: -1px;
}

p,
.tm-text-gray {
  color: #999;
}
.tm-text-gray-light {
  color: #ccc;
}
.tm-text-gray-dark {
  color: #666;
}

.tm-video-item {
  position: relative;
  overflow: hidden;
  text-align: center !important;
  cursor: pointer;
}

.tm-video-item img {
  position: relative;
  display: block;
  max-height: 18rem;
  min-height: 18rem;
  min-width: 100%;
  max-width: 14rem;
  opacity: 0.8;
}

.tm-video-item figcaption {
  padding: 2em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.tm-video-item figcaption::before,
.tm-video-item figcaption::after {
  pointer-events: none;
}

.tm-video-item figcaption,
.tm-video-item figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tm-video-item figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.tm-video-item h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.tm-video-item h2,
.tm-video-item p {
  margin: 0;
}

.tm-video-item p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

.tm-gallery div.d-block {
  animation: show 0.5s ease;
}

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.tm-paging-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 1.1rem;
  color: #eb3237;
  background-color: #eeeeee;
  margin: 10px;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.tm-paging-link:hover,
.tm-paging-link.active {
  background-color: #eb3237;
  color: #fff;
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect-ming {
  background: #030c17;
}

figure.effect-ming img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
}

figure.effect-ming h2 {
  font-size: 1.3em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover h2 {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure.effect-ming:hover figcaption {
  background-color: rgba(58, 52, 42, 0);
}
figure.effect-ming:hover img {
  opacity: 0.4;
}

.tm-footer-links li {
  list-style: none;
  margin-bottom: 5px;
}

.tm-footer-links li a {
  color: #999999;
}
.tm-footer-links li a:hover {
  color: #009999;
}

.tm-social-links li {
  list-style: none;
  margin-right: 15px;
}

.tm-social-links li:last-child {
  margin-right: 0;
}

.tm-social-links li a {
  color: #999999;
  width: 44px;
  height: 44px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.tm-social-links li a:hover {
  color: #fff;
  background-color: #009999;
}

.tm-footer {
  font-size: 0.95rem;
}
.tm-footer-title {
  font-size: 1.4rem;
}

/* Videos */
#tm-video-container {
  max-height: 400px;
  overflow: hidden;
  background-color: #333;
  margin-bottom: 90px;
  position: relative;
}

#tm-video {
  display: block;
  width: 100%;
  height: auto;
}

#tm-video-control-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  color: #e1e1e1;
}

.tm-video-details {
  height: 100%;
  padding: 40px;
}

/* About */
.tm-row-1640 {
  max-width: 1640px;
}
.tm-about-2-col {
  max-width: 716px;
}
.tm-about-3-col {
  max-width: 540px;
}

.tm-about-2-col,
.tm-about-3-col {
  margin-bottom: 50px;
}

.tm-about-icon-container {
  width: 150px;
  height: 150px;
  border: 1px solid #009999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tm-about-img-text {
  max-width: 940px;
}

/* Contact */

.mapouter {
  position: relative;
  height: 480px;
  width: 100%;
  max-width: 480px;
}

.gmap-canvas {
  overflow: hidden;
  background: none !important;
  height: 480px;
  width: 100%;
}

.tm-contact-form {
  max-width: 420px;
}
.tm-address-col {
  max-width: 520px;
}
.tm-contacts {
  padding-left: 0;
}

.tm-contacts li {
  list-style: none;
  margin-bottom: 20px;
}

.tm-contacts li a i {
  width: 30px;
}
.tm-social {
  display: flex;
}

.tm-social li {
  list-style: none;
  margin-bottom: 20px;
  margin-right: 15px;
}

.tm-social li a i {
  width: 40px;
  height: 40px;
  color: #666666;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.tm-social li a:hover i {
  color: #fff;
  background-color: #009999;
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 50px;
    right: 0px;
    background: white;
    width: 150px;
    padding: 15px;
  }

  .tm-container-content {
    max-width: 870px;
  }
  .tm-contact-form,
  .tm-address-col,
  .mapouter {
    max-width: 100%;
  }
  .tm-people-row {
    max-width: 900px;
  }
}

@media (max-width: 767px) {
  .tm-paging-col {
    flex-direction: column;
  }
  .tm-paging {
    flex-wrap: wrap;
  }
  .tm-about-img-text {
    max-width: 640px;
  }
  .tm-about-2-col,
  .tm-about-3-col {
    margin-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .tm-container-content {
    max-width: 420px;
  }

  .tm-search-form {
    padding-left: 15px;
    padding-right: 15px;
    width: 50% !important;
    justify-content: center;
  }

  .tm-search-input {
    width: 100%;
    max-width: 360px;
  }

  .tm-people-row {
    max-width: 420px;
  }
}

@media (max-width: 400px) {
  .tm-btn-big {
    padding: 12px 50px 14px;
  }
}

@media (max-width: 334px) {
  .tm-social-links {
    flex-wrap: wrap;
    justify-content: start !important;
  }

  .tm-social-links li {
    margin-right: 3px;
  }
}

.main-button a {
  font-size: 15px;
  color: #fff;
  background-color: #00bdfe;
  border: 1px solid #00bdfe;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.main-button a:hover {
  background-color: transparent;
  color: #00bdfe;
}

.section-heading {
  position: relative;
  z-index: 2;
  margin-top: 0px;
  margin-bottom: 70px;
}

.section-heading h6 {
  font-size: 15px;
  text-transform: uppercase;
  color: #00bdfe;
  font-weight: 400;
}

.section-heading h4 {
  margin-top: 10px;
  line-height: 36px;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  color: #212741;
}

.section-heading h4 em {
  color: #00bdfe;
  font-style: normal;
}

.section-heading p {
  margin-top: 30px;
}

.main-banner {
  background-image: url(../img/banner_img.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 290px;
  padding-bottom: 240px;
  height: 660px;
  text-align: center;
  background-color: #2a2a2a;
}

.main-banner h2 {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: 74px;
}

.main-banner h2 em {
  font-style: normal;
  color: #00bdfe;
}

.main-banner p {
  color: #fff;
  padding: 30px 0px;
  margin: 40px 75px 0px 75px;
  border-top: 1px solid rgba(250, 250, 250, 0.2);
}

.main-banner .buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.main-banner .big-border-button a {
  font-size: 20px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 15px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all 0.3s;
}

.main-banner .big-border-button a:hover {
  background-color: #fff;
  color: #00bdfe;
}

.main-banner .icon-button a {
  margin-top: 4px !important;
  display: inline-block;
  margin-left: 15px;
  font-size: 15px;
  text-decoration: none !important;
  font-weight: 500;
  color: #fff;
  transition: all 0.3s;
}

.main-banner .icon-button a i {
  margin-right: 6px;
  font-size: 16px;
}

.main-banner .icon-button a:hover {
  color: #00bdfe;
}

.container_swipe {
  position: relative;
  width: 24px;
  height: 24px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}
.Collect_header {
  color: #3e4093;
  font-size: 40px;
  font-weight: 700;
  line-height: 74px;
  text-underline-offset: 2px;
  opacity: 0;
}

/* 
 */
.category_newdesign,
.brandsnew {
  padding: 80px 0;
  background-color: #f5f5f5;
}
.newdesign_main {
  border-radius: 10px;
  box-shadow: 0 10px 10px #0000000f;
  padding: 60px 100px 60px;
}
.newdesign_main a {
  text-decoration: none;
}
.comn_heads h2 {
  text-align: center;
  margin: 0;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  font-size: 26px;
}
.categorynew_img {
  text-align: center;
  border: 1px solid #a9a9a9b0;
  border-radius: 10px;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
}
.categorynew_img img {
  max-width: 100%;
  width: auto !important;
  max-height: 120px !important;
}

.categorynew_box span {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: block;
  text-decoration: none !important;
  color: #000;
}
.sliderbtns_design .owl-nav .owl-next {
  background-image: url(../img/arrow.png) !important;
  text-indent: -1.000098098888889e33px;
  background-size: 33px !important;
  height: 22px;
  width: 38px;
  transform: rotate(-89deg);
  background-repeat: no-repeat !important;
  margin-right: -80px;
  margin-top: -20px;
}
.sliderbtns_design .owl-nav .owl-prev {
  background-image: url(../img/arrow.png) !important;
  text-indent: -1.000098098888889e33px;
  background-size: 33px !important;
  height: 22px;
  width: 38px;
  transform: rotate(-270deg);
  background-repeat: no-repeat !important;
  margin-left: -80px;
  margin-top: -10px;
}
.sliderbtns_design .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.view_all {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: #000;
  text-decoration: none !important;
  font-size: 15px;
  margin-bottom: -34px;
  position: relative;
  cursor: pointer;
}
.featuredproduct {
  padding: 80px 0;
  background-color: #fff;
}
.featuredproduct_box {
  text-align: center;
  border: 1px solid #a9a9a9b0;
  text-decoration: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  flex-wrap: wrap;
  max-height: 17rem;
}
.featuredproduct_details {
  width: 100%;
  padding: 25px 0;
  border-top: none !important;
}
.featuredproduct_img img {
  max-width: 100%;
  width: auto !important;
  max-height: 120px;
}
.featuredproduct_details > span {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  text-decoration: none !important;
  display: block;
}
.search_head2 {
  position: relative;
  top: 2px !important;
  font-size: 15px;
  font-weight: 700;
  color: #000;
  text-decoration: none !important;
}
.Newrating span {
  color: #ffd000;
  font-size: 11px;
  display: inline-block;
  margin: 0 1px;
}
.featuredproduct_img {
  padding: 30px 0;
}
.featuredproduct .featuredproduct_slider,
.brandsnew .featuredproduct_slider {
  padding: 0px 100px 0px;
}
.brandsnew_box {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 25px 15px;
  max-height: 8rem !important;
  min-height: 8rem !important;
}
.brandsnew_box img {
  max-width: 100%;
  width: auto !important;

  /* min-height: 110px; */
  max-height: 7rem !important;
}
.Newheader {
  padding: 20px 40px !important;
  height: 6.5rem !important;
}
.head_search {
  position: relative;
  top: 14px;
}
.head_cart {
  position: relative;
  top: 8px;
}
.header_newsearch .form-control {
  height: 2.8rem !important;
  border-radius: 35px !important;
  font-weight: 500;
  font-size: 12px;
  padding: 0 40px !important;
  background-color: #f5f5f5;
  border: 2px solid #3e4093;
  box-shadow: unset;
}
.search_ibtn {
  position: absolute;
  top: 50%;
  right: 30px;
  background-color: transparent;
  border: 0;
  outline: 0;
  transform: translateY(-50%);
}
.search_ibtn img {
  max-width: 30px !important;
}
.cart_header i {
  max-width: 40px;
  font-size: 1.5rem !important;
  color: #3e4093;
}
.cart_header a {
  text-decoration: none;
}
.cart_header {
  position: relative;
}
.cart_header span {
  background-color: #eb3237;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
  width: 18px;
  position: absolute;
  top: -12px;
  right: -6px;
}
.Loginb {
  border: 1px solid #3e4093;
  color: #3e4093 !important;
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 110px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.Signupb {
  background-color: #3e4093;
  border: 1px solid #3e4093;
  color: #fff !important;
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 110px;
  justify-content: center;
  align-items: center;
}

.SaveBtn {
  margin-top: 8px;
  background-color: #3e4093;
  border: 1px solid #3e4093;
  position: relative;
  color: #fff !important;
  font-weight: 400;
  font-size: 10px;
  padding: 6px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 60px !important;
  justify-content: center;
  align-items: center;
}

.recent_orders_user {
  overflow-y: scroll;
  max-height: 32rem !important;
  overflow-x: hidden;
}
.swal-button {
  background-color: #eb3237;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}
.swal-button:hover {
  background-color: #3e4093;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 24px;
  margin: 0;
  cursor: pointer;
}

/* 
 */
.fixed-head {
  overflow-y: scroll;
  max-height: 65vh !important;
}
.fixed-head::-webkit-scrollbar {
  width: 6px !important;
  margin-left: 2px !important;
}
.fixed-head::-webkit-scrollbar-track {
  /* background-color: #3e4093; */
  border-radius: 50px;
}
.fixed-head::-webkit-scrollbar-thumb {
  background-color: #3e4093 !important;
  border-radius: 50px;
  cursor: pointer !important;
}
.checkbox-labels {
  position: relative;
  top: 0px;
  display: block;
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
}

.checkbox-label {
  position: relative;
  top: 10px;
  display: block;
  width: 24px;
  height: 24px;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: #fff;
  border: 2px solid #ccc;
}

.checkbox-input:checked ~ .checkmark {
  background-color: #3e4093;
  color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-input:checked ~ .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 10px;
  color: #fff !important;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-label-all {
  position: relative;
  top: 8px;
  right: 10px;
  width: 100% !important;
  height: 18px !important;
}
.select-text {
  position: relative;
  left: 28px;
  font-size: 1.2rem;
}
.checkbox-input-all {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark-all {
  position: absolute;
  top: 0px;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: #fff;
  border: 2px solid #ccc;
}

.checkbox-input-all:checked ~ .checkmark-all {
  background-color: #3e4093;
  color: #fff;
}

.checkmark-all:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-input-all:checked ~ .checkmark-all:after {
  display: block;
}

.checkmark-all:after {
  left: 9px;
  top: 4px;
  width: 5px;
  height: 10px;
  color: #fff !important;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-in {
  left: 9px;
  top: 4px;
  width: 1rem;
  height: 1rem;
  color: #fff !important;
  border: solid #fff;
  border-width: 0 2px 2px 0;
}
.nine h1 {
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  color: #222;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}
.nine h1 span {
  margin-top: 5px;
  font-size: 15px;
  color: #444;
  word-spacing: 1px;
  font-weight: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 500;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}

.nine h1 span:after,
.nine h1 span:before {
  content: " ";
  display: block;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  height: 5px;
  background-color: #f8f8f8;
}

.bhRnqO {
  top: calc(50% - 30px) !important;
}
.kVtMtA {
  top: calc(50% - 30px) !important;
}

/* new header css */
.category_newdesign,
.brandsnew {
  padding: 80px 0;
  background-color: #f6f7fa;
}
.newdesign_main {
  border-radius: 10px;
  box-shadow: 0 10px 10px #0000000f;
  padding: 60px 100px 60px;
}
.comn_heads h2 {
  text-align: center;
  margin: 0;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  font-size: 26px;
}
.categorynew_img {
  text-align: center;
  border: 1px solid #a9a9a9b0;
  border-radius: 10px;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
}
.categorynew_img img {
  max-width: 100%;
  width: auto !important;
  max-height: 120px !important;
}
.categorynew_box span {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none !important;
  text-align: center;
  display: block;
  color: #000;
  cursor: pointer;
}
.sliderbtns_design .owl-nav .owl-next {
  background-image: url(../img/arrow.png) !important;
  text-indent: -1.000098098888889e33px;
  background-size: 33px !important;
  height: 22px;
  width: 38px;
  transform: rotate(-89deg);
  background-repeat: no-repeat !important;
  margin-right: -80px;
  margin-top: -20px;
}
.sliderbtns_design .owl-nav .owl-prev {
  background-image: url(../img/arrow.png) !important;
  text-indent: -1.000098098888889e33px;
  background-size: 33px !important;
  height: 22px;
  width: 38px;
  transform: rotate(-270deg);
  background-repeat: no-repeat !important;
  margin-left: -80px;
  margin-top: -10px;
}
.sliderbtns_design .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.view_all {
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  color: #000;
  font-size: 15px;
  margin-bottom: -34px;
  position: relative;
}

.featuredproduct {
  padding: 80px 0;
  background-color: #fff;
}
.featuredproduct_box {
  text-align: center;
  border: 1px solid #a9a9a9b0;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  flex-wrap: wrap;
}
.featuredproduct_details {
  width: 100%;
  padding: 25px 0;
}
.featuredproduct_img img {
  max-width: 100%;
  width: auto !important;
  max-height: 120px;
}
.featuredproduct_details > span {
  font-size: 15px;
  font-weight: 700;
  color: #000;
  display: block;
}
.Newrating span {
  color: #ffd000;
  font-size: 11px;
  display: inline-block;
  margin: 0 1px;
}
.featuredproduct_img {
  padding: 30px 0;
}
.featuredproduct .featuredproduct_slider,
.brandsnew .featuredproduct_slider {
  padding: 0px 100px 0px;
}
.brandsnew_box {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 50px 20px;
}
.brandsnew_box img {
  max-width: 100%;
  width: auto !important;
  max-height: 120px;
}
.Newheader {
  padding: 30px 0;
}
.header_newsearch .form-control {
  height: 56px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  padding: 0 40px;
  background-color: #f5f5f5;
  border: 1px solid #3e4093;
  box-shadow: unset;
}
.search_ibtn {
  position: absolute;
  top: 50%;
  right: 30px;
  background-color: transparent;
  border: 0;
  outline: 0;
  transform: translateY(-50%);
}
.search_ibtn img {
  max-width: 30px;
}
.cart_header img {
  max-width: 40px;
}
.cart_header {
  position: relative;
}
.cart_header span {
  background-color: #eb3237;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
  width: 18px;
  position: absolute;
  top: -7px;
  right: -6px;
}
.Loginb {
  border: 1px solid #3e4093;
  color: #3e4093 !important;
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 110px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.Signupb {
  background-color: #3e4093;
  border: 1px solid #3e4093;
  color: #fff !important;
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 110px;
  justify-content: center;
  align-items: center;
}

.header_menuss {
  display: flex;
  justify-content: center;
}
.header_menuss li > a {
  color: #fff;
  font-size: 12px !important;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  position: relative;
  transition: 0.3s;
  height: 35px !important;
  text-decoration: none;
  font-weight: 500;
}
.header_menuss li:hover > a {
  background-color: #fff;
  color: #3e4093;
  cursor: pointer;
}
.new_dropdown {
  z-index: 999 !important;
}
/* .new_dropdown_link::after {
  content: "";
  position: absolute;
  background-image: url("../img/arrow.png") !important;
  height: 12px;
  width: 12px;
  right: 18px;
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  transition: 0.3s all;
  top: 32px;
  filter: brightness(20.5);
} */
@keyframes list-anime {
  0% {
    opacity: 15%;
  }

  100% {
    opacity: 100%;
  }
}

.header_menuss li:hover > .new_dropdown_link::after {
  filter: unset;
  transform: rotate(180deg);
  transition: 2s ease-in !important;
}
.header_menuss li .new_dropdown_link {
  padding: 12px 15px 12px 15px;
}
.new_dropdown_inner {
  display: none;
}
.new_dropdown:hover .new_dropdown_inner {
  display: block;
  max-height: 240px;
  overflow-y: scroll !important;
}
.new_dropdown:hover .new_dropdown_inner::-webkit-scrollbar {
  width: 10px !important;
  margin-left: 2px !important;
  cursor: pointer !important;
}
.new_dropdown:hover .new_dropdown_inner::-webkit-scrollbar-track {
  /* background-color: #3e4093; */
  border-radius: 8px;
}
.new_dropdown:hover .new_dropdown_inner::-webkit-scrollbar-thumb {
  background-color: #3e4093 !important;
  border-bottom-right-radius: 30px;
}

.header_menuss li {
  list-style: none;
  padding: 0;
  position: relative;
}
.header_menuss {
  display: flex;
}
.new_dropdown:hover .new_dropdown_inner {
  display: block;
  position: absolute;
  top: 35px !important;
  background-color: #fff;
  width: 200px;
  border-radius: 0px 0px 10px 10px;
  z-index: 99999;
  overflow-x: hidden;
  box-shadow: 0 35px 30px #00000029;
  overflow-y: hidden;
  max-height: 500px !important;
  animation-name: list-anime;
  animation-duration: 0.7s;
}
.new_dropdown:hover .new_dropdown_inner a:last-child {
  border: 0;
}
.new_dropdown_inner a {
  display: block;
  padding: 14px 24px;
  font-weight: 400;
  color: #000;
  border-bottom: 1px solid #c1c1c12b;
  transition: 0.3s all;
  font-size: 12px;
  cursor: pointer;
}
.new_dropdown_inner a:hover {
  transform: translateX(8px);
  background-color: #3e4093 !important;
  color: #fff !important;
}

.header_menuss li:last-child:hover .new_dropdown_inner {
  right: 0;
}
.comman_divvision {
  background: #ffffff;
  box-shadow: 4px 4px 54px rgba(223, 229, 235, 0.58);
  border-radius: 10px;
  padding: 50px 90px;
}
.singleproduct_divvision {
  background: #ffffff;
  box-shadow: 4px 4px 54px rgba(223, 229, 235, 0.58);
  border-radius: 10px;
  padding: 20px 20px !important;
}
.comman_paddings {
  padding: 25px 0 25px !important;
}
.comman_padding {
  padding: 80px 0 !important;
}
.header-fixed .Newheader {
  padding: 14px 0;
}
.comman_product1 .categorynew_box {
  margin: 0 20px;
  display: block;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 20px;
}
.singleproduct-left {
  background-color: #f6f7fa;
  border-radius: 10px;
}
.singleproduct-left-box {
  padding: 22px 25px;
}
.singleproduct-form .custom_radio label::after {
  top: 0px;
  height: 22px;
  width: 22px;
  border: 1px solid #d7d7d7;
  border-radius: 50px;
  background-color: #fff;
  z-index: 0;
}
.singleproduct-form .custom_radio label {
  display: block;
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 500;
}
.singleproduct-form .custom_radio label::before {
  position: absolute;
  content: "";
  top: 4px;
  z-index: 1;
  left: 4px;
}

.singleproduct-left-box h2 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 25px;
}
.moreee {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.singleproduct--btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.singleproduct--btns a {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 46%;
  padding: 9px 12px;
  font-size: 13px;
  border: 1px solid #3e4093;
  border-radius: 4px;
  color: #3e4093 !important;
}
.singleproduct--btns a:last-child {
  background-color: #3e4093;
  color: #fff !important;
}
.singleproducttop---left {
  color: #3b438e;
  font-size: 16px;
  font-weight: 400;
}
.singleproducttop---left span {
  font-weight: 600;
  color: #000;
}
.singleproduct---paginationss span {
  font-weight: 600;
  color: #000;
  font-size: 16px;
  display: inline-block;
  margin: 0 10px;
}
.singleproduct---paginationss a {
  font-weight: 400;
  color: #3e4093;
  font-size: 16px;
}
.singleproduct---paginationss a:first-child img {
  transform: rotate(90deg);
  margin-top: -1px;
}
.singleproduct---paginationss a:last-child img {
  transform: rotate(-90deg);
  margin-top: -2px;
}

.singleproduct-box span {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: block;
  color: #000;
}
.singleproduct--img {
  text-align: center;
  border: 1px solid #a9a9a9b0;
  border-radius: 10px;
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.singleproduct-box {
  position: relative;
  margin: 0 12px !important;
}
.singleproduct--img img {
  max-width: 100%;
  width: auto !important;
  max-height: 110px;
}
.favvv---icon {
  position: absolute;
  top: 7px;
  right: 11px;
}
.prdct_singleneww {
  background: #f6f7fa;
  border-radius: 10px;
  position: relative;
  padding: 55px 70px;
}
.prdct_single_main .comman_divvision {
  padding: 70px 30px;
}
.prdct_singleshowimg img {
  max-width: 100%;
  max-height: 190px;
}
.prdct_singleshowimg {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 4px 4px 50px rgba(214, 223, 232, 0.4);
  border-radius: 5px;
  padding: 40px 30px;
  margin-bottom: 40px;
  max-height: 220px;
}

.prdct_singleneww ul li {
  width: 28% !important;
}
.prdct_singleneww ul li a {
  background: #ffffff;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 8rem !important;
  height: 90px;
}

/* .mySwiper{
  padding-right: ;
} */

.swiper-button-next {
  position: absolute;
  right: 0rem !important;
  z-index: 999 !important;
}
.swiper-button-prev {
  position: absolute;
  left: 0.5rem !important;
  z-index: 999 !important;
}
.prdct_singleneww ul li a img {
  max-width: 140px;
  max-height: 80px;
}

.prdct_comtenT h2 {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  margin-bottom: 16px;
}
.prdct---falvor {
  font-size: 14px;
  font-weight: 500;
}
.flavor_design {
  background: #ffffff;
  border: 2px solid #c0c0c0;
  border-radius: 5px;
  display: flex;
  color: #000000 !important;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  font-size: 14px;
  transition: 0.5s all;
}
.selected_flavor {
  box-shadow: 4px 4px 54px rgba(214, 223, 232, 0.42);
  border-color: #fff;
  color: #fff !important;
}
.selected_flavor:hover {
  box-shadow: 4px 4px 54px rgba(214, 223, 232, 0.42);
  border-color: #fff;
  color: #fff !important;
}

.flavor_design:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  border-radius: 100px;
  transition: all 0.1s;
  position: absolute;
}
.flavor_design:hover {
  border-color: #000;
  transform: translateY(-4px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.prdct_bottom .number {
  background: #3d438e;
  border-radius: 3px;
  display: inline-flex;
  border: 1px solid #3d438e;
  height: 41px;
  justify-content: center;
  align-items: center;
}
.prdct_bottom .minus,
.prdct_bottom .plus {
  border: 0;
  height: 39px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 25px;
  width: 38px;
}
.prdct_bottom input {
  height: 37px;
}
.cartt--btn {
  background-color: #3e4093;
  border: 1px solid #3e4093;
  color: #fff !important;
  font-weight: 400;
  font-size: 14px;
  padding: 9px 46px;
  display: inline-flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}
.falvor_main {
  max-height: 19rem;
  min-height: 15rem;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.falvor_main::-webkit-scrollbar {
  width: 6px !important;
  margin-left: 2px !important;
}
.falvor_main::-webkit-scrollbar-track {
  /* background-color: #3e4093; */
  border-radius: 50px;
}
.falvor_main::-webkit-scrollbar-thumb {
  background-color: #3e4093 !important;
  border-radius: 50px;
  cursor: pointer !important;
}
.SIMILAR_prrodct .categorynew_img {
  background-color: #fff;
  border: 0;
}
.describe_main p {
  color: #b6b6b6;
  font-size: 21px;
}

@media (max-width: 1199px) {
  .header_menuss li > a {
    font-size: 11px !important;
    padding: 8px 8px;
    transition: 0.3s;
    height: 42px;
  }
  .header_menuss li .new_dropdown_link {
    padding: 8px 18px 8px 8px !important;
  }
  .new_dropdown_link::after {
    height: 12px;
    width: 12px;
    right: 7px;
    background-size: 15px;
    top: 16px;
  }

  .comman_divvision {
    padding: 50px 50px;
  }
  .comman_product1 .categorynew_box {
    margin: 0 10px;
    display: block;
  }
  .new_dropdown:hover .new_dropdown_inner {
    top: 42px;
    width: 210px;
    max-height: 240px;
  }
  .new_dropdown_inner a {
    padding: 10px 20px;
    font-size: 13px;
  }
}

@media (max-width: 1023px) {
  .comman_padding {
    padding: 60px 0;
  }
  .singleproduct-box {
    margin: 0;
  }
  .comn_heads h2 {
    font-size: 22px;
  }
  .comman_divvision {
    padding: 40px 30px;
  }
  .Loginb,
  .Signupb {
    font-size: 13px;
    padding: 4px 11px;
    width: auto;
  }
  .Loginb {
    margin-right: 5px;
  }
  .header_newsearch .form-control {
    height: 42px;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 20px;
  }
  .search_ibtn {
    position: absolute;
    top: 45%;
    right: 10px;
  }
  .search_ibtn img {
    max-width: 18px;
  }
  .header_bottom .container {
    max-width: 100%;
  }
  .header_menuss li > a {
    font-size: 11px;
    padding: 12px 12px;
    height: 42px;
    line-height: 16px;
  }

  .singleproducttop---left,
  .singleproduct---paginationss a,
  .singleproduct---paginationss span {
    font-size: 14px;
  }
  .singleproduct---paginationss a img {
    max-width: 18px;
  }
  .singleproduct_divvision {
    padding: 30px 30px;
  }
  .singleproduct-left-box {
    padding: 19px 20px;
  }
  .singleproduct-left-box h2 {
    font-size: 17px;
    margin-bottom: 18px;
  }
  .singleproduct-form .custom_radio label {
    padding-left: 31px;
    font-size: 13px;
  }
  .singleproduct--btns a {
    width: 48%;
    padding: 9px 2px;
  }
  .prdct_single_main .comman_divvision {
    padding: 30px 10px;
  }
  .prdct_singleneww {
    padding: 30px 30px;
  }
  .prdct_singleshowimg {
    border-radius: 5px;
    padding: 20px 20px;
    margin-bottom: 30px;
    height: 200px;
  }
  .prdct_singleneww ul li a {
    height: 76px;
  }
  .prdct_singleneww ul li a img {
    max-width: 43px;
  }
  .prdct_comtenT h2 {
    font-size: 17px;
    margin-bottom: 10px;
  }
  .prdct---falvor {
    font-size: 13px;
  }
  .flavor_design {
    border: 1px solid #eaedf2;
    border-radius: 5px;
    padding: 4px 0;
    font-size: 11px;
  }
  .cartt--btn {
    font-size: 12px;
    padding: 9px 26px;
  }
  .prdct_bottom .number {
    height: 38px;
  }
  .prdct_bottom .minus,
  .prdct_bottom .plus {
    height: 34px;
    font-size: 20px;
    width: 36px;
  }
  .prdct_bottom input {
    height: 34px;
  }
  .describe_main p {
    font-size: 17px;
  }
  .comn_heads {
    margin-bottom: 20px !important;
  }
}

.content {
  position: relative;
  animation: animatop 0.9s cubic-bezier(0.425, 1.14, 0.47, 1.125) forwards;
}
.carding {
  width: 100%;
  min-height: 100px;
  padding: 20px;
  border-radius: 3px;
  /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
  position: relative;
  overflow: hidden;
}
.firstinfo {
  flex-direction: row;
  z-index: 2;
  position: relative;
}
.firstinfo img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.firstinfo,
.badgescard,
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstinfo .profileinfo {
  padding: 0px 20px;
}
.firstinfo .profileinfo p.bio {
  padding: 10px 0px;
  color: #5a5a5a;
  line-height: 1.2;
  font-style: initial;
}

.icon-photo {
  position: absolute;
  right: 0;
  bottom: 0;
}

.profile-pic-nn {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  vertical-align: middle;
  text-align: center;
  color: transparent;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}

.profile-pic-nn:hover {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  color: #fff;
  transition: all 0.3s ease;
  text-decoration: none;
}

.profile-pic-nn span {
  display: inline-block;
  padding-top: 4.5em;
  padding-bottom: 4.5em;
}

.nn-form input[type="file"] {
  display: none;
  cursor: pointer;
}
.profile_bg {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    281deg,
    rgba(255, 255, 255, 1) 6%,
    rgba(62, 64, 147, 0.9585084033613446) 100%
  );
  background: -webkit-linear-gradient(
    281deg,
    rgba(255, 255, 255, 1) 6%,
    rgba(62, 64, 147, 0.9585084033613446) 100%
  );
  background: linear-gradient(
    281deg,
    rgba(255, 255, 255, 1) 6%,
    rgba(62, 64, 147, 0.9585084033613446) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#3e4093",GradientType=1);
}

.dwn-ul {
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dwn-ul li {
  display: inline-block;
  margin: 10px;
}

.download {
  width: 160px;
  height: 50px;
  background: #fff;
  float: left;
  border-radius: 8px;
  position: relative;
  color: #000000;
  cursor: pointer;
  border: 1px solid #fff;
}

.download > .fa {
  color: #000;
  position: absolute;
  width: 30px;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
.download > .fa :hover {
  color: #000;
}
.df,
.dfn {
  position: absolute;
  left: 60px;
}

.df {
  top: 8px;
  font-size: 0.58em;
}

.dfn {
  top: 20px;
  position: relative;
  left: 60px !important;
  font-size: 1em;
}

.download:hover {
  background-color: #ffff !important;
  color: #000 !important;
}

.download-app {
  width: 130px;
  height: 45px;
  background: #e22f35;
  float: left;
  border-radius: 15px;
  position: relative;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
}

.download-app > .fa {
  color: #000;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.download-app > .fa :hover {
  color: #000;
}
.download-app .df,
.dfn {
  position: absolute;
  left: 50px;
}

.download-app .df {
  top: 7px;
  font-size: 0.58em;
}

.download-app .dfn {
  top: 18px;
  font-size: 0.8em;
}
.bg-primary-gradient {
  background: linear-gradient(
    to bottom right,
    var(--primary),
    var(--secondary)
  );
}
.bg-secondary-gradient {
  background: linear-gradient(
    to bottom right,
    var(--secondary),
    var(--primary)
  );
}
.dwn-btns {
  border-radius: 20px;
  border: #000;
  width: 50%;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    165deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(179, 180, 212, 1) 23%,
    rgba(62, 64, 147, 1) 84%
  );
  background: -webkit-linear-gradient(
    165deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(179, 180, 212, 1) 23%,
    rgba(62, 64, 147, 1) 84%
  );
  background: linear-gradient(
    165deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(179, 180, 212, 1) 23%,
    rgba(62, 64, 147, 1) 84%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#3e4093",GradientType=1);
}
.text-dwn {
}

.dwn-title1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif !important;
  font-weight: 600;
}

.prdct_bottom .minus,
.prdct_bottom .plus {
  height: 34px;
  font-size: 20px;
  width: 36px;
}
.prdct_bottom input {
  height: 34px;
}
.describe_main p {
  font-size: 17px;
}
.comn_heads {
  margin-bottom: 20px !important;
}
.login-new-heading h2 {
  font-size: 30px;
  font-weight: 600;
}
.login-new-img img {
  max-width: 160px;
}
.login-new-form .form-group .form-control {
  height: 50px;
}
.content_pagess .comman_divvision {
  padding: 40px 30px;
}
.content_pagess_main h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
.content_pagess_main p {
  font-size: 14px;
  line-height: 25px;
}
.content_pagess_main h2 {
  font-size: 18px;
  margin-bottom: 14px;
}
.aboutus-new .comman_divvision {
  padding: 50px 38px;
}
.aboutus-new-img img {
  max-width: 100%;
}
.aboutus-new-content span {
  color: #3d438e;
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.aboutus-new-content h2 {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  margin: 15px 0;
  line-height: 32px;
}
.aboutus-new-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.new---btn {
  padding: 10px 30px !important;
  margin-top: 14px !important;
  background-color: #3e4093 !important;
  color: #fff;
  border-radius: 8px;
}
.showPass {
  font-size: 15px;
  position: relative;
  top: 20px;
  left: 3px;
}
.showPassCheck {
  position: relative;
  top: 22px;
  accent-color: #eb3237 !important;
  font-size: 15px;
  width: 1rem;
  height: 1rem;
}
.otp_field {
  display: flex;
  justify-content: center;
}
.otp-field__input {
  width: 2.5rem !important;
  height: 3rem !important;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 7px;
  color: #eb3237;
  font-family: "Rubik", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}
.file_selector {
  color: #878787;
  font-size: 10px;
}
.file_selector::-webkit-file-upload-button {
  background: #ed1c1b;
  border: 2px solid #ed1c1b;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 8px;
  outline: none;
  padding: 7px 16px;
  text-transform: uppercase;
  transition: all 1s ease;
}
.table-responsive-pdf th {
  font-size: 12px;
  font-weight: 600;
  color: "#000";
  text-align: center;
  border: 0px solid !important;
}
.table-responsive-pdf td {
  font-size: 16px;
  font-weight: 600;
  color: #3e4093;
  text-align: center;
}
.buyAgain {
  max-height: 43rem;
  overflow-y: scroll;
}
.form-check-label-app {
  position: relative;
  left: 25px !important;
  top: 0px !important;
}
.barScanner {
  opacity: 1%;
  max-width: 2rem !important;
  position: fixed;
  width: 2rem;
  height: 2rem;
  right: 1rem !important;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filter {
  filter: brightness(30%);
  opacity: 70%;
  pointer-events: none;
  border-radius: 1rem !important;
}
.refFilter::before {
  font-size: 1rem;
  color: #fff;
  top: 80%;
  z-index: 99999;
  filter: brightness(30%);

  opacity: 90% !important;
}
.main_video {
  position: relative;
  width: 100% !important;
  max-height: 560px !important;
  top: -15% !important;
}
/* new css */
.myacctnew_profile {
  margin: 0;
  background: #ffffff;
  box-shadow: 4px 4px 54px rgba(223, 229, 235, 0.58);
  border-radius: 10px;
  overflow: hidden;
}
.myacct_detail h2 {
  font-weight: 700;
  color: #000;
  margin-bottom: 6px;
  font-size: 26px;
}
.myacct_detail {
  padding: 0 30px;
}
.acct_email {
  color: #000 !important;
  display: block;
  margin-bottom: 6px;
  font-size: 20px;
}
.acct_mnumber {
  color: #000 !important;
  display: block;
  margin-bottom: 6px;
  font-size: 20px;
}
.edit_btnns {
  position: absolute;
  top: -27px;
  right: 20px;
  font-weight: 500;
}
.myacctnew_profilebg {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile_picc img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.profile_picc {
  overflow: hidden;
  border: 7px solid #fff;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  margin: 0 auto;
}
.account-tabss {
  background: #ffffff !important;
  box-shadow: 4px 4px 54px rgba(223, 229, 235, 0.58) !important;
  border-radius: 10px !important;
  padding: 44px 25px !important;
}
.account-tabss .nav-link {
  border: 1px solid #3d438e !important;
  border-radius: 5px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #3d438e !important;
  display: flex !important;
  margin-bottom: 16px !important;
}
.account-tabss .nav .nav-link:last-child {
  margin-bottom: 0 !important;
}
.account-tabss .nav-link.active {
  background-color: #3d438e !important;
  color: #fff !important;
}
.tab_img {
  width: 40px !important;
  margin-right: 10px !important;
}
.account-tabss .nav-link.active .tab_img img {
  filter: brightness(20.5) !important;
}
.widht_mng {
  width: 28%;
}
.myacct_data {
  background: #ffffff;
  box-shadow: 4px 4px 54px rgba(223, 229, 235, 0.58);
  border-radius: 10px;
  padding: 40px 50px;
}
.data_head h2 {
  color: #000;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}
.order-new-box {
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  padding: 12px;
}
.widht_mng_r {
  width: 72%;
}
.orderID {
  font-size: 14px;
  color: #6c6c6c;
  font-weight: 500;
}
.orderID strong {
  color: #000;
}
.status-box {
  background: #3d438e;
  border-radius: 50px;
  color: #fff;
  font-size: 13px;
  padding: 4px 10px;
  text-align: center;
}
.datee_part {
  font-weight: 500;
  color: #000;
  font-size: 14px;
}
.items_head {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.items_part ul li p {
  margin: 0;
  font-weight: 600;
  color: #000;
  font-size: 14px;
}
.items_part ul {
  list-style: circle;
  padding: 0 0 0 20px;
}
.addrees_box {
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  padding: 20px 25px;
}
.addrees_box p {
  font-weight: 500;
  color: #000;
  font-size: 14px;
  margin: 0;
}
.right_btns a:first-child {
  border: 1px solid #3e4093;
  color: #3e4093 !important;
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 140px;
  justify-content: center;
  align-items: center;
}
.right_btns a:last-child {
  background-color: #3e4093;
  border: 1px solid #3e4093;
  color: #fff !important;
  font-weight: 400;
  font-size: 13px;
  padding: 8px 10px;
  display: inline-flex;
  border-radius: 4px;
  width: 140px;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}
.checkboxx_custom label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.checkboxx_custom label::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  border: 1px solid #3d438e;
  border-radius: 3px;
}
.checkboxx_custom label::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  height: 30px;
  width: 30px;
  background-color: #3d438e;
  border-radius: 3px;
  opacity: 0;
}
.checkboxx_custom input:checked ~ label::before {
  opacity: 1;
}
.setting_form .form-floating label {
  color: #666666 !important;
  opacity: 1 !important;
  font-size: 15px;
  font-weight: 500;
  left: 18px;
  top: 5px;
}
.setting_form .form-floating .form-control {
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  height: 70px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  box-shadow: unset;
  padding: 25px 18px 10px 19px;
}
.upload_document {
  background: #f2f2f2;
  border-radius: 10px;
  padding: 16px 20px;
}
.upload_document > span {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  display: block;
  margin-bottom: 15px;
}
.drag_box {
  background-color: #fff;
  align-items: center;
  justify-content: center;
  height: 110px;
  border-radius: 10px;
  margin-bottom: 18px;
}
.choose_fliee {
  font-size: 12px;
  border: 1px solid rgba(61, 67, 142, 1);
  width: 100%;
  border-radius: 5px;
  height: 36px;
}
.choose_fliee input {
  height: 34px;
  width: 100%;
  line-height: 27px;
  font-weight: 500;
  color: #3d438e;
}
.choose_fliee label {
  background: #3d438e;
  border: 1px solid #3d438e;
  border-radius: 5px 0px 0px 5px;
  color: #fff;
  height: 35px;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 83px;
  padding-bottom: 3px;
}
.Expired_dattee {
  font-size: 14px;
  text-align: center;
  color: #3d438e;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .header_menuss li > a {
    font-size: 10px;
    padding: 12px 12px;
    transition: 0.3s;
    height: 42px;
  }
  .header_menuss li .new_dropdown_link {
    padding: 12px 22px 12px 12px;
  }
  .new_dropdown_link::after {
    height: 12px;
    width: 12px;
    right: 7px;
    background-size: 15px;
    top: 16px;
  }

  .comman_divvision {
    padding: 50px 50px;
  }
  .comman_product1 .categorynew_box {
    margin: 0 10px;
    display: block;
  }
  .new_dropdown:hover .new_dropdown_inner {
    top: 42px;
    width: 210px;
    height: 240px;
  }
  .new_dropdown_inner a {
    padding: 10px 20px;
    font-size: 13px;
  }
  .myacct_data {
    border-radius: 10px;
    padding: 30px 40px;
  }
  .account-tabss {
    border-radius: 10px;
    padding: 32px 22px;
  }
  .data_head h2 {
    font-size: 22px;
  }
  .myacct_detail {
    padding: 0 20px;
  }
  .myacct_detail h2 {
    margin-bottom: 5px;
    font-size: 24px;
  }
  .acct_email,
  .acct_mnumber {
    font-size: 17px;
  }
}

@media (max-width: 1023px) {
  .comman_padding {
    padding: 60px 0;
  }
  .singleproduct-box {
    margin: 0;
  }
  .comn_heads h2 {
    font-size: 22px;
  }
  .comman_divvision {
    padding: 40px 30px;
  }
  .Loginb,
  .Signupb {
    font-size: 13px;
    padding: 4px 11px;
    width: auto;
  }
  .Loginb {
    margin-right: 5px;
  }
  .header_newsearch .form-control {
    height: 42px;
    border-radius: 10px;
    font-size: 12px;
    padding: 0 20px;
  }
  .search_ibtn {
    position: absolute;
    top: 45%;
    right: 10px;
  }
  .search_ibtn img {
    max-width: 18px;
  }
  .header_bottom .container {
    max-width: 100%;
  }
  .header_menuss li > a {
    font-size: 11px;
    padding: 12px 12px;
    height: 42px;
    line-height: 16px;
  }
  .header_bottom {
    height: 42px !important;
  }
  .singleproducttop---left,
  .singleproduct---paginationss a,
  .singleproduct---paginationss span {
    font-size: 14px;
  }
  .singleproduct---paginationss a img {
    max-width: 18px;
  }
  .singleproduct_divvision {
    padding: 30px 30px;
  }
  .singleproduct-left-box {
    padding: 19px 20px;
  }
  .singleproduct-left-box h2 {
    font-size: 17px;
    margin-bottom: 18px;
  }
  .singleproduct-form .custom_radio label {
    padding-left: 31px;
    font-size: 13px;
  }
  .singleproduct--btns a {
    width: 48%;
    padding: 9px 2px;
  }
  .prdct_single_main .comman_divvision {
    padding: 30px 10px;
  }
  .prdct_singleneww {
    padding: 30px 30px;
  }
  .prdct_singleshowimg {
    border-radius: 5px;
    padding: 20px 20px;
    margin-bottom: 30px;
    height: 200px;
  }
  .prdct_singleneww ul li a {
    height: 76px;
  }
  .prdct_singleneww ul li a img {
    max-width: 43px;
  }
  .prdct_comtenT h2 {
    font-size: 17px;
    margin-bottom: 10px;
  }
  .prdct---falvor {
    font-size: 13px;
  }
  .flavor_design {
    border: 1px solid #eaedf2;
    border-radius: 5px;
    padding: 4px 0;
    font-size: 11px;
  }
  .cartt--btn {
    font-size: 12px;
    padding: 9px 26px;
  }
  .prdct_bottom .number {
    height: 38px;
  }
  .prdct_bottom .minus,
  .prdct_bottom .plus {
    height: 34px;
    font-size: 20px;
    width: 36px;
  }
  .prdct_bottom input {
    height: 34px;
  }
  .describe_main p {
    font-size: 17px;
  }
  .comn_heads {
    margin-bottom: 20px !important;
  }
  .login-new-heading h2 {
    font-size: 25px;
  }
  .login-new-img img {
    max-width: 160px;
  }
  .login-new-form .form-group .form-control {
    height: 50px;
  }
  .content_pagess .comman_divvision {
    padding: 40px 30px;
  }
  .content_pagess_main h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .content_pagess_main p {
    font-size: 14px;
    line-height: 25px;
  }
  .content_pagess_main h2 {
    font-size: 18px;
    margin-bottom: 14px;
  }
  .profile_picc {
    border: 5px solid #fff;
    height: 110px;
    width: 110px;
  }
  .myacctnew_profilebg {
    height: 170px;
  }
  .myacct_detail {
    padding: 0 10px;
  }
  .myacct_detail h2 {
    margin-bottom: 5px;
    font-size: 22px;
  }
  .acct_email,
  .acct_mnumber {
    font-size: 16px;
  }
  .edit_btnns {
    top: -27px;
    right: 10px;
    font-size: 13px;
    color: #3e4093 !important;
  }
  .widht_mng {
    width: 100%;
    margin-bottom: 15px;
  }
  .tab_img {
    width: 20px;
    margin-right: 10px;
  }
  .account-tabss .nav {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: unset !important;
    overflow-x: auto;
  }
  .account-tabss .nav .nav-link {
    white-space: nowrap;
    display: inline-flex;
    margin-right: 14px;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 11px;
  }
  .account-tabss .nav::-webkit-scrollbar {
    display: none;
  }
  .account-tabss {
    border-radius: 10px;
    padding: 18px 16px;
  }
  .widht_mng_r {
    width: 100%;
  }
  .data_head h2 {
    font-size: 20px;
  }
  .myacct_data {
    border-radius: 10px;
    padding: 30px 30px;
  }
  .orderID {
    font-size: 12px;
  }
  .datee_part {
    font-size: 12px;
  }
  .items_head {
    font-size: 13px;
  }
  .items_part ul li p {
    font-size: 12px;
  }
  .status-box {
    border-radius: 4px;
    font-size: 10px;
    padding: 4px 2px;
  }
  .addrees_box p {
    font-size: 13px;
    margin: 0;
  }
  .addrees_box {
    padding: 15px 17px;
  }
  .checkboxx_custom label::before,
  .checkboxx_custom label::after {
    height: 20px;
    width: 20px;
  }
  .right_btns a:first-child,
  .right_btns a:last-child {
    font-size: 12px;
    padding: 6px 12px;
    width: 100px;
  }
}
/* .banner_slider {
  margin-top: 8.6rem !important;
  max-height: 600px !important;
} */

.banner_slider button {
  height: 50px;
  width: 50px;
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 50%;
  font-size: 40px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ff3f55 !important;
  transition: 0.5s all;
}
.banner_slider button:hover {
  background-color: #ff3f55 !important;
}
.banner_slider .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}
.banner_slider button span {
  display: block;
  margin-top: -4px;
}
.banner_slider .owl-prev {
  border-radius: 0 30px 30px 0;
}
.banner_slider .owl-next {
  border-radius: 30px 0 0 30px;
}
.banner_section img {
  width: 100% !important;
  height: 100%;
  /* max-height: 50vh !important; */
}

.banner_section {
  cursor: pointer !important;
}

.center_art {
  position: relative;
  top: 40% !important;
  color: gray;
}
.custom_date {
  position: relative;
  top: -30px !important;
  left: 80% !important;
  z-index: 99 !important;
  border: 0;
  text-indent: -441100000000000000000px;
  background-color: #fff;
  height: 15px;
  width: 25px;
  display: flex;
  border-radius: 0px;
  cursor: pointer;
  transform: scale(1.4);
}
.custom_date:focus {
  outline: none;
}

.marquee {
  position: relative;
  overflow: hidden;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 600;
  font-size: 15px !important;
  text-transform: uppercase;
  margin: 0 !important;
}

.marquee__inner {
  display: flex;
}

.marquee__line {
  flex-shrink: 0;
  margin: 0 !important;
  min-width: 100%;
  white-space: nowrap;
  animation-name: marqueeLine;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes marqueeLine {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-30%);
  }
}

.title_product {
  position: relative;
  top: 5px;
  align-items: center;
}

.photo_gallery {
  max-width: 15rem;
  /* max-height: 18rem; */
  /* min-height: 18rem; */
  aspect-ratio: 4/3;
  /* border: 2px solid #000; */
  border-radius: 3%;
  margin: 10px;
  cursor: zoom-in;
}
.photo_gallery:hover {
  opacity: 50%;
}

@media (min-width: 1400px) {
  .photo_gallery {
    max-width: 20rem;
    max-height: 18rem;
    min-height: 18rem;
    aspect-ratio: 4/3;
    /* border: 2px solid #000; */
    border-radius: 3%;
    margin: 10px;
    cursor: zoom-in;
  }
  .photo_gallery:hover {
    opacity: 50%;
  }
}

.gallery_uploads {
  max-width: 12rem;
  max-height: 8rem;
  min-height: 8rem;
  aspect-ratio: 4/3;
  border: 2px solid grey;
  border-radius: 3%;
  margin: 5px !important;
}
.gallery_uploads_web {
  max-width: 35rem;
  max-height: 18rem;
  min-height: 18rem;
  aspect-ratio: 4/3;
  /* border: 2px solid grey; */
  border-radius: 3%;
  margin: 5px !important;
}

.closeIcon {
  background-color: #eb3237;
  color: #fff;
  border-radius: 60%;
  padding: 10px;
  font-size: 16px;
  position: relative;
  top: -3.8rem !important;
  left: 1rem;
  cursor: pointer;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #3e4093;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #3e4093;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1400px) {
  .Fcard {
    position: relative;
    max-width: 100%;
    height: 16rem !important;
    width: auto !important;
    background: #eb3237 !important;
    border-radius: 15px;
    overflow: hidden;
    align-items: center !important;
  }
  .Fcard::before {
    content: "";
    position: absolute;
    top: -50%;
    width: 100%;
    height: 100%;
    background: #3b4093 !important;
    transform: skewY(345deg);
    transition: 0.5s;
  }
  .Fcard:hover::before {
    top: -70%;
    transform: skewY(390deg);
  }

  .Fcard::after {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 400;
    font-size: 3em !important;
    color: rgba(0, 0, 0, 0.1);
  }

  .Fcard .imgBox {
    position: relative;
    left: 8%;
    top: 4%;
    max-width: 84% !important;
    max-height: 5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px !important;
    z-index: 1;
  }
  .Fcard .main_image {
    height: 4rem;
    width: 100%;
  }
  .Fcard .contentBox {
    position: relative;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
  }

  .title_prod {
    font-size: 12px !important;
    position: relative;
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .Fcard:hover .title_prod {
    opacity: 0;
  }

  .Fcard .contentBox .price {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .Fcard .contentBox .buy {
    position: relative;
    top: 100px;
    opacity: 0;
    padding: 8px 15px !important;
    margin-top: 10px;
    color: #000;
    text-decoration: none;
    background: #f2f2f2 !important;
    border-radius: 25px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.5s;
  }

  .Fcard:hover .contentBox .buy {
    top: 60px !important;
    opacity: 1;
  }
  .banner_section img {
    width: 100% !important;
    max-height: 560px !important;
  }
  .banner_section video {
    width: 100% !important;
    max-height: 560px !important;
  }

  .banner_section {
    cursor: pointer !important;
  }
}

.Fcard {
  position: relative;
  max-width: 100%;
  height: 16rem;
  width: auto !important;
  background: #eb3237 !important;
  border-radius: 15px;
  overflow: hidden;
}

.Fcard::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  background: #3b4093 !important;
  transform: skewY(345deg);
  transition: 0.5s;
}

.Fcard:hover::before {
  top: -50%;
  transform: skewY(390deg);
}

.Fcard::after {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size: 6em;
  color: rgba(0, 0, 0, 0.1);
}

.Fcard .imgBox {
  position: relative;
  max-width: 100%;
  max-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  z-index: 1;
}

/* .Fcard .imgBox img {
    max-width: 4rem;
    
    transition: .5s;
}

.Fcard:hover .imgBox img {
    max-width: 50%;
      
} */

.Fcard .contentBox {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.title_prod {
  font-size: 17px;
  position: relative;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.Fcard:hover .title_prod {
  opacity: 0;
}

.Fcard .contentBox .price {
  font-size: 24px;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
}

.Fcard .contentBox .buy {
  position: relative;
  top: 100px;
  opacity: 0;
  padding: 10px 30px;
  margin-top: 15px;
  color: #000;
  text-decoration: none;
  background: #f2f2f2 !important;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.5s;
}

.Fcard:hover .contentBox .buy {
  top: 60px !important;
  opacity: 1;
}

.subCateSearch {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.subCateSearch:hover {
  color: #3b4093;
  transition: ease-out;
  transform: scale3d(1.1);
}

.lds-facebook {
  display: inline-block;
  position: relative;
  left: 48%;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #3e4093;
  animation: lds-facebook 1.1s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 74px;
  }
  0% {
    top: 14px;
    height: 74px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.thumbnails_box {
  position: relative;
  width: 110%;
  padding: 10px 0 !important;
}
.thumbnails_box .box {
  width: 100%;
}

.text_area {
  white-space: wrap !important;
}

.is-loading {
  .image,
  .one {
    background: #eee !important;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.element_container {
  position: relative;
  border: 2px solid #000;
  top: -2.4rem !important;
  z-index: 999;
}

.main_hot:hover .card_hot {
  transform: translateY(-50px);
}

.card_hot {
  height: 330px !important;
  width: 15rem;
  background-color: #fff;
  border-radius: 20px;
  position: relative;

  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  transition: 0.5s;
}

.card_hot .offer {
  width: 140px;
  height: 40px;
  background-color: orange;
  position: absolute;
  top: 14px !important;
  left: -14px;
  border-color: orange;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}
.card_hot .offer2 {
  width: 200px;
  height: 40px;
  background-color: red;
  position: absolute;
  top: -3.2rem !important;
  left: -14px;
  border-color: red;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}
.card_hot .offer2:before {
  position: absolute;
  content: "";
  background-color: red;
  height: 30px;
  width: 17px;
  top: 20px;
  left: 0px;
  border-radius: 10px;
  z-index: -10;
}

.card_hot .offer:before {
  position: absolute;
  content: "";
  background-color: orange;
  height: 30px;
  width: 17px;
  top: 20px;
  left: 0px;
  border-radius: 10px;
  z-index: -10;
}

.card_hot .offer:after {
  position: absolute;
  content: "";
  background-color: orange;
  height: 30px;
  width: 17px;
  top: 30px;
  left: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 20px;
  z-index: -10;
}
.card_hot .offer2:after {
  position: absolute;
  content: "";
  background-color: red;
  height: 30px;
  width: 17px;
  top: 30px;
  left: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 20px;
  z-index: -10;
}

.card_hot .item-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card_hot .item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-content {
  padding: 10px;
  position: relative;
  top: 22px;
  opacity: 85% !important;
  height: 5.5rem !important;
  background-color: #3e4093;
  border-radius: 0px 0 14px 14px;
}

.item-content h3 {
  position: relative;
  top: 8px;
  font-size: 16px;
  color: #fff !important;
}

.item-content p {
  font-size: 12px !important;
  font-weight: 500;
}

.item-price {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.mb-45 {
  margin-bottom: 45px;
}

.main_hot:hover .product-action {
  opacity: 1;
}

.product-action {
  bottom: 0px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transition: all 0.7s ease 0s;
}

.product-action-style {
  display: inline-block;
  padding: 16px 2px 12px;
}
.product-action-style > a {
  color: #979797;
  line-height: 1;
  padding: 0 21px;
  position: relative;
  transition: transform 1s;
}
.product-action-style a:hover {
  color: #3e4093 !important;
  transform: scale(1.3) !important;
}
.product-action-style > a.action-plus {
  font-size: 20px;
}
.product-wrapper:hover .product-action {
  bottom: 20px;
  opacity: 1;
}

.viewall_btn {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}

.viewall_btn img {
  transition: 0.5s all;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.product-grid {
  font-family: "Poppins", sans-serif;
  text-align: center;
  transition: all 0.7s ease 0s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 10px 15px rgba(0, 0, 0, 0.05);
  max-height: 20rem !important;
  min-height: 18rem !important;
  margin: 0.4rem;
}
.product-grid:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 10px 10px rgba(0, 0, 0, 0.05);
}
.product-grid .product-image {
  overflow: hidden;
  position: relative;
}
.product-grid .product-image a.image {
  display: block;
}
.product-grid .product-image img {
  width: 100%;
  height: auto;
  max-height: 13.6rem;
  min-height: 13.5rem !important;
}
.product-grid .product-image .pic-1 {
  transition: all 200ms ease 0s;
}
.product-grid .product-image:hover .pic-1 {
  opacity: 0;
}
.product-grid .product-image .pic-2 {
  width: 100% !important;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
}

.product-grid .product-image img {
  width: 80% !important;
  height: auto;
  max-height: 13.6rem;
  min-height: 13.5rem !important;
}

.product-grid:hover .product-image .pic-2 {
  opacity: 1;
  transform: scale(1.2);
}

/* .product-grid  .pic-2{
  opacity: 1 !important;
  transform: scale(1.3) !important;
} */

.product-grid .product-Featured-label {
  color: #fff;
  background: #3e4093;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 7px 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-grid .product-hot-label {
  color: #fff;
  background: orange;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 7px 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.product-grid .product-Close-label {
  color: #fff;
  background: red;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 7px 12px;
  position: absolute;
  top: 0;
  left: 0;
}
.product-grid .product-links {
  background: #f1f1f1;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  transform: scaleX(2);
  position: absolute;
  bottom: -50px;
  left: 0;
  transition: all 0.5s ease-in-out 0s;
}
.product-grid:hover .product-links {
  opacity: 1;
  transform: scaleX(1);
  bottom: 0;
}
.product-grid .product-links li {
  margin: 0 -2px;
  display: inline-block;
}
.product-grid .product-links li a {
  color: #eb3237;
  font-size: 16px;
  line-height: 41px;
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease-out;
}
.product-grid .product-links li a:hover {
  color: #fff;
  background-color: #eb3237;
}
.product-grid .product-links li a:before,
.product-grid .product-links li a:after {
  content: attr(data-tip);
  color: #fff;
  background-color: #222;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 10px;
  white-space: nowrap;
  display: none;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: -40px;
  transition: all 0.3s;
}
.product-grid .product-links li a:after {
  content: "";
  height: 15px;
  width: 15px;
  transform: translateX(-50%) rotate(45deg);
  top: -25px;
  z-index: -1;
}
.product-grid .product-links li a:hover:before,
.product-grid .product-links li a:hover:after {
  display: block;
}
.product-grid .product-content {
  background: #fff;
  padding: 20px 20px !important;
  position: relative;
}

.product-grid .add-to-cart {
  color: #3e4093;
  font-size: 15px;
  font-weight: 600;
  transform: translateX(-50%);
  position: absolute;
  top: 13px;
  left: 50%;
  opacity: 0;
  transition: all 1s ease 0s;
}
.product-grid:hover .add-to-cart {
  opacity: 1;
}
.product-grid .add-to-cart i.fas {
  font-size: 14px;
  margin: 0 5px 0 0;
}
.product-grid .title {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 10px;
  transition: all 0.3s ease 0s;
}
.product-grid .title a {
  color: #444;
}
.product-grid:hover .title {
  opacity: 0;
}
.product-grid .rating {
  color: #1c1a19;
  font-size: 12px;
  padding: 0;
  margin: 0 0 11px;
  list-style: none;
}
.product-grid .price {
  color: #88c000;
  font-size: 16px;
  font-weight: 700;
}
@media screen and (max-width: 1400px) {
  .product-grid {
    margin: 0 0 30px;
  }
  .product-grid .product-image img {
    width: 100%;
    height: auto;
    max-height: 11rem;
    min-height: 11rem !important;
  }
}

.react-pdf__Page__textContent {
  display: none;
}

.catelogueBOx {
  min-height: 50rem;
  max-height: 50rem;
}



.sliderC {
  max-width: 100%;
  height: 30rem;
  margin: 100px auto;
}

.stf__parent {
  max-width: 100% !important;
  height: 30rem !important;
  margin: 100px auto !important;
  position: relative;
  display: block;
  box-sizing: border-box;
  transform: translateZ(0);
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

@media (max-width: 400px) {
  .sliderC h1 {
    font-size: 40px;
    top: -60px;
    left: calc(50% - 100px);
  }
}

.sliderC ul {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  margin-top: 18px;
  list-style: none;
  perspective: 2000px;
  perspective-origin: 50% -200px;
}
.sliderC ul {
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(0, 0, 0, 0.2) 46%,
    rgba(0, 0, 0, 0.5) 49%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 51%,
    rgba(0, 0, 0, 0.2) 52%,
    transparent 100%
  );
}

.sliderC ul > li {
  width: 50%;
  height: 100%;
  position: absolute;
  transform-origin: center right;
  transition: all 1s ease-in-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  line-height: 400px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.5) 0.2em 0 0 0;
  color: #000;
}

.sliderC ul > li:before,
.sliderC ul > li:after {
  content: "";
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 2px solid #000 !important;
}

.sliderC ul > li:after {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.open {
  transform: rotateY(180deg);
}

.sliderC > div {
  text-align: center;
  position: absolute;
  width: 120%;
  top: 50%;
  left: -10%;
  background-color: #fff;
}

.sliderC .fa {
  cursor: pointer;
  color: #3e4093;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  position: absolute;
}

.sliderC .fa:first-of-type {
  left: 0;
}

.sliderC .fa:last-of-type {
  right: 0;
}

.sliderC .fa:hover,
.sliderC .fa.active {
  background: #eb3237 !important;
  color: #fff;
}
.brandPic {
  max-height: 5rem !important;
}

.site-header {
  border-bottom: 2px solid #ccc;
  padding: 0.5em 1em;
  font-family: "Unbounded", cursive !important;
  font-weight: 600;
}

.site-header::after {
  content: "";
  display: table;
  clear: both;
}

.site-identity {
  float: left;
}

.site-identity h1 {
  font-size: 1.5em;
  font-family: "Unbounded", cursive !important;
  text-decoration: none !important;
  margin: 0.7em 0 0.3em 0;
  display: inline-block;
}

.site-identity img {
  max-width: 8rem;
  float: left;
  margin: 0 10px 0 0;
}

.site-navigation {
  float: right;
}

.site-navigation ul,
li {
  margin: 0;
  padding: 0;
}

.site-navigation li {
  display: inline-block;
  margin: 1.4em 1em 1em 1em;
}

.flowpaper_toolbarstd {
  display: inline-block;
  margin-left: 5%;
  margin-bottom: 3px;
  height: 29px !important;
  border-style: solid !important;
  border-width: 0;
  /* z-index: 100; */
  position: relative !important;
  background-color: #000 !important;
  top: -1rem !important;
  z-index: 10000 !important;
}

.wallet {
  --bg-color: #ceb2fc;
  --bg-color-light: #f0e7ff;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.cardR {
  width: 220px;
  height: 321px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.cardR:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.11),
    0 12px 20px var(--box-shadow-color);
}

.cardR:hover .overlay {
  transform: scale(4) translateZ(0);
}

.cardR:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.cardR:hover .circle:after {
  background: var(--bg-color-light);
}

.cardR:hover p {
  color: var(--text-color-hover);
}

.cardR p {
  font-size: 20px;
  color: #4c5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.circle img {
  z-index: 10000;
  transform: translateZ(0);
}

.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 70px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}

.cateView {
  width: 100%;
  height: 100vh !important;
}
